import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "./pages/Login/Login_Logic";
import Signup from "./pages/Signup/Signup_Logic";
import Account from "./pages/Account/Account_Logic";
import LoanWidget from "./components/LoanWidget/LoanWidget_Logic";
import Transactions from "./pages/Transactions/Transactions_Logic";
import useAuth from "./hooks/useAuth";
import LoanTable from "./pages/Admin/LoanTable/LoanTable_Logic";
import UserTable from "./pages/Admin/UserTable/UserTable_Logic";
import NotFound from "./pages/NotFound/NotFound_View";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword_Logic";
import ResetPassword from "./pages/ResetPassword/ResetPassword_Logic";
import { PageLayout } from "./components";
import Profile from "./pages/Profile/Profile_Logic";

export const history = createBrowserHistory();

const PageLoader = (isLoading: any) => (
  <PageLayout loading={isLoading} showHeader={true}>
    <></>
  </PageLayout>
);

const AuthRoute = ({ component, ...props }: any) => {
  const { user, isLoading } = useAuth();
  const Component = component;
  return (
    <Route
      {...props}
      render={() => {
        if (isLoading) return <PageLoader isLoading />;
        if (user) {
          return <Component />;
        } else return <Redirect to="/login" />;
      }}
    />
  );
};

const AdminRoute = ({ component, ...props }: any) => {
  const { user, isLoading } = useAuth();
  const Component = component;
  return (
    <Route
      {...props}
      render={() => {
        if (isLoading) return <PageLoader isLoading />;
        return user?.admin ? <Component /> : <Redirect to="/account" />;
      }}
    />
  );
};

const CatchAllRoute = ({ component, ...props }: any) => {
  const { user, isLoading } = useAuth();
  const Component = component;
  return (
    <Route
      {...props}
      render={() => {
        if (isLoading) return <PageLoader isLoading />;
        return user ? <Component /> : <Redirect to="/account" />;
      }}
    />
  );
};

const Navigation = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" component={() => <Redirect to="/account" />} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/signup" component={Signup} exact />
        <Route path="/forgot-password" component={ForgotPassword} exact />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/loan-widget" component={LoanWidget} exact />
        <AuthRoute path="/account" component={Account} exact />
        <AuthRoute path="/profile" component={() => <Profile />} exact />
        <AuthRoute path="/transactions" component={Transactions} exact />
        <AdminRoute path="/admin/users" component={UserTable} exact />
        <AdminRoute path="/admin/loans" component={LoanTable} exact />
        <CatchAllRoute path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Navigation;
