import { DepositAddressQR, Modal } from "..";
import { collateralFormatter } from "../../helpers/formatters";

type Props = {
  loan: server.Loan | undefined;
  onClose: () => void;
  setDepositDataStatus:React.Dispatch<React.SetStateAction<boolean>>;
  handleOnClickToCopyAddress:(value:string)=> void;
  addressCopied:boolean;
  handleSubmit: () => void;
  setValue:React.Dispatch<React.SetStateAction<string>>
  value:string;
  error:boolean;
  getSelectedAddressBasedOnAsset:(value:server.AssetId)=> string
};

const DepositCollateralModal: React.FC<Props> = props => {
  if (!props.loan) {
    return null;
  }

  // If some collateral has already been paid, only show the amount left to be paid
  const instructions =
    props.loan.status === "pending-collateral"
      ? `Deposit ${collateralFormatter(
          props.loan.collateral,
          6,
          props.loan.desiredCollateral
        )} ${props.loan.assetId} to activate your loan, click to copy the below address to deposit`
      : `Deposit more ${props.loan.assetId} to increase your loan's health`;

  return (
    <Modal
      title={`Deposit ${props.loan.assetId}`}
      show={true}
      onClose={props.onClose}
    >
      {/* <DepositAddressQR address={props.loan.depositAddress} /> */}

      {/* <div className="mt-6 text-center"> */}
      <div className="mt-6">
        <span>{instructions}</span>
      </div>
      <div className="mt-2">
        <DepositAddressQR 
        setDepositDataStatus={props.setDepositDataStatus} 
        onClose={props.onClose} 
        loan={props.loan} 
        handleOnClickToCopyAddress={props.handleOnClickToCopyAddress} addressCopied={props.addressCopied} 
        handleSubmit={props.handleSubmit}
        setValue={props.setValue}
        value={props.value}
        error={props.error}
        getSelectedAddressBasedOnAsset={props.getSelectedAddressBasedOnAsset}
        />
        </div>
    </Modal>
  );
};

export default DepositCollateralModal;
