import { createContext, useEffect, useState, useCallback } from "react";
import useApi from "../hooks/useApi";

export const SystemStateContext = createContext<server.SystemState | undefined>(
  null as any
);

export const SystemStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const api = useApi();
  const [systemState, setSystemState] = useState<server.SystemState>();

  const fetchSystemState = useCallback(async () => {
    const systemState = await api.getSystemState();
    setSystemState(systemState);
  }, [api]);

  useEffect(() => {
    fetchSystemState();
  }, [fetchSystemState]);

  return (
    <SystemStateContext.Provider value={systemState}>
      {children}
    </SystemStateContext.Provider>
  );
};
