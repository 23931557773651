import { useField } from "formik";
import SwitchView, { Props as SwitchViewProps } from "./Switch_View";

const Switch: React.FC<SwitchViewProps> = props => {
  const [field, meta] = useField(props as any);

  const error = meta.error && meta.touched ? meta.error : undefined;

  return (
    <SwitchView checked={field.value} error={error} {...field} {...props} />
  );
};

export default Switch;
