import useSystemState from "../../hooks/useSystemState";
import LoanStatusView from "./LoanStatus_View";

type Props = {
  loan: LoanWithAssetData;
};

const LoanStatus: React.FC<Props> = props => {
  const systemState = useSystemState();

  return (
    <LoanStatusView
      loan={props.loan}
      unsafeCollateralToDebtRatios={
        systemState
          ? systemState.unsafeCollateralToDebtRatios
          : { crypto: 0, stablecoin: 0 }
      }
    />
  );
};

export default LoanStatus;
