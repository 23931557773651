import { BubbleText } from "..";
import { getDebtToCollateralRatio } from "../../helpers/loan-helpers";
import { getLoanStatusColor, getFormattedLoanStatus } from "../utils";

type Props = {
  loan: LoanWithAssetData;
  unsafeCollateralToDebtRatios: { crypto: number; stablecoin: number };
};

const LoanStatus: React.FC<Props> = props => {
  const formattedStatus = getFormattedLoanStatus(props.loan.status);

  const unsafeCollateralToDebtRatio = props.loan.isStablecoin
    ? props.unsafeCollateralToDebtRatios.stablecoin
    : props.unsafeCollateralToDebtRatios.crypto;

  const loanIsSafe =
    getDebtToCollateralRatio(
      props.loan.collateral,
      props.loan.assetPriceAUD,
      props.loan.outstandingDebt
    ) > unsafeCollateralToDebtRatio;

  const status =
    props.loan.status === "active"
      ? `${formattedStatus} - ${loanIsSafe ? "Safe" : "Attention"}`
      : formattedStatus;

  const color =
    props.loan.status === "active" && !loanIsSafe
      ? "yellow"
      : getLoanStatusColor(props.loan.status);

  return <BubbleText color={color}>{status}</BubbleText>;
};

export default LoanStatus;
