import classnames from "classnames";
import { Link } from "react-router-dom";

export type Props = {
  title: string;
  route: string;
  isSelected: boolean;
  className?: string;
};

const NavButtonView: React.FC<Props> = ({
  title,
  isSelected,
  className,
  route
}) => {
  return (
    <Link to={route}>
      <NavButtonViewLayout className={className} isSelected={isSelected}>
        {title}
      </NavButtonViewLayout>
    </Link>
  );
};

export default NavButtonView;

export const NavButtonViewLayout: React.FC<{
  className?: string;
  isSelected: boolean;
  onClick?(): void;
}> = ({ className, children, isSelected, onClick }) => {
  return (
    <div
      className={classnames(
        "block px-4 py-2  rounded text-white hover:no-underline font-medium hover:bg-purple-electric focus:outline-none",
        className,
        { "border-b-2 py-5 rounded-b-none border-purple-electric": isSelected }
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
