import { useState } from "react";
import LoanWidgetView from "./LoanWidget_View";
import useCollateralCalculatorState from "../../hooks/useCollateralCalculatorState";

const LoanWidgetLogic: React.FC = () => {
  const [assetId, setAssetId] = useState<server.AssetId>("BTC");
  const {
    assetPrices,
    systemState,
    loanAmount,
    setLoanAmount,
    collateralToDebt,
    setCollateralToDebt
  } = useCollateralCalculatorState();

  const navigateToSignup = (collateral: number) => {
    if (window.top)
      window.top.location.href = `${process.env.PUBLIC_URL}/signup?loanAmount=${loanAmount}&collateralToDebtRatio=${collateralToDebt}&collateral=${collateral}`;
  };

  return (
    <LoanWidgetView
      assetPrices={assetPrices}
      selectedAssetId={assetId}
      systemState={systemState}
      loanAmount={loanAmount}
      setAssetId={setAssetId}
      setLoanAmount={setLoanAmount}
      setCollateralToDebt={setCollateralToDebt}
      collateralToDebt={collateralToDebt}
      navigateToSignup={navigateToSignup}
    />
  );
};

export default LoanWidgetLogic;
