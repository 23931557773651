import classNames from "classnames";

interface RadioGroupType {
  options: string[];
  name: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const RadioGroup: React.FC<RadioGroupType> = props => {
  return (
    <div className="mt-5">
      {props.options.map(item => (
        <label
          htmlFor={props.name + item}
          key={item}
          className={classNames("cursor-pointer  mr-2  mb-8 radio-btn", {
            "radio-active": item === props.value
          })}
        >
          <input
            id={props.name + item}
            type="radio"
            name={props.name}
            onChange={props.onChangeHandler}
            className="hidden"
            value={item}
            checked={item === props.value}
          />
          {item[0].toUpperCase() + item.substring(1)}
        </label>
      ))}
    </div>
  );
};
