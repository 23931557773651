import CoinButton from "./CoinButton_View";
import { Button, Loader, ToolTip, SelectLVRButton } from "..";
import Select from "./Select_View";
import { getCollateralRequired } from "../../helpers/loan-helpers";
import {
  COLLATERAL_RATIO_BUTTON_DATA,
  LVR_EXPLANATION
} from "../../helpers/constants";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  assetPrices: server.Asset[] | undefined;
  selectedAssetId: server.AssetId;
  loanAmount: number;
  collateralToDebt: number;
  systemState: server.SystemState | undefined;
  setLoanAmount(newValue: number): void;
  setAssetId(title: server.AssetId): void;
  setCollateralToDebt(cr: number): void;
  navigateToSignup: (collateralValue: number) => void;
};

const SUPPORTED_ASSETS: server.AssetId[] = ["BTC", "ETH", "USDT", "USDC"];

const LoanWidget: React.FC<Props> = props => {
  if (!props.assetPrices || !props.systemState) {
    return <Loader />;
  }

  const selectedAsset = props.assetPrices.find(
    a => a.assetId === props.selectedAssetId
  )!;

  const cryptoValue = getCollateralRequired(
    props.loanAmount,
    props.collateralToDebt,
    selectedAsset.assetPriceAUD
  );

  return (
    <div className="bg-background-light rounded-3xl p-10">
      <form>
        <AssetButtons {...props} />

        <div className="grid grid-cols-2 pt-5 pb-3">
          <div className="flex items-item justify-center flex-col">
            <div className="text-sm text-white">COLLATERAL</div>
            <div className="flex mt-1 items-center">
              <img
                className="w-6 h-6"
                src={selectedAsset.imageURL}
                alt={selectedAsset.assetId}
              />
              <span className="text-purple-electric text-sm px-2">
                {cryptoValue.toFixed(6)}
              </span>
            </div>
          </div>
          <Select
            value={selectedAsset.assetId}
            className="h-12 p-1 px-2 text-white flex bg-background-light border-2 border-solid border-other-gray outline-none"
            onChange={e => {
              props.setAssetId(e.target.value);
            }}
          >
            {SUPPORTED_ASSETS.map(sa => (
              <option value={sa} key={sa}>
                {sa}
              </option>
            ))}
          </Select>
        </div>
        <div className="grid grid-cols-2 mt-2">
          <div className="flex items-center">
            <span className="text-white pr-8 text-sm ">I WANT TO BORROW $</span>
          </div>
          <input
            value={props.loanAmount}
            onChange={e => {
              let { value } = e.target;
              props.setLoanAmount(Number(value));
            }}
            className="flex-grow text-base h-12 px-3 text-white flex bg-background-light border-2 border-solid border-other-gray outline-none rounded-lg "
            type="number"
            min={props.systemState.minimumLoanAmount}
            max={props.systemState.maximumLoanAmount}
            onInput={e => {
              const target = e.target as HTMLTextAreaElement;
              target.value = Math.max(0, parseInt(target.value)).toString();
            }}
          />
        </div>

        <div className="flex my-8">
          <input
            className="w-full rounded-full bg-other-gray overflow-hidden appearance-none h-4"
            type="range"
            min={props.systemState.minimumLoanAmount}
            max={props.systemState.maximumLoanAmount}
            step={100}
            value={props.loanAmount}
            onChange={event => {
              props.setLoanAmount(event.target.valueAsNumber);
            }}
          />
        </div>

        <div className="flex items-center justify-between ">
          <span className="text-white text-sm">
            LVR &nbsp;
            <ToolTip value={LVR_EXPLANATION} />
          </span>

          <div>
            {COLLATERAL_RATIO_BUTTON_DATA.map(cbd => {
              return (
                <SelectLVRButton
                  title={cbd.title}
                  onClick={e => {
                    props.setCollateralToDebt(cbd.value);
                    e.preventDefault();
                  }}
                  selected={props.collateralToDebt === cbd.value}
                  key={cbd.title}
                />
              );
            })}
          </div>
        </div>

        <div className="py-4 text-left">
          <span className="text-white text-sm">
            Interest rate: {props.systemState.interestRate}%
          </span>
        </div>
      </form>
      <Button
        title="Get Loan"
        onClick={() => props.navigateToSignup(cryptoValue)}
        className="w-full  "
        disabled={props.loanAmount < 1000}
      />
    </div>
  );
};

const AssetButtons: React.FC<
  Pick<Props, "assetPrices" | "setAssetId" | "selectedAssetId">
> = props => {
  if (!props.assetPrices) {
    return null;
  }

  const assetButtons = props.assetPrices
    .filter(ap => SUPPORTED_ASSETS.includes(ap.assetId))
    .map(ap => (
      <CoinButton
        key={ap.assetId}
        assetId={ap.assetId}
        onClick={e => {
          props.setAssetId(ap.assetId);
          e.preventDefault();
        }}
        imageURL={ap.imageURL}
        isSelected={props.selectedAssetId === ap.assetId}
      />
    ));

  return (
    <div className="grid grid-flow-col grid-cols-4 space-x-2">
      {assetButtons}
    </div>
  );
};

export default LoanWidget;
