import React from "react";
import { Loader } from "..";

type props = {
  sendVerifyEmail: () => Promise<void>;
  isOtpSent: boolean;
};
const VerifyEmailView: React.FC<props> = props => {
  const [seconds, setSeconds] = React.useState(0);

  React.useEffect(() => {
    if (seconds > 0 && !props.isOtpSent) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [setSeconds, seconds, props.isOtpSent]);
  const resentVerificationLink = () => {
    props.sendVerifyEmail();
    setSeconds(30);
  };
  return (
    <div className="pt-2 w-full text-center justify-center h-full">
      <div>
        <p className="text-gray">
          Verification link was sent to your email. You must open the link to
          complete verification before you can continue using the platform.
        </p>
        <p className="text-gray mt-12">
          Didn’t receive the Email?{" "}
          {props.isOtpSent ? (
            <Loader />
          ) : (
            <span
              className={`text-purple-electric ${
                !seconds ? "cursor-pointer" : ""
              }`}
              onClick={() => !seconds && resentVerificationLink()}
            >
              {seconds ? seconds : "Resend Email"}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default VerifyEmailView;
