import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance
} from "axios";

type RetrieveAuthTokenCallback = () => string | Promise<string>;

export default class HTTPClient {
  private client: AxiosInstance;
  private customOnError: Function | undefined;
  private axiosAuthHeaderInterceptorId: number;

  constructor(baseUrl: string) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: {
        "Cache-Control": "no-store"
      }
    });
    this.axiosAuthHeaderInterceptorId = -1;
  }
  public attachAuthHeaderInterceptor(
    retrieveAuthToken: RetrieveAuthTokenCallback
  ): void {
    this.removeAuthHeaderInterceptor();
    this.axiosAuthHeaderInterceptorId = this.client.interceptors.request.use(
      async config => {
        const newConfig = { ...config };
        const authToken = await retrieveAuthToken();
        newConfig.headers.Authorization = `Bearer ${authToken}`;
        return newConfig;
      }
    );
  }

  public removeAuthHeaderInterceptor(): void {
    if (this.axiosAuthHeaderInterceptorId >= 0) {
      this.client.interceptors.request.eject(this.axiosAuthHeaderInterceptorId);
      delete this.client.defaults.headers.Authorization;
      this.axiosAuthHeaderInterceptorId = -1;
    }
  }

  protected get = <T>(
    url: string,
    axiosReqConfig?: AxiosRequestConfig
  ): Promise<T> =>
    (this.client
      .get(url, axiosReqConfig)
      .then(this.onSuccess)
      .catch(this.onError) as unknown) as Promise<T>;
  protected post = <T>(
    url: string,
    data?: unknown,
    reqConfig?: AxiosRequestConfig
  ): Promise<T> =>
    (this.client
      .post(url, data, reqConfig)
      .then(this.onSuccess)
      .catch(this.onError) as unknown) as Promise<T>;
  protected put = <T>(
    url: string,
    data?: unknown,
    axiosReqConfig?: AxiosRequestConfig
  ): Promise<T> =>
    (this.client
      .put(url, data, axiosReqConfig)
      .then(this.onSuccess)
      .catch(this.onError) as unknown) as Promise<T>;
  protected patch = <T>(
    url: string,
    data?: unknown,
    axiosReqConfig?: AxiosRequestConfig
  ): Promise<T> =>
    (this.client
      .patch(url, data, axiosReqConfig)
      .then(this.onSuccess)
      .catch(this.onError) as unknown) as Promise<T>;
  public setCustomOnError = (onError: Function) => {
    this.customOnError = onError;
  };

  private onSuccess = (res: AxiosResponse) => res.data;
  private onError = (e: AxiosError) => {
    const error = e.response ? e.response.data : e;

    if (this.customOnError) {
      this.customOnError(error);
    }

    throw error;
  };
}
