import { useState } from "react";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import useGlobalError from "../../hooks/useGlobalError";
import { BAD_REQUEST_CODE } from "../../types/errors";
import AddPhoneNumberView, { Step } from "./AddPhoneNumber_View";

type Props = {};

const AddPhoneNumber: React.FC<Props> = () => {
  const api = useApi();
  const { setGlobalError } = useGlobalError();
  const { user, setUser } = useAuth();

  const [step, setStep] = useState<Step>("update");
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    user!.phoneNumber || null
  );
  const [incorrectCode, setIncorrectCode] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const updatePhoneNumber = async (data: server.AddPhoneNumberRequestBody) => {
    try {
      setIsOtpSent(true);
      const authUser = await api.addPhoneNumber({
        ...data,
        isMfa: false
      });
      setPhoneNumber(data.phoneNumber);
      setUser({ ...user, ...authUser });
      setStep("verify");
      setIsOtpSent(false);
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.statusCode) {
        if (error.code === BAD_REQUEST_CODE.MobileUsed) {
          return setError("Phone Number already exists");
        } else if (error.code === BAD_REQUEST_CODE.InvalidMobile) {
          return setError("Invalid Phone Number");
        } else if (error.code === BAD_REQUEST_CODE.MobileVerified) {
          return setError("Phone Number is already verified");
        }
      }
      setIsOtpSent(false);
      setGlobalError(error);
    }
  };

  const verifyPhoneNumber = async (
    data: server.VerifyPhoneNumberRequestBody
  ) => {
    try {
      const authUser = await api.verifyPhoneNumber({ ...data, isMfa: false });
      setUser({ ...user, ...authUser });
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.statusCode) {
        if (error.statusCode === 400) {
          return setIncorrectCode(true);
        }
      }
      setGlobalError(error);
    }
  };

  const reset = () => {
    setIncorrectCode(false);
    setPhoneNumber(null);
    setStep("update");
  };

  return (
    <AddPhoneNumberView
      step={step}
      phoneNumber={phoneNumber}
      incorrectCode={incorrectCode}
      reset={reset}
      updatePhoneNumber={updatePhoneNumber}
      verifyPhoneNumber={verifyPhoneNumber}
      isOtpSent={isOtpSent}
      error={error}
    />
  );
};

export default AddPhoneNumber;
