type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const LodaCard: React.FC<Props> = props => {
  const { className, children, ...restOfProps } = props;
  return (
    <div
      className={`p-6 bg-background-light rounded-3xl shadow-2xl ${className}`}
      {...restOfProps}
    >
      {children}
    </div>
  );
};

export default LodaCard;
