import React from "react";

export const WithdrawIcon: React.FC = props => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M16 0.5008V1.5008C16 1.56636 15.9871 1.63127 15.962 1.69184C15.9369 1.7524 15.9001 1.80743 15.8538 1.85379C15.8074 1.90014 15.7524 1.93691 15.6918 1.962C15.6313 1.98709 15.5664 2 15.5008 2H2V14H15.5008C15.6333 14.0004 15.7603 14.0534 15.8539 14.1472C15.9475 14.2411 16 14.3683 16 14.5008V15.5008C16 15.5664 15.9871 15.6313 15.962 15.6918C15.9369 15.7524 15.9001 15.8074 15.8538 15.8538C15.8074 15.9001 15.7524 15.9369 15.6918 15.962C15.6313 15.9871 15.5664 16 15.5008 16H0.4992C0.433644 16 0.36873 15.9871 0.308164 15.962C0.247599 15.9369 0.192567 15.9001 0.146212 15.8538C0.0998573 15.8074 0.0630865 15.7524 0.0379993 15.6918C0.0129122 15.6313 0 15.5664 0 15.5008L0 0.5008C-6.76452e-07 0.368256 0.0525423 0.241118 0.146115 0.147245C0.239687 0.053373 0.366657 0.000423462 0.4992 0L15.5008 0C15.6333 0.000423462 15.7603 0.053373 15.8539 0.147245C15.9475 0.241118 16 0.368256 16 0.5008Z"
          fill="currentColor"
        />
        <path
          d="M15.5008 7H9V4.5008C9 4.36798 8.94724 4.2406 8.85332 4.14668C8.7594 4.05276 8.63202 4 8.4992 4H7.7936C7.66076 4.00046 7.53334 4.05269 7.4384 4.1456L4.1456 7.4384C4.05269 7.53334 4.00046 7.66076 4 7.7936V8.2064C4.00046 8.33924 4.05269 8.46666 4.1456 8.5616L7.4384 11.8544C7.53334 11.9473 7.66076 11.9995 7.7936 12H8.4992C8.63174 12 8.75888 11.9475 8.85275 11.8539C8.94663 11.7603 8.99958 11.6333 9 11.5008V9H15.5008C15.5664 9 15.6313 8.98709 15.6918 8.962C15.7524 8.93691 15.8074 8.90014 15.8538 8.85379C15.9001 8.80743 15.9369 8.7524 15.962 8.69184C15.9871 8.63127 16 8.56636 16 8.5008V7.5008C16 7.36826 15.9475 7.24112 15.8539 7.14725C15.7603 7.05337 15.6333 7.00042 15.5008 7Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
