import Input from "../../components/Input/Input_Logic";
import validator, {
  SignupValidatorPayload
} from "../../helpers/validators/signup-validator";
import { AuthPageLayout, QuickForm, SwitchLogic } from "../../components";

type Props = {
  signup(data: server.SignupRequestBody): Promise<void>;
  navigateToLogin(): void;
  error: string | null;
};

const SignupView: React.FC<Props> = props => {
  return (
    <AuthPageLayout
      navigateComponent={
        <div className="mt-6">
          Already have an account?{" "}
          <span
            className="text-purple-electric cursor-pointer"
            onClick={props.navigateToLogin}
          >
            Login
          </span>
        </div>
      }
    >
      <QuickForm<SignupValidatorPayload>
        initialValues={{
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          confirmPassword: "",
          acceptedTermsAndConditions: false
        }}
        validator={validator}
        onSubmit={props.signup}
        error={props.error}
        buttonTitle="Signup"
      >
        <h2 className="text-3xl mb-4 font-bold">Sign up for Loda</h2>
        <p className="font-medium mb-3">
          Please complete the form below to access the member portal.
        </p>
        <Input name="firstName" placeholder="First Name" />
        <Input name="lastName" placeholder="Last Name" />
        <Input name="email" placeholder="Email" />
        <Input name="password" placeholder="Password" type="password" />
        <Input
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
        />

        <div className="mt-6 mb-4">
          <SwitchLogic
            name="acceptedTermsAndConditions"
            layout="default"
            className="term-condition-checkbox"
            label="I expressly agree to the LODA V1 General Terms and Conditions and understand that my account information will be used according to the LODA Privacy Policy."
          />
        </div>
        <div className="mb-5 text-xs pl-10">
          <a
            className="text-purple-electric cursor-pointer mr-5 hover:underline"
            href="https://loda.finance/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className="text-purple-electric cursor-pointer mr-5 hover:underline"
            href="https://loda.finance/terms-conditions/"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </div>
      </QuickForm>
    </AuthPageLayout>
  );
};

export default SignupView;
