import { ToolTip, SelectLVRButton } from "..";
import { getCollateralRequired } from "../../helpers/loan-helpers";
import {
  COLLATERAL_RATIO_BUTTON_DATA,
  LVR_EXPLANATION
} from "../../helpers/constants";

export type Props = {
  asset: server.Asset;
  systemState: server.SystemState;
  loanAmount: number;
  collateralToDebt: number;
  setLoanAmount(newValue: number): void;
  setCollateralToDebt(cr: number): void;
};

const DepositCalculator: React.FC<Props> = props => {
  const collateralRequired = getCollateralRequired(
    props.loanAmount,
    props.collateralToDebt,
    props.asset.assetPriceAUD
  );

  return (
    <form>
      <div className="text-white w-100 mt-6 block sm:grid grid-cols-2">
        <div className="flex items-center opacity-70">Amount AUD: $ </div>
        <div className="flex items-center h-14 my-4 depositInput px-4 rounded-xl">
          <span>$</span>
          <input
            value={props.loanAmount}
            onChange={e => {
              let { value } = e.target;
              props.setLoanAmount(Number(value));
            }}
            className="flex-grow text-white outline-none text-base h-full bg-none text-right"
            type="number"
            min={props.systemState.minimumLoanAmount}
            max={props.systemState.maximumLoanAmount}
            onInput={e => {
              const target = e.target as HTMLTextAreaElement;
              target.value = Math.max(0, parseInt(target.value)).toString();
            }}
            readOnly
          />
        </div>
        <div className="flex items-center opacity-70 deposit-tooltip">
          LVR &nbsp;
          <ToolTip value={LVR_EXPLANATION} />
          &nbsp;&nbsp;&nbsp;
        </div>
        <div className={"my-4"}>
          <div className="flex rounded-xl mb-4 ">
            {COLLATERAL_RATIO_BUTTON_DATA.map(cbd => {
              return (
                <SelectLVRButton
                  title={cbd.title}
                  onClick={e => {
                    props.setCollateralToDebt(cbd.value);
                    e.preventDefault();
                  }}
                  selected={props.collateralToDebt === cbd.value}
                  key={cbd.title}
                />
              );
            })}
          </div>
        </div>
        <div className="flex items-center opacity-70">
          Required Collateral (BTC):
        </div>
        <div className="flex my-4 text-purple-electric items-center depositInput rounded-xl h-14 px-4 justify-between">
          <img
            className="sm:block w-6 h-6"
            src={props.asset.imageURL}
            alt={props.asset.assetId}
          ></img>
          <span className="text-white">
            {collateralRequired.toFixed(6)} {props.asset.assetId}
          </span>
        </div>
      </div>
    </form>
  );
};

export default DepositCalculator;
