import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import LoginView from "./Login_View";
import useGlobalError from "../../hooks/useGlobalError";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../../services/identity-auth.service";
import {
  getMultiFactorResolver,
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier
} from "firebase/auth";

const Login: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { setGlobalError } = useGlobalError();
  const [verificationId, setVerificationId] = useState<string>();
  const [resolver, setResolver] = useState<MultiFactorResolver>();
  const [error, setError] = useState<string | null>(null);
  const navigateToSignup = () => {
    history.push("/signup");
  };

  useEffect(() => {
    if (user) {
      history.push("/account");
    }
  }, [user, history]);

  const handleLogin = async (data: server.LoginRequestBody) => {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      //  @ts-ignore
      window.gtag("event", "login", {
        method: "Website"
      });
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.code) {
        if (
          error.code === "auth/wrong-password" ||
          error.code === "auth/user-not-found"
        )
          return setError("Please check your email and password");
        if (error.code === "auth/too-many-requests")
          return setError(
            "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
          );
        if (error.code === "auth/multi-factor-auth-required") {
          const resolver = getMultiFactorResolver(
            auth,
            err as MultiFactorError
          );
          const recaptchaVerifier = new RecaptchaVerifier(
            "login-button",
            { size: "invisible" },
            auth
          );
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          const verifyId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          );

          setVerificationId(verifyId);
          setResolver(resolver);
          setError(error.code);
        }
      }
      if (error.code !== "auth/multi-factor-auth-required")
        setGlobalError(error);
    }
  };

  const verifyNumber = async (data: server.VerifyPhoneNumberRequestBody) => {
    if (resolver && verificationId) {
      const cred = PhoneAuthProvider.credential(verificationId!, data.code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await resolver.resolveSignIn(multiFactorAssertion);
    }
  };

  return (
    <LoginView
      login={handleLogin}
      navigateToSignup={navigateToSignup}
      error={error}
      setError={setError}
      verifyNumber={verifyNumber}
    />
  );
};

export default Login;
