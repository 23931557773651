import { useState } from "react";
import useSWR from "swr";
import useApi from "../../../hooks/useApi";
import UserTableView from "./UserTable_View";

const UserTable: React.FC = () => {
  const api = useApi();
  const [page, setPage] = useState<number>(1);
  const [pageRange, setPageRange] = useState<number>(20);

  const [loanFilter, onFilterLoans] = useState<server.loanFilter>("all");
  const [balanceFilter, onFilterAssets] = useState<server.balanceFilter>("all");

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onChangeSelectedRange = (pageRange: number) => {
    setPage(1);
    setPageRange(pageRange);
  };

  const { data: users } = useSWR(
    `adminUsers-page-${page}-range-${pageRange}-loanFilter${loanFilter}-balanceFilter${balanceFilter}`,
    () =>
      api.adminGetUsers({
        page,
        pageSize: pageRange,
        loanFilter: loanFilter,
        balanceFilter: balanceFilter
      })
  );

  return (
    <UserTableView
      users={users}
      currentPage={page}
      pageRange={pageRange}
      loanFilter={loanFilter}
      assetFilter={balanceFilter}
      onPageChange={onPageChange}
      onChangePageRange={onChangeSelectedRange}
      onFilterLoans={onFilterLoans}
      onFilterAssets={onFilterAssets}
    />
  );
};

export default UserTable;
