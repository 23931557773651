import classnames from "classnames";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  imageURL: string;
  assetId: string;
  isSelected: boolean;
};

const Button: React.FC<Props> = props => {
  const {
    className,
    assetId,
    disabled,
    imageURL,
    isSelected,
    ...restOfProps
  } = props;

  return (
    <button
      className={classnames(
        `flex flex-col justify-center items-center py-3 rounded-lg hover:bg-purple-electric hover:text-white focus:outline-none`,
        {
          "text-black bg-white": isSelected,
          "bg-background-lighter": !isSelected
        },

        className
      )}
      disabled={disabled}
      {...restOfProps}
    >
      <img className="h-5 w-5 sm:h-9 sm:w-9" src={imageURL} alt={assetId} />
      <span className="block mt-2 text-xs">{assetId}</span>
    </button>
  );
};

export default Button;
