import {  Modal, DepositCalculator, Loader } from "..";
import { CollateralCalculatorState } from "../../hooks/useCollateralCalculatorState";

type Props = CollateralCalculatorState & {
  asset: server.Asset | undefined;
  onClose: () => void;
};

const DepositAddressModal: React.FC<Props> = props => {
  if (!props.asset || !props.systemState) {
    return null;
  }

  return (
    <Modal
      title={`Deposit ${props.asset.assetId}`}
      show={!!props.asset}
      onClose={props.onClose}
    >
      {props.asset && props.systemState ? (
        <>
          {/* <UserDepositAddressQR assetId={props.asset.assetId} /> */}
          {/* <UserDepositAddressQR /> */}
          <DepositCalculator
            asset={props.asset}
            systemState={props.systemState}
            loanAmount={props.loanAmount}
            collateralToDebt={props.collateralToDebt}
            setLoanAmount={props.setLoanAmount}
            setCollateralToDebt={props.setCollateralToDebt}
          />
        </>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

export default DepositAddressModal;
