import classnames from "classnames";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  selected: boolean;
};

const SelectLVRButton: React.FC<Props> = props => {
  const { className, title, value, disabled, selected, ...restOfProps } = props;

  return (
    <button
      className={classnames(
        `w-14 sm:w-16 rounded-lg m-1 h-14 content-center items-center text-white border-2 border-solid border-other-gray focus:outline-none`,
        {
          "text-white bg-purple-electric shadow hover:bg-purple-electric ": selected,
          "text-white bg-background-light shadow hover:text-background-light": !selected,
          "hover:bg-gray-200": !disabled
        },
        className
      )}
      disabled={disabled}
      {...restOfProps}
    >
      {title}%
    </button>
  );
};

export default SelectLVRButton;
