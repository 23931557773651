import Input from "../Input/Input_Logic";
import QuickForm from "../QuickForm/QuickForm_View";
import phoneNumberValidator from "../../helpers/validators/phone-number-validator";
import phoneNumberVerificationValidator from "../../helpers/validators/phone-number-verification-validator";
import PinInput from "react-pin-input";
import React from "react";
import { useField, useFormikContext } from "formik";
import { Loader } from "..";

export type Step = "update" | "verify";

type Props = {
  step: Step;
  phoneNumber: string | null;
  incorrectCode: boolean;
  reset: () => void;
  updatePhoneNumber: (data: server.AddPhoneNumberRequestBody) => Promise<void>;
  verifyPhoneNumber: (
    data: server.VerifyPhoneNumberRequestBody
  ) => Promise<void>;
  isOtpSent: boolean;
  error: string | null;
};

const AddPhoneNumberView: React.FC<Props> = ({ isOtpSent, ...props }) => {
  const [seconds, setSeconds] = React.useState(0);
  React.useEffect(() => {
    if (seconds > 0 && !isOtpSent) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [setSeconds, seconds, isOtpSent]);

  const resentCode = () => {
    props.updatePhoneNumber({
      phoneNumber: props.phoneNumber!,
      isMfa: false
    });
    setSeconds(30);
  };

  if (props.step === "update") {
    return (
      <div className="text-center px-3 pt-4">
        <p className="text-base my-2t text-gray">
          Adding a phone number helps secure your account and allows us to keep
          you updated on the status of your loan.
          <br /> Please verify your number below.
        </p>
        <QuickForm<server.AddPhoneNumberRequestBody>
          key="update"
          initialValues={{
            phoneNumber: props.phoneNumber || "",
            isMfa: false
          }}
          validator={phoneNumberValidator}
          onSubmit={props.updatePhoneNumber}
          error={props.error}
          buttonTitle="Submit"
        >
          <Input
            name="phoneNumber"
            placeholder="Mobile Number (+61412 345 678)"
          />
        </QuickForm>
      </div>
    );
  }

  return (
    <div className="text-center">
      <h2 className="title-font mb-4">Mobile Verification</h2>
      <p className="text-base m-0 mb-2 text-gray">
        We have sent a 6-digit verification code to{" "}
        <span className="text-white">{props.phoneNumber}</span>.
      </p>
      <p className="mb-1 cursor-pointer text-purple-electric">
        <button onClick={props.reset}>Not the right mobile number?</button>
      </p>
      <QuickForm<server.VerifyPhoneNumberRequestBody>
        key="verify"
        initialValues={{
          code: "",
          isMfa: false
        }}
        validator={phoneNumberVerificationValidator}
        onSubmit={props.verifyPhoneNumber}
        error={props.incorrectCode ? "Code does not match. Try again." : null}
        buttonTitle="Check Code"
      >
        <ReactInput />
        {/* <Input name="code" placeholder="Verification code" /> */}
      </QuickForm>
      <p className="text-gray mt-12">
        Didn’t receive the code?{" "}
        {isOtpSent ? (
          <Loader />
        ) : (
          <span
            className={`text-purple-electric ${
              !seconds ? "cursor-pointer" : ""
            }`}
            onClick={() => !seconds && resentCode()}
          >
            {seconds ? seconds : "Resend Code"}
          </span>
        )}
      </p>
    </div>
  );
};

export default AddPhoneNumberView;

const ReactInput: React.FC<any> = () => {
  const [value, setValue] = React.useState("");
  const { setFieldValue } = useFormikContext();

  const [field] = useField("code");

  const onChange = (v: string) => {
    setValue(v);
    setFieldValue("code", v);
  };
  return (
    <>
      <PinInput
        length={6}
        initialValue={value}
        onChange={onChange}
        type="numeric"
        inputMode="number"
        focus
        style={{ padding: "30px 0 10px", margin: "0 -30px" }}
        inputStyle={{
          padding: "5px",
          width: 56,
          height: 56,
          background: "#18171F",
          borderColor: "#382F3E",
          borderRadius: 12,
          margin: "0 5px"
        }}
        inputFocusStyle={{ borderColor: "#7A4FFF" }}
      />
      <Input {...field} hidden placeholder="Verification code" />
    </>
  );
};
