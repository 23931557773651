type Props = {
  className: string;
};

const LodaLogo: React.FC<Props> = props => {
  return (
    <svg
      viewBox="0 0 100 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M20.8595 17.1592V21.5231H0V1.28906H5.21487V17.1592H20.8595Z"
          fill="white"
        ></path>
        <path
          d="M21.6119 11.378C21.6119 4.67345 25.4386 1 34.3594 1C43.2561 1 47.1069 4.70155 47.1069 11.378C47.1069 18.0865 43.252 21.8122 34.3594 21.8122C25.4386 21.8122 21.6119 18.1146 21.6119 11.378ZM41.896 11.378C41.896 7.27497 39.8117 5.36397 34.3634 5.36397C28.9152 5.36397 26.8308 7.24285 26.8308 11.378C26.8308 15.5412 28.9474 17.4482 34.3634 17.4482C39.7795 17.4482 41.896 15.5131 41.896 11.378Z"
          fill="white"
        ></path>
        <path
          d="M73.5595 11.4061C73.5595 18.2873 68.9522 21.5231 63.7092 21.5231H48.99V1.28906H63.7092C68.9522 1.28906 73.5595 4.52491 73.5595 11.4061ZM68.3446 11.4061C68.3446 6.49211 65.6808 5.65304 61.7375 5.65304H54.2049V17.1592H61.7375C65.6808 17.1592 68.3446 16.3201 68.3446 11.4061Z"
          fill="white"
        ></path>
        <path
          d="M91.1194 16.5368C88.3913 16.7296 86.2103 18.9055 86.0172 21.6275C86.0172 21.6435 85.989 21.6435 85.989 21.6275C85.7959 18.9055 83.615 16.7296 80.8868 16.5368C80.8707 16.5368 80.8707 16.5087 80.8868 16.5087C83.615 16.316 85.7959 14.1401 85.989 11.4181C85.989 11.4021 86.0172 11.4021 86.0172 11.4181C86.2103 14.1401 88.3913 16.316 91.1194 16.5087C91.1395 16.5128 91.1395 16.5368 91.1194 16.5368Z"
          fill="#582AE9"
        ></path>
        <path
          d="M100 21.6435H94.2339L86.0052 5.25556L81.8646 13.4616H81.8687L79.9211 17.3358L77.7483 21.6435H72.0103L82.4722 1.40948H89.5139L100 21.6435Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100" height="22" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LodaLogo;
