import React from "react";
import { Button } from "..";
import useApi from "../../hooks/useApi";
import InputView from "../Input/Input_View";

type Props = {
  loan: server.Loan | null;
};
const TransferCollateralAdminView: React.FC<Props> = ({ loan }) => {
  const [isformSubmit, setIsformSubmit] = React.useState(false);
  const [text, setText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const api = useApi();

  const onTransferClick = async () => {
    setIsLoading(true);
    try {
      if (loan) {
        await api.transferCollateral({ loanId: loan.id });
        setText("Transfer Successful");
      } else {
        setText("Transfer Failed");
      }
    } catch (error) {
      setText("Transfer Failed");
    }
    setIsLoading(false);
    setIsformSubmit(true);
  };

  return (
    <>
      {!isformSubmit ? (
        <>
          <InputView
            name="walletAddress"
            value={loan?.withdrawAddress}
            readOnly
            disabled
            placeholder="Wallet Address"
          />
          <InputView
            name="transferAmount"
            value={Number(loan?.collateral).toFixed(6)}
            readOnly
            disabled
            placeholder="Amount"
          />
          <div className="mt-20 flex gap-6 justify-between">
            <Button
              loading={isLoading}
              color="green"
              onClick={onTransferClick}
              className="w-full"
              type="submit"
            >
              Transfer
            </Button>
          </div>
        </>
      ) : (
        <div className="text-center p-4">{text}</div>
      )}
    </>
  );
};

export default TransferCollateralAdminView;
