import { createContext } from "react";
import LodaApi from "../apis/LodaApi";

export const ApiContext = createContext<LodaApi>(null as any);

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const api = new LodaApi(process.env.REACT_APP_API_URL!);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
