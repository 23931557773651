import { useHistory } from "react-router";
import { NavBar, Loader } from "../";
import useAuth from "../../hooks/useAuth";

type Props = {
  showHeader: boolean;
  children: React.ReactNode;
  activeTab?: string;
  loading?: boolean;
  hideFooter?: boolean;
};

const PageLayout: React.FC<Props> = ({
  showHeader,
  children,
  loading,
  hideFooter
}) => {
  const { location } = useHistory();
  const { user } = useAuth();
  return (
    <div className="flex flex-1 h-screen overflow-auto bg-black background-main">
      <header className="fixed w-full bg-background z-40">
        {showHeader && <NavBar />}
      </header>
      <main
        className={`bg-cover flex flex-1 py-4 overflow-y-auto ${
          location.pathname === "/login" ? "background-with-dot" : ""
        } ${showHeader ? "mt-16" : null}`}
      >
        <div className="flex flex-col flex-1 max-w-7xl px-3 md:mx-auto">
          {loading ? (
            <div className="flex flex-1 justify-center items-center">
              <Loader className="text-5xl" />
            </div>
          ) : (
            <div className="flex flex-1 flex-col pb-4">{children}</div>
          )}
          {user && !hideFooter && (
            <div className="mt-6 md:flex gap-4 app-footer pt-8 text-xs text-center pb-5">
              <span>
                © {new Date().getFullYear()} Loda – The Future of Credit
              </span>
              <div className="flex gap-3 text-gray-400">
                <a
                  className="text-white cursor-pointer mr-5 hover:underline"
                  href="https://loda.finance/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                <a
                  className="text-white cursor-pointer mr-5 hover:underline"
                  href="https://loda.finance/terms-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default PageLayout;
