import classnames from "classnames";

export type BubbleTextColor = "green" | "red" | "yellow" | "blue" | "gray";

type Props = {
  color: BubbleTextColor;
  className?: string;
};

const BubbleTextView: React.FC<Props> = props => {
  return (
    <span
      className={classnames(
        "px-2 py-2 text-xs rounded-xl inline-block w-full text-center",
        {
          "border-2 border-solid border-green-800 text-green-300":
            props.color === "green",
          "border-2 border-solid border-yellow-800 text-yellow-300":
            props.color === "yellow",
          "border-2 border-solid border-red-800 text-red-300":
            props.color === "red",
          "border-2 border-solid border-blue-800 text-blue-300":
            props.color === "blue",
          "border-2 border-solid border-gray-800 text-grey-300":
            props.color === "gray"
        }
      )}
    >
      {props.children}
    </span>
  );
};

export default BubbleTextView;
