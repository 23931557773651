import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  AddBankAccount,
  VerifyIdentity,
  VerifyEmail,
  AddPhoneNumber
} from "../";
import { CrossCloseLogo, MobileCloseLogo } from "../CloseLogo/CloseLogo_View";
import useWindowSize from "../../hooks/useWindowSize";
import React, { useRef } from "react";
import { ModalBase, Button } from "..";
import { User } from "../../providers/Auth";

export type Tab = "email" | "phone" | "bank" | "kyc";

type Props = {
  show: boolean;
  tab: Tab;
  user: User;
  onClose: () => void;
  changeTab: (tab: Tab) => void;
};

const OnboardingModalView: React.FC<Props> = props => {
  const closeButtonRef = useRef(null);
  const size = useWindowSize();
  const tabButtonData: {
    title: string;
    tab: Tab;
    completed: boolean;
    noMargin?: boolean;
  }[] = [
    {
      title: "Verify Email •",
      tab: "email",
      completed: props.user.isEmailVerified ? props.user.isEmailVerified : false
    },
    {
      title: "Verify Phone •",
      tab: "phone",
      completed: props.user.phoneNumberVerified
    },
    {
      title: "Bank Details •",
      tab: "bank",
      completed: props.user.hasBankAccount
    },
    {
      title: "Identity •",
      tab: "kyc",
      completed: props.user.idVerified,
      noMargin: true
    }
  ];

  return (
    <ModalBase
      className="verify-modal"
      show={props.show}
      onClose={props.onClose}
    >
      <div className="flex justify-end">
        <button
          className="text-sm font-medium text-gray-300 hover:text-purple-electric cursor-pointer focus:outline-none"
          onClick={props.onClose}
          ref={closeButtonRef}
        >
          {size.width !== undefined && size.width > 640 ? (
            <CrossCloseLogo
              className={"absolute left-16 sm:right-5 sm:left-auto top-6"}
            />
          ) : (
            <MobileCloseLogo
              className={"absolute left-3 sm:right-5 sm:left-auto top-6"}
            />
          )}
        </button>
      </div>
      <div className="flex modal-tabs">
        {tabButtonData.map(tb => (
          <TabButton
            key={tb.tab}
            title={tb.title}
            selected={props.tab === tb.tab}
            onClick={() => props.changeTab(tb.tab)}
            completed={tb.completed}
            noMargin={!!tb.noMargin}
          />
        ))}
      </div>
      <div className="modal-content">
        <CompletedWrapper
          show={props.tab === "email"}
          completedMessage="Email verified"
          completed={
            props.user.isEmailVerified ? props.user.isEmailVerified : false
          }
        >
          <VerifyEmail />
        </CompletedWrapper>
        <CompletedWrapper
          show={props.tab === "phone"}
          completedMessage="Phone Number verified"
          completed={props.user.phoneNumberVerified}
        >
          <AddPhoneNumber />
        </CompletedWrapper>
        <CompletedWrapper
          show={props.tab === "bank"}
          completedMessage="Bank details saved"
          completed={props.user.hasBankAccount}
        >
          <AddBankAccount />
        </CompletedWrapper>

        <CompletedWrapper
          show={props.tab === "kyc"}
          completedMessage="Identity verified"
          completed={props.user.idVerified}
          nextStep={() => props.onClose()}
        >
          <VerifyIdentity />
        </CompletedWrapper>
      </div>
    </ModalBase>
  );
};

const CompletedWrapper: React.FC<{
  show: boolean;
  completed: boolean;
  completedMessage: string;
  nextStep?: React.ReactNode;
}> = props => {
  if (!props.show) {
    return null;
  }

  if (props.completed) {
    return (
      <div className="py-10 text-center">
        <span>{props.completedMessage}</span>
        {props.nextStep && <div className="block mt-3">{props.nextStep}</div>}
      </div>
    );
  }

  return <>{props.children}</>;
};

const TabButton: React.FC<{
  title: string;
  selected: boolean;
  completed: boolean;
  onClick: () => void;
  noMargin: boolean;
}> = props => {
  return (
    <Button
      className={`w-1/3 h-20 tabs-btn ${
        props.selected ? "tabs-highlited" : ""
      }`}
      onClick={props.onClick}
    >
      {props.completed && (
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-xl text-green-500"
        />
      )}{" "}
      <span>{props.title}</span>
    </Button>
  );
};

export default OnboardingModalView;
