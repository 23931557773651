import validator from "validator";

export type SignupValidatorPayload = server.SignupRequestBody & {
  confirmPassword: string;
  acceptedTermsAndConditions: boolean;
};

const signupValidator = ({
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  acceptedTermsAndConditions
}: SignupValidatorPayload): ValidatorResult<SignupValidatorPayload> => {
  const errors: ValidatorResult<SignupValidatorPayload> = {};

  if (!validator.isEmail(email)) errors.email = "Please enter a valid email";
  if (!password) errors.password = "Please enter a password";
  if (!confirmPassword) errors.confirmPassword = "Please confirm password";
  if (password !== confirmPassword)
    errors.confirmPassword = "Passwords do not match";
  if (!validator.isStrongPassword(password))
    errors.password =
      "Your password should have at least 1 uppercase, 1 lowercase, 1 number, 1 symbol and a minimum of 8 characters";
  if (!firstName) errors.firstName = "Please enter your first name";
  if (!lastName) errors.lastName = "Please enter your last name";
  if (!acceptedTermsAndConditions)
    errors.acceptedTermsAndConditions =
      "Please accept the Terms and Conditions";

  return errors;
};

export default signupValidator;
