import React, { useState } from "react";
import useGlobalError from "../../hooks/useGlobalError";
import { ResetValidatorPayload } from "../../helpers/validators/resetPassword-validator";
import ResetPasswordView from "./ResetPassword_View";
import useApi from "../../hooks/useApi";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword: React.FC = () => {
  const { setGlobalError } = useGlobalError();

  const [error, setError] = useState<string | null>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const query = useQuery();
  const api = useApi();
  const submitHandler = async (data: ResetValidatorPayload) => {
    try {
      const token = query.get("token");
      const newPassword = data.password;
      const confirmPassword = data.password;
      if (!token || newPassword.toLowerCase() !== confirmPassword.toLowerCase())
        return setError("Password not matched");
      const result = await api.resetPassword({ token, newPassword });
      if (result.success) setIsSubmit(true);
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.statusCode) {
        if (error.statusCode === 404) {
          const notFoundError = error as server.NotFoundResponse;
          if (notFoundError.resource === "user") {
            return setError(error.message);
          }
        }
      }

      setGlobalError(error);
    }
  };
  return (
    <ResetPasswordView
      isSubmit={isSubmit}
      submitHandler={submitHandler}
      error={error}
    />
  );
};

export default ResetPassword;
