import BN from "bignumber.js";

const fiatFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol"
});

export const numberToFormattedAUD = (amount: number) =>
  fiatFormatter.format(amount);

export const collateralFormatter = (
  collateral: string,
  decimalPlaces: number,
  desiredCollateral?: string
) => {
  if (new BN(collateral) !== new BN(0)) {
    if (desiredCollateral) {
      return new BN(desiredCollateral)
        .minus(new BN(collateral))
        .dp(decimalPlaces, 2)
        .toString();
    }
  }
  if (desiredCollateral)
    return new BN(desiredCollateral).dp(decimalPlaces, 2).toString();
  return new BN(collateral).dp(decimalPlaces, 2).toString();
};

export const decimalInputLimiter = (
  value: string,
  decimalPlaces: number
): string => {
  if (!value) {
    return "0";
  }
  return value.indexOf(".") >= 0
    ? value.substr(0, value.indexOf(".")) +
        value.substr(value.indexOf("."), decimalPlaces + 1)
    : value;
};
export const formatWithUndefined = (
  value: number | string | undefined,
  unit: number
) => {
  if (value === undefined) return "00.00";
  return Number(value).toFixed(unit);
};

export const formatWithFixedDown = (
  value: number | string | undefined,
  unit: number
) => {
  const formattedVal = formatWithUndefined(value, unit);
  if (unit === 0) return formattedVal;
  const [intPart, decimalPart] = formattedVal.split(".");
  const updatedDecimalPart = decimalPart.slice(0, unit - 1);
  const res = intPart + "." + updatedDecimalPart;
  return res;
};
