import { QuickForm, Input } from "..";
import { GENDER, getAvailableGenders } from "../../helpers/constants";
import { IDENTITY_ERRORS } from "../../helpers/general";
import identityValidator, {
  IdentityFormData
} from "../../helpers/validators/verify-identity-validator";
import Select from "../Select/Select_View";

type Props = {
  verifyIdentity: (data: server.VerifyIdentityRequestBody) => Promise<void>;
  onSetState(state: string): void;
  state: string;
  setDlState(state: server.State): void;
  dlState: server.State;
  failedIdentification: boolean;
  verificationCountExceeded: boolean;
  gender: GENDER;
  setGender: (val: GENDER) => void;
};

const STATES: string[] = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
const availableGenders: string[] = getAvailableGenders();

const VerifyIdentity: React.FC<Props> = props => {
  return (
    <div className="text-center px-3">
      <p className="text-sm my-2"></p>
      <QuickForm<IdentityFormData>
        initialValues={{
          dobDay: "",
          dobMonth: "",
          dobYear: "",
          addressState: "NSW",
          addressPostcode: "",
          addressSuburb: "",
          addressStreetName: "",
          addressStreetType: "",
          addressStreetNumber: "",
          addressFlatNumber: "",
          dlState: "NSW",
          dlNumber: "",
          dlFirstName: "",
          dlLastName: "",
          dlMiddleName: "",
          gender: GENDER.Male,
          cardNumber: ""
        }}
        validator={identityValidator}
        disabled={props.verificationCountExceeded}
        onSubmit={data => {
          return props.verifyIdentity({
            dob: {
              day: Number(data.dobDay),
              month: Number(data.dobMonth),
              year: Number(data.dobYear)
            },
            address: {
              state: props.state,
              postcode: data.addressPostcode,
              suburb: data.addressSuburb,
              streetName: data.addressStreetName,
              streetType: data.addressStreetType,
              streetNumber: data.addressStreetNumber,
              flatNumber: data.addressFlatNumber
            },
            driversLicense: {
              state: props.dlState,
              number: data.dlNumber,
              firstName: data.dlFirstName,
              lastName: data.dlLastName,
              middleName: data.dlMiddleName,
              cardNumber: data.cardNumber,
            },
            gender: props.gender
          });
        }}
        error={
          props.verificationCountExceeded
            ? IDENTITY_ERRORS.VerificationCountExceeded
            : props.failedIdentification
            ? IDENTITY_ERRORS.UnknownError
            : null
        }
        buttonTitle="Save Details"
      >
        <div className="block">
          <div className="text-left">Drivers License</div>
          <div className="flex-grow">
            <div className="flex gap-4 justify-between">
              <Input
                name="dlNumber"
                type="text"
                placeholder="Licence No"
                maxLength={11}
                minLength={5}
                outerDivClassName="w-full"
              />
              <Select
                name="dlState"
                value={props.dlState}
                className="bg-gray-200 w-min h-14 mt-5"
                onChange={e => {
                  props.setDlState(e.target.value);
                }}
              >
                {STATES.sort((a, b) => {
                  return a > b ? 1 : -1;
                }).map(sa => (
                  <option value={sa} key={sa}>
                    {sa}
                  </option>
                ))}
              </Select>
            </div>
            <div className="grid grid-cols-2 gap-4 w-full">
              <Input
                name="dlFirstName"
                type="text"
                placeholder="First Name"
                maxLength={30}
              />
              <Input
                name="dlMiddleName"
                type="text"
                placeholder="Middle Name(s)"
                maxLength={30}
              />
            </div>
            <Input
              name="dlLastName"
              type="text"
              placeholder="Last Name"
              maxLength={30}
            />
          </div>
        </div>
        <div className="block mt-10">
          <div className="text-left">Date of Birth</div>
          <div className="flex-grow">
            <div className="flex gap-4">
              <Input
                name="dobDay"
                type="text"
                placeholder="DD"
                maxLength={2}
                minLength={2}
              />
              <Input
                name="dobMonth"
                type="text"
                placeholder="MM"
                maxLength={2}
                minLength={2}
              />
              <Input
                name="dobYear"
                type="text"
                placeholder="YYYY"
                maxLength={4}
                minLength={4}
              />
            </div>
          </div>
        </div>
        <div className="block mt-10 flex">
          <div className="text-left flex-grow mr-5">
            Gender
            <Select
              name="gender"
              value={props.gender}
              className="bg-gray-200 h-14 mt-5"
              onChange={e => {
                props.setGender(e.target.value);
              }}
            >
              {availableGenders.map((gender, index) => (
                <option value={gender} key={index}>
                  {gender}
                </option>
              ))}
            </Select>
          </div>
          <div className="text-left flex-grow ml-5">
            Card Number
            <Input
              name="cardNumber"
              type="text"
              placeholder="Card No"
              outerDivClassName="w-full"
            />
          </div>
        </div>


        <div className="block mt-10">
          <div className=" text-left">Home Address</div>
          <div className="flex-grow w-full">
            <div className="grid grid-cols-2 gap-4">
              <Input
                name="addressFlatNumber"
                type="text"
                placeholder="Flat No"
                maxLength={30}
              />
              <Input
                name="addressStreetNumber"
                type="text"
                placeholder="Street No"
                maxLength={30}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Input
                name="addressStreetName"
                type="text"
                placeholder="Street Name"
                maxLength={30}
              />
              <Input
                name="addressStreetType"
                type="text"
                placeholder="Street Type"
                maxLength={30}
              />
            </div>
            <Input
              name="addressSuburb"
              type="text"
              placeholder="Suburb"
              maxLength={30}
            />
            <div className="flex gap-4 justify-between">
              <Input
                name="addressPostcode"
                type="text"
                placeholder="Postcode"
                maxLength={4}
                outerDivClassName="w-full"
              />
              <Select
                name="addressState"
                value={props.state}
                className="bg-gray-200 rounded-2xl w-min h-14 mt-5"
                onChange={e => {
                  props.onSetState(e.target.value);
                }}
              >
                {STATES.sort((a, b) => {
                  return a > b ? 1 : -1;
                }).map(sa => (
                  <option value={sa} key={sa}>
                    {sa}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </QuickForm>
    </div>
  );
};

export default VerifyIdentity;
