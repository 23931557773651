import React from "react";

type props = {
  className?: string;
};

const CopyIcon: React.FC<props> = prop => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={prop.className}
    >
      <path
        d="M6.6857 0H16.4572C17.3143 0 18 0.674795 18 1.5183V11.1343C18 11.9778 17.2928 12.6526 16.4572 12.6526H14.1429V11.3873H16.4572C16.5857 11.3873 16.6928 11.2819 16.6928 11.1553V1.5183C16.6928 1.39181 16.5857 1.28637 16.4572 1.28637H6.66431C6.53571 1.28637 6.42857 1.39181 6.42857 1.5183V5.06104H5.14286V1.5183C5.14286 0.674795 5.82856 0 6.6857 0Z"
        fill="#7A4FFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 7.8446C0 7.00109 0.685704 6.3263 1.54285 6.3263H11.3143C12.1714 6.3263 12.8571 7.00109 12.8786 7.8446V17.4816C12.8786 18.3252 12.1714 19 11.3357 19H1.54285C0.685704 19 0 18.3041 0 17.4816V7.8446ZM11.3357 17.6925C11.4643 17.6925 11.5714 17.5871 11.5714 17.4606V7.8446C11.5714 7.7181 11.4643 7.61266 11.3357 7.61266H1.54285C1.41431 7.61266 1.30716 7.7181 1.30716 7.8446V17.4606C1.30716 17.5871 1.41431 17.6925 1.54285 17.6925H11.3357Z"
        fill="#7A4FFF"
      />
    </svg>
  );
};
export default CopyIcon;
