import useAuth from "../../hooks/useAuth";
import useApi from "../../hooks/useApi";
import {
  multiFactor,
  PhoneAuthProvider,
  RecaptchaVerifier
} from "@firebase/auth";
import { useState } from "react";
import TwoFaModalView from "./TwoFaModal_View";
import {
  getMultiFactorResolver,
  MultiFactorError,
  MultiFactorResolver,
  PhoneMultiFactorGenerator,
  signInWithEmailAndPassword
} from "firebase/auth";
import useGlobalError from "../../hooks/useGlobalError";
import { getAuth } from "firebase/auth";
import { formatNumbers } from "../../helpers/general";

type Props = {
  show: boolean;
  onClose: () => void;
  isTwoFaEnabled: boolean;
  updateTwoFaEnabled: (data: boolean) => void;
};

const TwoFaModal: React.FC<Props> = props => {
  const auth = getAuth();
  const [error, setError] = useState<string | null>(null);
  const api = useApi();
  const { user, setUser } = useAuth();
  const [, setVerifyId] = useState<string>();
  const { setGlobalError } = useGlobalError();
  const [verificationId, setVerificationId] = useState<string>();
  const [resolver, setResolver] = useState<MultiFactorResolver>();
  const [step, setStep] = useState<string>("emailVerification");

  const [
    recaptchaVerifier,
    setRecaptchaVerifier
  ] = useState<RecaptchaVerifier>();

  const updateStep = (value: string) => {
    setStep(value);
  };

  const handleLogin = async (data: server.LoginRequestBody) => {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      updateStep("phoneVerification");
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.code) {
        if (
          error.code === "auth/wrong-password" ||
          error.code === "auth/user-not-found"
        )
          return setError("Please check your email and password");
        if (error.code === "auth/too-many-requests")
          return setError(
            "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
          );
        if (error.code === "auth/multi-factor-auth-required") {
          const resolver = getMultiFactorResolver(
            auth,
            err as MultiFactorError
          );

          const recaptchaVerifier = new RecaptchaVerifier(
            "login-button",
            { size: "invisible" },
            auth
          );
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          );
          setVerificationId(verificationId);
          setResolver(resolver);
          updateStep("phoneVerification");
        }
      }
      if (error.code !== "auth/multi-factor-auth-required")
        setGlobalError(error);
    }
  };

  const updateNumber = async (
    data: server.AddPhoneNumberRequestBody
  ): Promise<void> => {
    const authUser = await api.addPhoneNumber({
      phoneNumber: data.phoneNumber,
      isMfa: true
    });
    setUser({ ...user, ...authUser });

    const recaptchaVerifier = new RecaptchaVerifier(
      "update-button",
      { size: "invisible" },
      auth
    );
    const multiFactorSession = await multiFactor(
      auth.currentUser!
    ).getSession();
    const phoneInfoOptions = {
      phoneNumber: data.phoneNumber,
      session: multiFactorSession
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verifyId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );

    setVerifyId(verifyId);
    api.attachAuthHeaderInterceptor(
      (): Promise<string> => {
        return auth.currentUser!.getIdToken();
      }
    );
  };
  const onClose = async (data: server.VerifyPhoneNumberRequestBody) => {
    props.onClose();
    const verifiedUser = await api.verifyPhoneNumber({ ...data, isMfa: true });
    setUser({ ...user, ...verifiedUser });
  };
  const verifyNumber = async (data: server.VerifyPhoneNumberRequestBody) => {
    if (resolver) {
      const options = multiFactor(auth.currentUser!).enrolledFactors;
      const cred = PhoneAuthProvider.credential(verificationId!, data.code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      if (options.length === 0)
        await multiFactor(auth.currentUser!).enroll(multiFactorAssertion);
      await resolver.resolveSignIn(multiFactorAssertion);
      props.updateTwoFaEnabled(!props.isTwoFaEnabled);
      updateStep("emailVerification");
      onClose(data);
    } else {
      const multiFactorSession = await multiFactor(
        auth.currentUser!
      ).getSession();

      const phoneInfoOptions = {
        phoneNumber: formatNumbers(user?.phoneNumber),
        session: multiFactorSession
      };

      let verifier: RecaptchaVerifier | null = null;
      if (!recaptchaVerifier) {
        verifier = new RecaptchaVerifier(
          "verify-button",
          { size: "invisible" },
          auth
        );
        setRecaptchaVerifier(verifier);
      }

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verifyId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        verifier === null ? recaptchaVerifier! : verifier!
      );
      const cred = PhoneAuthProvider.credential(verifyId, data.code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(auth.currentUser!).enroll(multiFactorAssertion);

      //await reauthenticateWithCredential(auth.currentUser!, cred);
      props.updateTwoFaEnabled(!props.isTwoFaEnabled);
      updateStep("emailVerification");
      onClose(data);
    }

    if (props.isTwoFaEnabled) {
      const options = multiFactor(auth.currentUser!).enrolledFactors;
      if (options && options.length > 0)
        await multiFactor(auth.currentUser!).unenroll(options[0]);
      onClose(data);
      updateStep("emailVerification");
      props.updateTwoFaEnabled(!props.isTwoFaEnabled);
    }
  };
  return (
    <TwoFaModalView
      phoneNumber={formatNumbers(user?.phoneNumber)}
      verifyNumber={verifyNumber}
      updateNumber={updateNumber}
      show={props.show}
      login={handleLogin}
      error={error}
      onClose={props.onClose}
      email={user!.email}
      step={step}
      isTwoFaEnabled={props.isTwoFaEnabled}
      phoneVerified={false}
      updateStep={updateStep}
    />
  );
};

export default TwoFaModal;
