import { Formik, Form } from "formik";
import { Button } from "../";
import Error from "../error/Error";

type ValidatorResult<T> = { [key in keyof Partial<T>]: string };

type Props<T> = {
  initialValues: T;
  onSubmit(data: T): void | Promise<void>;
  validator(data: T): ValidatorResult<T>;
  buttonTitle: string;
  error: string | null;
  children: React.ReactNode;
  formClassName?: string;
  hideButton?: boolean;
  disabled?: boolean;
  buttonId?: string;
};

const QuickForm = <T extends {}>(props: Props<T>) => {
  return (
    <Formik<T>
      initialValues={props.initialValues}
      validate={props.validator}
      onSubmit={data => props.onSubmit(data)}
      validateOnChange={true}
    >
      {({ isSubmitting }) => (
        <Form className={props.formClassName}>
          {props.children}

          {!props.hideButton && (
            <Button
              id={props.buttonId}
              title={props.buttonTitle}
              className="mt-8 px-7 min-width-btn"
              type="submit"
              loading={isSubmitting}
              disabled={props.disabled}
            />
          )}
          {props.error && <Error>{props.error}</Error>}
        </Form>
      )}
    </Formik>
  );
};

export default QuickForm;
