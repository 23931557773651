import { numberToFormattedAUD } from "../../helpers/formatters";
import {
  ButtonIcon,
  PageLayout,
  LodaCard,
  SwitchView,
  Button,
  LoanStatus,
} from "../../components";
import {
  displayLoanDebt,
  getAssetValueInAud
} from "../../helpers/loan-helpers";
import BN from "bignumber.js";
import { TopUpIcon } from "../../components/Icons/TopupIcon";
import { WithdrawIcon } from "../../components/Icons/WithdrawIcon";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { EmptyPageView } from "./Empty_page_view";

type Props = {
  balances: server.Balances | undefined;
  loans: server.Loan[] | undefined;
  displayEmptyBalances: boolean;
  displayInActiveLoans: boolean;
  openDepositCollateralModal(loan: server.Loan): void;
  toggleDisplayEmptyBalances(): void;
  toggleDisplayInActiveLoans(): void;
  openLoanDetailsModal(loanId: number): void;
  openUserLoanDetailsModal(loan: server.Loan): void;
  openDepositModal(assetId: server.AssetId | undefined): void;
  openWithdrawModal(loan: server.Loan): void;
  openRequestLoanModal(loanId?: number): void;
  fetchLoanContract(loan: server.Loan): Promise<void>;
  openOnboardingModal(show: boolean): void;
  KYC: boolean;
  pendingCollateralTimeLimit: number | null;
};

const AccountView: React.FC<Props> = props => {
  const borrowedAmount = props.loans?.reduce((sum, a) => {
    const openingDept = a.status === "active" ? a.openingDebt : 0;
    return sum + openingDept;
  }, 0)!;

  const outstandingDebt = props.loans?.reduce(
    (sum, a) => sum + a.outstandingDebt,
    0
  )!;

  const collateralAmount = props.balances?.aud!;

  const displayInterestEarning = JSON.parse(
    process.env.REACT_APP_DISPLAY_INTEREST_EARNING!
  );

  return (
    <PageLayout showHeader={true} loading={!props.balances || !props.loans}>
      {!props.KYC && (
        <div className={"grid col-span-6 text-center p-6 error-message"}>
          <span>
            Please click{" "}
            <span
              className={"text-other-green cursor-pointer"}
              onClick={() => props.openOnboardingModal(true)}
            >
              here
            </span>{" "}
            to complete your KYC.
          </span>
        </div>
      )}
      {props.loans?.length! === 0 ? (
        <EmptyPageView
          openRequestLoanModal={() => props.openRequestLoanModal()}
        />
      ) : (
        <>
          <div className="grid gap-6 py-10 mb-2 md:grid-cols-6 divide-y divide-gray-800 divide-border">
            <div className="flex col-span-6 justify-between items-center center-align mobile-view">
              <span className="text-4xl font-bold font-ABC-Favorite-Extended">
                Welcome to Loda
              </span>
              <Button
                title="Borrow"
                className="px-7 width-full margin-t-20"
                onClick={() => props.openRequestLoanModal()}
              />
            </div>
            <div className="col-span-6 flex gap-4 border-none justify-between mobile-view">
              <BorrowedAmount
                title="BORROWED AMOUNT"
                value={borrowedAmount}
                displayInterestEarning={displayInterestEarning}
              />
              <OutStandingDebt
                title="OUTSTANDING DEBT"
                displayInterestEarning={displayInterestEarning}
                outstandingDebt={outstandingDebt}
              />
              <CollateralAmount
                title="COLLATERAL AMOUNT"
                collateralAmount={collateralAmount ?? 0}
              />
            </div>
            {props.loans &&
              props.loans.some(
                item => item.status === "pending-collateral"
              ) && (
                <div
                  className={"grid col-span-6 text-center p-6 error-message"}
                >
                  Pending Collateral: Please deposit your collateral amount
                  within {props.pendingCollateralTimeLimit || "72"} hours of
                  signing contract. Refer to Terms and Conditions.
                </div>
              )}
            <LoanTable
              loans={props.loans!}
              balances={props.balances!}
              openLoanDetailsModal={props.openLoanDetailsModal}
              openUserLoanDetailsModal={props.openUserLoanDetailsModal}
              openDepositCollateralModal={props.openDepositCollateralModal}
              toggleDisplayInActiveLoans={props.toggleDisplayInActiveLoans}
              displayInActiveLoans={props.displayInActiveLoans}
              openDepositModal={props.openDepositModal}
              fetchLoanContract={props.fetchLoanContract}
              openWithdrawModal={props.openWithdrawModal}
              openOnboardingModal={props.openOnboardingModal}
              openRequestLoanModal={props.openRequestLoanModal}
            />
            <CryptoBalancesTable
              balances={props.balances!}
              openDepositModal={props.openDepositModal}
              // Todo - asset withdraw functionality
              // openWithdrawModal={props.openWithdrawModal}
              displayEmptyBalances={props.displayEmptyBalances}
              toggleDisplayEmptyBalances={props.toggleDisplayEmptyBalances}
              displayInterestEarning={displayInterestEarning}
            />
          </div>
        </>
      )}
      {props.children}
    </PageLayout>
  );
};

export default AccountView;

type BorrowedAmountProps = {
  title: string;
  value: number;
  displayInterestEarning: boolean;
};

type OutStandingDebtProps = {
  title: string;
  outstandingDebt: number;
  displayInterestEarning: boolean;
};

const BorrowedAmount: React.FC<BorrowedAmountProps> = props => {
  return (
    <LodaCard className="p-8 w-1/3 py-10 pt-7 width-full margin-b-20">
      <span className="block text-xs mb-4 letter-spacing-06 font-ABC-Favorite-Extended">
        {props.title}
      </span>
      <span className="font-size-32 font-semibold text-other-green font-ABC-Favorite-Extended">
        {numberToFormattedAUD(props.value)}
      </span>
    </LodaCard>
  );
};

const OutStandingDebt: React.FC<OutStandingDebtProps> = props => {
  return (
    <LodaCard className="p-8 w-1/3 py-10 pt-7 width-full margin-b-20">
      <span className="block text-xs mb-4 letter-spacing-06 font-ABC-Favorite-Extended">
        {props.title}
      </span>
      <span className="font-size-32 font-semibold text-other-green font-ABC-Favorite-Extended">
        {numberToFormattedAUD(props.outstandingDebt)}
      </span>
    </LodaCard>
  );
};

type CollateralAmountProps = {
  title: string;
  collateralAmount: number;
};

const CollateralAmount: React.FC<CollateralAmountProps> = props => {
  return (
    <LodaCard className="p-8 w-1/3 py-10 pt-7 width-full">
      <div>
        <span className="block text-xs letter-spacing-06 mb-4 font-ABC-Favorite-Extended">
          {props.title}
        </span>
      </div>
      <span className="font-size-32 font-semibold text-other-green font-ABC-Favorite-Extended">
        {numberToFormattedAUD(props.collateralAmount)}
      </span>
    </LodaCard>
  );
};

type LoanTableProps = {
  loans: server.Loan[];
  balances: server.Balances;
  displayInActiveLoans: boolean;
  toggleDisplayInActiveLoans(): void;
  openLoanDetailsModal(loanId: number): void;
  openWithdrawModal(loan: server.Loan): void;
  openUserLoanDetailsModal(loan: server.Loan): void;
  openDepositModal(assetId: string | undefined): void;
  openDepositCollateralModal(loan: server.Loan): void;
  fetchLoanContract(loan: server.Loan): Promise<void>;
  openOnboardingModal(show: boolean): void;
  openRequestLoanModal(loanId?: number): void;
};

const sortedBy = {
  "pending-review": 2,
  rejected: 2,
  "admin-signed": 2,
  "pending-legal": 2,
  "pending-collateral": 0,
  "pending-outgoing-payment": 2,
  active: 1,
  liquidated: 2,
  "loan-completed": 2,
  "collateral-requested": 2,
  closed: 3,
  cancelled: 3,
  "withdraw-initiated": 4,
  "retry-withdraw": 4,
  "kyc-incomplete": 4,
  draft: 4
};

const sortByProp = (a: LoanWithAssetData, b: LoanWithAssetData) =>
  sortedBy[a.status] - sortedBy[b.status];

const LoanTable: React.FC<LoanTableProps> = props => {
  let data: LoanWithAssetData[] = props.loans.map(l => {
    const balance = props.balances.assets.find(a => a.assetId === l.assetId)!;

    return {
      ...l,
      balance,
      imageURL: balance.imageURL,
      assetPriceAUD: l.status === "closed" ? 0 : balance.assetPriceAUD
    };
  });
  data = data.sort(sortByProp);

  if (!props.displayInActiveLoans) {
    data = data.filter(loan => loan.status === "active");
  }

  if (!props.loans.length) {
    return null;
  }

  return (
    <>
      <div className="hidden sm:block col-span-6 pt-6">
        <Table className="sm:table-auto">
          <TableHead>
            <th className="py-3 text-left pl-4 text-xs font-bold text-purple-light">
              ASSET
            </th>
            <th className="text-left pl-2 pr-4 text-xs font-bold text-purple-light">
              OUTSTANDING
            </th>
            <th className="text-left pl-2 pr-4 text-xs font-bold text-purple-light">
              COLLATERAL
            </th>
            <th className="text-left pl-2 pr-4 text-xs font-bold text-purple-light">
              ASSET PRICE
            </th>
            <th className="text-left pl-2 pr-4 text-xs font-bold text-purple-light">
              LIQUIDATION PRICE
            </th>
            <th className="text-left pl-2 pr-4 text-xs font-bold text-purple-light">
              LOAN STATUS
            </th>
            <th className="text-right pl-2 pr-4 whitespace-nowrap w-56">
              <SwitchView
                checked={props.displayInActiveLoans}
                name="swDisplayActive"
                className="sm:justify-end"
                onChange={props.toggleDisplayInActiveLoans}
                label="Show Inactive Loans"
              />
            </th>
          </TableHead>
          <tbody className="divide-y divide-gray-800 divide-border">
            {data.map(l => {
              return (
                <TableRow
                  key={l.id}
                  loan={l}
                  showDetails={props.openUserLoanDetailsModal}
                  className="cursor-pointer"
                >
                  <td className="pl-4 max-h-3 py-7">
                    <div className="flex items-center">
                      <img src={l.imageURL} alt={`${l.assetId} icon`} />
                      <span className="pr-4 ml-5 font-semibold text-base">
                        {l.assetId}
                      </span>
                    </div>
                  </td>
                  <td className="py-2 pl-2 pr-4 text-left">
                    <span className="block font-semibold font-sans text-base">
                      {displayLoanDebt(l)}
                    </span>
                  </td>
                  <td className="py-2 pl-2 pr-4 text-left pt-9">
                    <span className="block font-semibold font-sans text-base">
                      {new BN(l.collateral).toFixed(6)} {l.assetId}
                    </span>
                    <span className="text-xs pl-2 pr-4 leading-6 font-medium text-purple-light">
                      {numberToFormattedAUD(
                        getAssetValueInAud(l.collateral, l.assetPriceAUD)
                      )}
                    </span>
                  </td>
                  <td className="font-semibold pl-2 pr-4 font-sans text-base text-left">
                    {numberToFormattedAUD(l.assetPriceAUD)}
                  </td>
                  <td className="text-left pl-2 pr-4">
                    <span className="block font-semibold font-sans text-base">
                      {numberToFormattedAUD(l.liquidationPrice)}
                    </span>
                  </td>

                  <td className="text-left pl-2 pr-4 whitespace-nowrap">
                    <LoanStatus loan={l} />
                  </td>

                  <td className="whitespace-nowrap pl-2 pr-4">
                    <div className="flex gap-3 pr-4 justify-end">
                      {l.status === "active" && (
                        <ButtonIcon
                          title="Repay"
                          onClick={e => {
                            e.stopPropagation();
                            props.openLoanDetailsModal(l.id);
                          }}
                          className="border sm:font-normal sm:h-12 flex align-center repay-attention"
                          disabled={l.status !== "active"}
                        />
                      )}
                      {l.status === "kyc-incomplete" && (
                        <ButtonIcon
                          title="Complete KYC"
                          iconClass="highlight-icon"
                          icon={faFileSignature}
                          className="sm:font-normal sm:h-12 pl-4 flex align-center highlight-button"
                          onClick={e => {
                            e.stopPropagation();
                            props.openOnboardingModal(true);
                          }}
                        />
                      )}
                      {l.status === "draft" && (
                        <ButtonIcon
                          title="Continue"
                          icon={faFileSignature}
                          iconClass="highlight-icon"
                          className="sm:font-normal sm:h-12 pl-4 flex align-center highlight-button"
                          onClick={e => {
                            e.stopPropagation();
                            props.openRequestLoanModal(l.id);
                          }}
                        />
                      )}
                      {l.status === "pending-legal" && (
                        <ButtonIcon
                          title="Pending Signature"
                          icon={faFileSignature}
                          className="border sm:font-normal sm:h-12 pl-4 flex align-center"
                          disabled
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        />
                      )}

                      {l.status === "loan-completed" && (
                        <ButtonIcon
                          title="Withdraw"
                          customIcon={<WithdrawIcon />}
                          onClick={e => {
                            e.stopPropagation();
                            props.openWithdrawModal(l);
                          }}
                          className="border sm:font-normal sm:h-12 pl-4 flex align-center"
                          disabled={l.status !== "loan-completed"}
                        />
                      )}

                      {(l.status === "pending-collateral" ||
                        l.status === "active") && (
                        <ButtonIcon
                          title={
                            l.status === "pending-collateral"
                              ? "Deposit"
                              : "Top Up"
                          }
                          onClick={e => {
                            e.stopPropagation();
                            props.openDepositCollateralModal(l);
                          }}
                          className="border sm:font-normal sm:h-12 pl-4 flex align-center"
                          customIcon={<TopUpIcon />}
                        />
                      )}
                    </div>
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className=" col-span-6 display block sm:hidden padding-t-20">
        <Table>
          <TableHead>
            <th>
              <SwitchView
                checked={props.displayInActiveLoans}
                name="swDisplayActive2"
                onChange={props.toggleDisplayInActiveLoans}
                label="Show Inactive"
              />
            </th>
          </TableHead>
          <tbody className=" divide-y divide-gray-800 divide-border">
            {data.map(l => {
              return (
                <TableRow key={l.id}>
                  <td className="m-1 text-left text-sm">
                    <div className=" items-center m-4 grid grid-cols-4 ">
                      <img src={l.imageURL} alt={`${l.assetId} icon`} />
                      <span className="text-xs font-bold text-purple-light">
                        OUTSTANDING
                      </span>

                      <span className="block center-align">
                        {displayLoanDebt(l)}
                      </span>
                    </div>

                    <div className=" m-4 grid grid-cols-4 items-baseline">
                      <span className="font-medium">{l.assetId}</span>
                      <span className="text-xs font-bold text-purple-light">
                        COLLATERAL
                      </span>
                      <span className="center-align">
                        {numberToFormattedAUD(
                          getAssetValueInAud(l.collateral, l.assetPriceAUD)
                        )}
                      </span>
                      <span className="block text-right center-align">
                        {Number(l.collateral).toFixed(6)}
                      </span>
                    </div>
                    <div className=" m-4 grid grid-cols-4 items-baseline">
                      <span className="text-xs font-bold text-purple-light"></span>
                      <span className="text-xs font-bold text-purple-light">
                        LIQUIDATION PRICE
                      </span>

                      <>
                        <span className="block text-green-500 center-align">
                          {numberToFormattedAUD(
                            getAssetValueInAud("1", l.assetPriceAUD!)
                          )}
                        </span>
                        <span className="text-right text-red-800 center-align">
                          {numberToFormattedAUD(l.outstandingDebt)}
                        </span>
                      </>
                    </div>

                    <div className=" m-4 grid grid-cols-4 items-baseline">
                      <span></span>
                      <span className="text-xs font-bold text-purple-light">
                        LOAN STATUS
                      </span>

                      <span className="text-right col-span-2">
                        {<LoanStatus loan={l} />}
                      </span>
                    </div>
                    <div className="flex justify-between items-center m-4">
                      {l.status === "active" && (
                        <ButtonIcon
                          title="Repay"
                          onClick={() => props.openLoanDetailsModal(l.id)}
                          className="w-3/4 m-2 border sm:font-normal sm:h-12 flex align-center repay-attention"
                          disabled={l.status !== "active"}
                        />
                      )}

                      {l.status === "pending-legal" && (
                        <ButtonIcon
                          title="Pending Signature"
                          disabled
                          icon={faFileSignature}
                          className="w-3/4 m-2 border sm:font-normal sm:h-12 pl-4 flex align-center"
                        />
                      )}
                      {l.status === "loan-completed" && (
                        <ButtonIcon
                          title="Withdraw"
                          customIcon={<WithdrawIcon />}
                          onClick={() => props.openWithdrawModal(l)}
                          className="w-3/4 m-2 border sm:font-normal sm:h-12 pl-4 flex align-center"
                          disabled={l.status !== "loan-completed"}
                        />
                      )}
                      {(l.status === "pending-collateral" ||
                        l.status === "active") && (
                        <ButtonIcon
                          title={
                            l.status === "pending-collateral"
                              ? "Deposit"
                              : "Top Up"
                          }
                          onClick={() => props.openDepositCollateralModal(l)}
                          className="w-3/4 border sm:font-normal sm:h-12 pl-4 flex align-center"
                          customIcon={<TopUpIcon />}
                        />
                      )}
                    </div>
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const Table: React.FC<{
  className?: string;
}> = props => {
  return (
    <div className="col-span-6">
      <table className={`w-full text-right table-fixed ${props.className}`}>
        {props.children}
      </table>
    </div>
  );
};

const TableHead: React.FC = props => {
  return (
    <thead className="text-white text-l">
      <tr>{props.children}</tr>
    </thead>
  );
};

const TableRow: React.FC<{
  className?: string;
  loan?: server.Loan;
  showDetails?: (loan: server.Loan) => void;
}> = props => {
  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    if (props.loan && props.showDetails) props.showDetails(props.loan);
  };

  return (
    <tr
      className={`group hover:bg-purple-electric hover:text-white hover:bg-opacity-20  ${
        props.className || ""
      }`}
      onClick={handleClick}
    >
      {props.children}
    </tr>
  );
};

type CryptoBalancesTableProps = {
  balances: server.Balances;
  openDepositModal(assetId: string | undefined): void;
  // Todo - asset withdraw functionality
  // openWithdrawModal(asset: server.Balance): void;
  displayEmptyBalances: boolean;
  displayInterestEarning: boolean;
  toggleDisplayEmptyBalances(): void;
};

const CryptoBalancesTable: React.FC<CryptoBalancesTableProps> = props => {
  const { assets } = props.balances;
  const sortByBalanceThenName = (a: server.Balance, b: server.Balance) => {
    if (a.balanceAUD < b.balanceAUD) return 1;
    else if (a.balanceAUD < b.balanceAUD) return -1;
    else return a.assetId > b.assetId ? 1 : -1;
  };
  const displayAssets = props.displayEmptyBalances
    ? assets.sort(sortByBalanceThenName)
    : assets.filter(a => a.balanceAUD > 0).sort(sortByBalanceThenName);

  return (
    <>
      <div className="hidden sm:block col-span-6 mt-6 pt-6">
        <Table>
          <TableHead>
            <th className="w-1/3 text-left pl-4 ">
              <SwitchView
                checked={props.displayEmptyBalances}
                name="swDisplayEmptyBalances"
                onChange={props.toggleDisplayEmptyBalances}
                label="Show assets with no balance"
              />
            </th>
            <th className="text-left pl-2 pr-2 py-3 text-xs font-medium font-sans text-purple-light">
              BALANCE
            </th>
            <th className="pr-3 pl-2 pr-2 text-left text-xs font-sans font-medium text-purple-light">
              CREDIT LINE
            </th>
            <th className="pl-2 pr-2"></th>
          </TableHead>
          <tbody className="divide-y divide-gray-800 divide-border m-4">
            {displayAssets.map(b => {
              return (
                <TableRow key={b.assetId}>
                  <td className="pl-4 max-h-3 py-7">
                    <div className="flex items-center ">
                      <img src={b.imageURL} alt={`${b.assetId} icon`} />
                      <span className="pr-4 ml-5 font-semibold text-base">
                        {b.assetId}
                      </span>
                    </div>
                  </td>
                  <td className="text-left pt-7 pr-2">
                    <span className="block font-semibold font-sans text-base">
                      {new BN(b.balance).toFixed(6)} {b.assetId}
                    </span>
                    <span className="text-xs leading-6 font-medium text-purple-light">
                      {numberToFormattedAUD(b.balanceAUD)}
                    </span>
                  </td>
                  <td className="text-left pl-2 pr-2 font-semibold text-base">
                    {numberToFormattedAUD(b.availableCreditLine)}
                  </td>
                  <td className="pl-2 pr-2">
                    <div className="flex gap-3 pr-4 justify-end">
                      {/* Todo - asset withdraw functionality
                       <ButtonIcon
                        title="Withdraw"
                        customIcon={<WithdrawIcon />}
                        className="border sm:font-normal sm:h-12 pl-4 flex align-center"
                        onClick={() => props.openWithdrawModal(b)}
                        disabled={b.balanceAUD === 0}
                      /> */}
                      {/* <ButtonIcon
                        title="Top Up"
                        customIcon={<TopUpIcon />}
                        className="border sm:font-normal sm:h-12 pl-4 flex align-center"
                        onClick={() => props.openDepositModal(b.assetId)}
                        disabled={true}
                      /> */}
                    </div>
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="col-span-6 block sm:hidden padding-t-20">
        <Table>
          <TableHead>
            <th className="flex justify-start p-2">
              <SwitchView
                checked={props.displayEmptyBalances}
                name="swDisplayEmptyBalances2"
                onChange={props.toggleDisplayEmptyBalances}
                label="Show assets with no balance"
              />
            </th>
          </TableHead>
          <tbody>
            {displayAssets.map(b => {
              return (
                <TableRow key={b.assetId}>
                  <td className="m-1 bg-background-light border-2 border-solid border-purple-dark rounded-2xl text-sm">
                    <div className=" items-center m-4 grid grid-cols-4">
                      <span className="text-left">
                        <img src={b.imageURL} alt={`${b.assetId} icon`} />
                      </span>
                      <span className="text-left">Balance</span>
                      <span className="text-right">
                        {numberToFormattedAUD(b.balanceAUD)}
                      </span>
                      <span className="">{new BN(b.balance).toFixed(6)}</span>
                    </div>
                    <div className=" m-4 grid grid-cols-4">
                      <span className="text-left">{b.assetId}</span>
                      <span className="text-left å">Credit Line</span>
                      <span className="text-right">
                        {numberToFormattedAUD(b.availableCreditLine)}
                      </span>
                    </div>
                    <div className="flex justify-between items-center m-4">
                      {/* Todo - asset withdraw functionality
                       <Button
                        title="Withdraw"
                        onClick={() => props.openWithdrawModal({})}
                        disabled={b.balanceAUD === 0}
                        className="w-3/4 m-2"
                      /> */}
                      {/* <Button
                        title="Top Up"
                        onClick={() => props.openDepositModal(b.assetId)}
                        className="w-3/4 m-2"
                        disabled={true}
                      /> */}
                    </div>
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};
