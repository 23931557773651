import { PageLayout } from "../../components";

type Props = {};

const AccountView: React.FC<Props> = () => {
  return (
    <PageLayout showHeader={true}>
      <span>Transactions</span>
    </PageLayout>
  );
};

export default AccountView;
