import classNames from "classnames";

type IError = {
  classname?: string;
};
const Error: React.FC<IError> = ({ children, classname }) => {
  return (
    <div
      className={classNames(classname, "mt-5 p-2 error-message text-center")}
    >
      <span>{children}</span>
    </div>
  );
};

export default Error;
