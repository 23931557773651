import { useEffect, useState } from "react";
import LoanDetailsModalUserView from "./LoanDetailsModal_User_View";
import useApi from "../../hooks/useApi";
import useGlobalError from "../../hooks/useGlobalError";
import useSWR from "swr";
import useAuth from "../../hooks/useAuth";

type Props = {
  loan: server.Loan | undefined;
  onClose: () => void;
  refetchLoans(): void;
  onLoanUpdate(loan: server.Loan): void;
};

const LoanDetailsModalUserLogic: React.FC<Props> = props => {
  const [documents, setDocuments] = useState<server.GetDocumentsResponse>();
  const [financialData, setFinancialData] = useState<
    | server.ConsumerResponsibleLendingData
    | server.BusinessResponsibleLendingData
  >();
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [loadingFinancialData, setLoadingFinancialData] = useState(false);
  const [contractUrl, setContractUrl] = useState<string>();
  const [cancellingLoan, setCancellingLoan] = useState<boolean>(false);
  const [alertShow, setAlertShow] = useState<boolean>(false);
  const api = useApi();
  const { user } = useAuth();
  const { setGlobalError } = useGlobalError();

  const { data: assetPrices } = useSWR("asset-prices", () =>
    api.getAssetPrices()
  );

  useEffect(() => {
    setDocuments(undefined);
    setLoadingDocuments(false);
    setFinancialData(undefined);
    setLoadingFinancialData(false);
    setContractUrl(undefined);
  }, [props.loan?.id]);

  const cancelLoan = async () => {
    if (!props.loan) {
      return;
    }
    setAlertShow(false);
    setCancellingLoan(true);

    try {
      const updatedLoan = await api.cancelLoan(props.loan.id);
      props.refetchLoans();
      props.onLoanUpdate(updatedLoan);
    } catch (error) {
      setGlobalError(error as server.ErrorResponse);
    }

    setCancellingLoan(false);
  };

  const fetchDocuments = async () => {
    if (!props.loan) {
      return;
    }

    setLoadingDocuments(true);

    try {
      const documentResults = await api.getLoanDocuments(props.loan.id);

      setDocuments(documentResults);
    } catch (error) {
      setGlobalError(error as server.ErrorResponse);
    }

    setLoadingDocuments(false);
  };

  const fetchFinancialData = async () => {
    if (!props.loan) {
      return;
    }

    setLoadingFinancialData(true);

    try {
      const data = await api.getLoanFinancialData(props.loan.id);

      setFinancialData(data);
    } catch (error) {
      setGlobalError(error as server.ErrorResponse);
    }

    setLoadingFinancialData(false);
  };

  const fetchLoanContract = async () => {
    if (!props.loan) {
      return;
    }

    const contractUrl = await api.getLoanContract(props.loan.id);

    setContractUrl(contractUrl);
    window.open(contractUrl, "_blank");
  };

  return (
    <LoanDetailsModalUserView
      user={user}
      price={assetPrices?.find(a => a.assetId === props.loan?.assetId)}
      loan={props.loan}
      onClose={props.onClose}
      loadingDocuments={loadingDocuments}
      documents={documents}
      loadingFinancialData={loadingFinancialData}
      financialData={financialData}
      contractUrl={contractUrl}
      fetchDocuments={fetchDocuments}
      fetchFinancialData={fetchFinancialData}
      fetchLoanContract={fetchLoanContract}
      cancelLoan={cancelLoan}
      cancellingLoan={cancellingLoan}
      alertShow={alertShow}
      onAlertClose={() => setAlertShow(false)}
      onAlertShow={() => setAlertShow(true)}
    />
  );
};

export default LoanDetailsModalUserLogic;
