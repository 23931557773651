// import { AddressQRCode } from "..";

// import { AddressQRCode } from "..";
// import Loader from "../Loader/Loader_View";

// type Props = {
//   address: string | undefined;
// };

// const DepositAddress: React.FC<Props> = props => {
/* {!props.address ? (
          <div className="p-6 text-center">
            <Loader />
            <span className="block mt-1 text-sm">Creating secure address</span>
             ) : ( 
           <AddressQRCode address={props.address} /> 
         )} 
            */
//}


import React, { useEffect, useState } from "react";
import Input from "../Input/Input_View";
import Button from "../Button/Button_View";
import CopyIcon from "../CopyIcon/CopyIconView";
type Props = {
  setDepositDataStatus: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  loan: server.Loan | undefined;
  handleOnClickToCopyAddress:(value:string)=> void;
  addressCopied:boolean;
  handleSubmit: () => void;
  setValue:React.Dispatch<React.SetStateAction<string>>;
  value:string;
  error:boolean;
  getSelectedAddressBasedOnAsset:(value:server.AssetId)=> string
}
  const DepositAddress:React.FC<Props> = (props) => { 
    const {error,
       getSelectedAddressBasedOnAsset,
      handleOnClickToCopyAddress,
      handleSubmit, 
      loan, 
      setValue,
      addressCopied,
      value} = props;
    const [address, setAddress] = useState<string>("");
    useEffect(()=>{
      if (!loan) {
        return;
      }
      const getAddress = getSelectedAddressBasedOnAsset(loan.assetId);
      setAddress(getAddress);
    },[loan, getSelectedAddressBasedOnAsset]);
  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-col relative mb-4">
          <div className="absolute top-8 right-0 mt-2 mr-4 cursor-pointer" onClick={()=>handleOnClickToCopyAddress(address)}>
            <CopyIcon/>
          </div>
        <Input value={address} className="cursor-pointer" readOnly onClick={()=>handleOnClickToCopyAddress(address)} />
        {addressCopied && <div className="rounded-md px-4 py-2 w-full text-right text-other-green">Copied</div>}
        </div>
        <div className="flex flex-col">
          <span>Enter Transaction Hash - </span>
        <Input type="text" value={value} onChange={(e)=> setValue(e.target.value)} />
        {error && <span className="text-red-500 pl-2 text-sm">Invalid Txn. Hash</span>}
        </div>
       <div className="w-full flex justify-center items-center mt-4">
        <Button onClick={handleSubmit} disabled={value===""? true : false}>Submit</Button>
       </div>
      </div>
    </div>
  );
};
export default DepositAddress;
