import { validate } from "multicoin-address-validator";

export const roundUpTwoDecimals = (number: number) =>
  Math.round(number * 100) / 100;

export enum IDENTITY_ERRORS {
  VerificationCountExceeded = "Verification Count Exceeded, please contact support. ",
  UnknownError = "We were not able to verify your identity. Please check your information and try again."
}

export const validateCryptoAddress = (
  currencyNameOrSymbol: server.AssetId,
  address?: string
) => {
  if (!address) return false;
  // networkType: 'both' is used to use testnet and prod addresses.
  return validate(address, currencyNameOrSymbol, "both");
};

export const formatNumbers = (phoneNumber?: string) => {
  if (!phoneNumber) return "";
  const areaCodeAU = "+614";
  const phoneNumberRegex = new RegExp("^04|614|6104|(\\+614)");
  const updatedPhoneNumber = phoneNumber.replace(phoneNumberRegex, areaCodeAU);
  return updatedPhoneNumber;
};
