import isMobilePhone from "validator/lib/isMobilePhone";

const validate = ({ phoneNumber }: server.AddPhoneNumberRequestBody) => {
  const errors: {
    phoneNumber?: string;
  } = {};
  if (!phoneNumber) errors.phoneNumber = "Please enter your phone number.";

  if (!isMobilePhone(phoneNumber, "en-AU"))
    errors.phoneNumber = "Please enter a valid mobile number.";
  return errors;
};

export default validate;
