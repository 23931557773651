import validator from "validator";

export type ResetValidatorPayload = {
  password: string;
  confirmPassword: string;
};

const resetPasswordValidator = ({
  password,
  confirmPassword
}: ResetValidatorPayload): ValidatorResult<ResetValidatorPayload> => {
  const errors: ValidatorResult<ResetValidatorPayload> = {};
  if (!password) errors.password = "Please enter a password";
  if (!confirmPassword) errors.confirmPassword = "Please confirm password";
  if (password !== confirmPassword)
    errors.confirmPassword = "Passwords do not match";
  if (!validator.isStrongPassword(password))
    errors.password =
      "Your password should have at least 1 uppercase, 1 lowercase, 1 number, 1 symbol and a minimum of 8 characters";

  return errors;
};

export default resetPasswordValidator;
