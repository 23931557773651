import { createRef, useState, useEffect } from "react";
import useSWR from "swr";
import useApi from "../../hooks/useApi";
import useGlobalError from "../../hooks/useGlobalError";
import useLoanDetailsQueryString from "../../hooks/useLoanDetailsQueryString";
import useSystemState from "../../hooks/useSystemState";
import CreateLoanModalView, { Step } from "./CreateLoanModal_View";
import {
  getCollateralRequired,
  getMaxDebtAllowed,
  getMinCollateralToDebtRatioForAsset
} from "../../helpers/loan-helpers";
import { collateralFormatter } from "../../helpers/formatters";
import { BusinessLoanPayload } from "../../helpers/validators/business-loan-validator";
import { LOAN_PURPOSES } from "./CreateLoanModal_View";

const TERMS_AND_CONDITIONS_VERSION = "0.1";

type Props = {
  show: boolean;
  onClose(): void;
  currentLoanId: number | null;
};

const RequestLoanModal: React.FC<Props> = props => {
  const api = useApi();
  const systemState = useSystemState();
  const loanDetailsQueryString = useLoanDetailsQueryString();

  const { setGlobalError } = useGlobalError();

  const fileInput = createRef<HTMLInputElement>();

  const { data: balances } = useSWR("balance", () => api.getBalance());

  const { revalidate: revalidateLoans } = useSWR("loans", () =>
    api.searchLoans({})
  );
  const [step, setStep] = useState<Step>("loan");

  const [selectedAssetId, setSelectedAssetId] = useState<server.AssetId>("BTC");
  const [debt, setDebt] = useState(
    loanDetailsQueryString.loanAmount?.toString() ||
      systemState?.minimumLoanAmount.toString() ||
      "1000"
  );
  const [tcVersionAgreedTo, setTcVersionAgreedTo] = useState<string>();
  const [sourceOfCollateral, setSourceOfCollateral] = useState<string>("");
  const [requestingLoan, setRequestingLoan] = useState(false);
  const [loanPurpose, setLoanPurpose] = useState<string>("Car");
  const [loanPurposeDisplay, setLoanPurposeDisplay] = useState<string>("Car");
  const [loanDuration, setLoanDuration] = useState<number>(12);
  const [files, setFile] = useState<{ name: string; id: string }[]>([]);
  const [pretaxIncome, setIncome] = useState(0);
  const [
    employmentStatus,
    setEmploymentStatus
  ] = useState<server.EmploymentStatus>("casual");
  const [numberOfChildren, setNumberOfChildren] = useState(0);
  const [inFinancialHardship, setInFinancialHardship] = useState(false);
  const [monthlyRepayments, setMonthlyRepayments] = useState(0);
  const [monthlyCreditCardExpenses, setMonthlyCreditCardExpenses] = useState(0);
  const [otherLoanExpenses, setOtherLoanExpenses] = useState(0);
  const [filesErrorMessage, setFilesErrorMessage] = useState<string>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const [loanType, setLoanType] = useState<server.LoanNature>("personal");
  const [isForeignBusiness, setIsForeignBusiness] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>("");
  const [ABN, setABN] = useState<string>("");
  const [businessType, setBusinessType] = useState<string>("");
  const [businessAddress, setBusinessAddress] = useState<string>("");
  const [propertyType, setPropertyType] = useState<string>("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState<string>("");
  const [monthlyExpenses, setMonthlyExpenses] = useState<string>("");
  const [secondName, setSecondName] = useState<string>("");
  const [secondEmail, setSecondEmail] = useState<string>("");
  const [loanId, setLoanId] = useState<number>();

  const resetCollateral = (debt: string, assetId: server.AssetId) => {
    if (balances && systemState) {
      const selectedAsset = balances.assets.find(b => b.assetId === assetId)!;

      const minimumCollateralToDebtRatio = getMinCollateralToDebtRatioForAsset(
        selectedAsset,
        systemState
      );

      const requiredCollateral = getCollateralRequired(
        parseFloat(debt),
        minimumCollateralToDebtRatio,
        selectedAsset.assetPriceAUD
      ).toString();
      return collateralFormatter(requiredCollateral, 6);
    }
    return null;
  };

  useEffect(() => {
    if (props.currentLoanId) {
      api
        .getLoan(props.currentLoanId)
        .then(res => {
          res.assetId && setSelectedAssetId(res.assetId);
          res.openingDebt && setDebt(res.openingDebt.toString());
          res.natureOfLoan && setLoanType(res.natureOfLoan);
          const currentCollateralAmount = resetCollateral(
            res.openingDebt.toString(),
            res.assetId
          );
          res.openingDebt &&
            res.assetId &&
            setCollateralAmount(currentCollateralAmount);
        })
        .catch(err => {
          console.log({ err });
        });
      api
        .getLoanFinancialData(props.currentLoanId)
        .then(res => {
          if (loanType === "personal") {
            const personalLoan = res as server.ConsumerResponsibleLendingData;
            personalLoan.annualPreTaxIncome &&
              setIncome(personalLoan.annualPreTaxIncome);
            personalLoan.employmentStatus &&
              setEmploymentStatus(personalLoan.employmentStatus);
            personalLoan.numberOfDependants &&
              setNumberOfChildren(personalLoan.numberOfDependants);
            personalLoan.currentlyInFinancialHardshipOrInDefault &&
              setInFinancialHardship(
                personalLoan.currentlyInFinancialHardshipOrInDefault
              );
            personalLoan.monthlyAccommodationExpenses &&
              setMonthlyRepayments(personalLoan.monthlyAccommodationExpenses);
            personalLoan.monthlyCreditCardExpenses &&
              setMonthlyCreditCardExpenses(
                personalLoan.monthlyCreditCardExpenses
              );
            personalLoan.monthlyLoanExpenses &&
              setOtherLoanExpenses(personalLoan.monthlyLoanExpenses);
            personalLoan.bankStatementFileData &&
              setFile(personalLoan.bankStatementFileData);
          } else {
            const financialLoan = res as server.BusinessResponsibleLendingData;
            financialLoan.isForeignCompany &&
              setIsForeignBusiness(financialLoan.isForeignCompany);
            financialLoan.businessAddress &&
              setBusinessAddress(financialLoan.businessAddress);
            financialLoan.businessName &&
              setBusinessName(financialLoan.businessName);
            financialLoan.businessPhoneNumber &&
              setBusinessPhoneNumber(financialLoan.businessPhoneNumber);
            financialLoan.abn && setABN(financialLoan.abn);
            financialLoan.businessType &&
              setBusinessType(financialLoan.businessType);
            financialLoan.typeOfProperty &&
              setPropertyType(financialLoan.typeOfProperty);
            financialLoan.filesData && setFile(financialLoan.filesData);
            financialLoan.averageMonthlyAndOutgoingExpenses &&
              setMonthlyExpenses(
                financialLoan.averageMonthlyAndOutgoingExpenses.toString()
              );

            financialLoan.secondDirector &&
              setSecondEmail(
                financialLoan.secondDirector
                  ? financialLoan.secondDirector.email
                  : ""
              );
            financialLoan.secondDirector &&
              setSecondName(
                financialLoan.secondDirector
                  ? financialLoan.secondDirector.name
                  : ""
              );

            financialLoan.witness &&
              setSecondEmail(
                financialLoan.witness ? financialLoan.witness.email : ""
              );
            financialLoan.witness &&
              setSecondName(
                financialLoan.witness ? financialLoan.witness.name : ""
              );
            financialLoan.filesData && setFile(financialLoan.filesData);
          }
          setWalletAddress(res.walletAddress);
          setLoanDuration(res.durationInMonths);
          if (LOAN_PURPOSES.includes(res.reasonForLoan)) {
            setLoanPurpose(res.reasonForLoan);
            setLoanPurposeDisplay(res.reasonForLoan);
          } else {
            setLoanPurposeDisplay("Other...");
            setLoanPurpose(res.reasonForLoan);
          }
        })
        .catch(err => {
          console.log({ err });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, props.currentLoanId]);

  useEffect(() => {
    if (props.currentLoanId) {
      api
        .getLoanFinancialData(props.currentLoanId)
        .then(res => {
          if (loanType === "personal") {
            const personalLoan = res as server.ConsumerResponsibleLendingData;
            personalLoan.annualPreTaxIncome &&
              setIncome(personalLoan.annualPreTaxIncome);
            personalLoan.employmentStatus &&
              setEmploymentStatus(personalLoan.employmentStatus);
            personalLoan.numberOfDependants &&
              setNumberOfChildren(personalLoan.numberOfDependants);
            personalLoan.currentlyInFinancialHardshipOrInDefault &&
              setInFinancialHardship(
                personalLoan.currentlyInFinancialHardshipOrInDefault
              );
            personalLoan.monthlyAccommodationExpenses &&
              setMonthlyRepayments(personalLoan.monthlyAccommodationExpenses);
            personalLoan.monthlyCreditCardExpenses &&
              setMonthlyCreditCardExpenses(
                personalLoan.monthlyCreditCardExpenses
              );
            personalLoan.monthlyLoanExpenses &&
              setOtherLoanExpenses(personalLoan.monthlyLoanExpenses);
          } else {
            const financialLoan = res as server.BusinessResponsibleLendingData;
            financialLoan.isForeignCompany &&
              setIsForeignBusiness(financialLoan.isForeignCompany);
            financialLoan.businessAddress &&
              setBusinessAddress(financialLoan.businessAddress);
            financialLoan.businessName &&
              setBusinessName(financialLoan.businessName);
            financialLoan.businessPhoneNumber &&
              setBusinessPhoneNumber(financialLoan.businessPhoneNumber);
            financialLoan.abn && setABN(financialLoan.abn);
            financialLoan.businessType &&
              setBusinessType(financialLoan.businessType);
            financialLoan.typeOfProperty &&
              setPropertyType(financialLoan.typeOfProperty);
            financialLoan.filesData && setFile(financialLoan.filesData);
            financialLoan.averageMonthlyAndOutgoingExpenses &&
              setMonthlyExpenses(
                financialLoan.averageMonthlyAndOutgoingExpenses.toString()
              );
            financialLoan.secondDirector &&
              setSecondEmail(
                financialLoan.secondDirector
                  ? financialLoan.secondDirector.email
                  : ""
              );
            financialLoan.secondDirector &&
              setSecondName(
                financialLoan.secondDirector
                  ? financialLoan.secondDirector.name
                  : ""
              );
            financialLoan.witness &&
              setSecondEmail(
                financialLoan.witness ? financialLoan.witness.email : ""
              );
            financialLoan.witness &&
              setSecondName(
                financialLoan.witness ? financialLoan.witness.name : ""
              );
          }
          setWalletAddress(res.walletAddress);
          setLoanDuration(res.durationInMonths);
          if (LOAN_PURPOSES.includes(res.reasonForLoan)) {
            setLoanPurpose(res.reasonForLoan);
            setLoanPurposeDisplay(res.reasonForLoan);
          } else {
            setLoanPurposeDisplay("Other...");
            setLoanPurpose(res.reasonForLoan);
          }
        })
        .catch(err => {
          console.log({ err });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, loanType, props.currentLoanId]);

  const [collateralAmount, setCollateralAmount] = useState(
    loanDetailsQueryString.collateral
      ? loanDetailsQueryString.collateral.toString()
      : resetCollateral(debt, selectedAssetId)
  );

  useEffect(() => {
    setCollateralAmount(resetCollateral(debt, selectedAssetId));
    // eslint-disable-next-line
  }, [selectedAssetId, balances, systemState]);

  if (!balances || !selectedAssetId || !systemState) {
    return null;
  }

  const onReturnToPreviousStep = (step: any) => {
    setStep(step);
  };

  const createLoan = (data: server.RequestLoanRequestBody) => {
    api
      .createEmptyLoan(data)
      .then(res => {
        setLoanId(res.id);
      })
      .catch(err => {
        console.log({ err });
      });
  };

  const updateLoanUsingLoanId = (
    id: number,
    data: server.RequestLoanRequestBody
  ) => {
    api.updateLoan({ loanId: id }, { ...data }).catch(err => {
      console.log({ err });
    });
  };

  const getDataToUpdateLoan = () => {
    const data = getLoanData();
    if (data) {
      if (props.currentLoanId) {
        updateLoanUsingLoanId(props.currentLoanId, data);
      } else if (loanId) {
        updateLoanUsingLoanId(loanId, data);
      }
    }
  };

  const onCompleteLoanDetails = () => {
    const data = getLoanData();
    if (data) {
      if (!props.currentLoanId && !loanId) {
        createLoan(data);
      } else if (props.currentLoanId) {
        updateLoanUsingLoanId(props.currentLoanId, data);
      } else if (loanId) {
        updateLoanUsingLoanId(loanId, data);
      }
    }
    setStep("loan_type");
  };

  const onCompleteBorrowerDetails = () => {
    getDataToUpdateLoan();
    setStep("upload");
  };

  const onCompleteLoanTypeDetails = (data: BusinessLoanPayload) => {
    setBusinessName(data.businessName);
    setBusinessType(data.businessType);
    setBusinessAddress(data.businessAddress);
    setBusinessPhoneNumber(data.businessPhoneNumber);
    setABN(data.ABN);
    setPropertyType(data.propertyType);
    setMonthlyExpenses(data.monthlyExpenses);
    setIsForeignBusiness(data.isForeignLoan);
    setSecondEmail(data.email);
    setSecondName(data.name);
    const loanData = getLoanData();
    if (loanData) {
      const dataToSubmit: server.RequestLoanRequestBody = {
        ...loanData,
        responsibleLendingData: {
          ...loanData.responsibleLendingData,
          businessName: data.businessName,
          businessType: data.businessType,
          businessAddress: data.businessAddress,
          businessPhoneNumber: data.businessPhoneNumber,
          abn: data.ABN,
          typeOfProperty: data.propertyType,
          averageMonthlyAndOutgoingExpenses: Number(data.monthlyExpenses),
          isForeignCompany: data.isForeignLoan,
          secondDirector:
            !data.isForeignLoan && data.name && data.email
              ? { name: data.name, email: data.email }
              : null,
          witness: data.isForeignLoan
            ? { name: data.name, email: data.email }
            : null
        }
      };
      if (props.currentLoanId) {
        updateLoanUsingLoanId(props.currentLoanId, dataToSubmit);
      } else if (loanId) {
        updateLoanUsingLoanId(loanId, dataToSubmit);
      }
    }
    setStep("borrower_details");
  };

  const onCompleteFileUploads = () => {
    getDataToUpdateLoan();
    setStep("employment_status");
  };

  const onCompleteEmploymentStatus = () => {
    getDataToUpdateLoan();
    setStep("financial_situation");
  };

  const toggleAcceptTC = () => {
    setTcVersionAgreedTo(
      tcVersionAgreedTo ? undefined : TERMS_AND_CONDITIONS_VERSION
    );
  };
  const getLoanData = () => {
    if (!walletAddress) {
      return;
    }
    let responsibleLendingData:
      | server.ConsumerResponsibleLendingData
      | server.BusinessResponsibleLendingData;
    if (loanType === "personal") {
      responsibleLendingData = {
        reasonForLoan: loanPurpose,
        durationInMonths: loanDuration,
        annualPreTaxIncome: pretaxIncome,
        employmentStatus: employmentStatus,
        numberOfDependants: numberOfChildren,
        currentlyInFinancialHardshipOrInDefault: inFinancialHardship,
        monthlyAccommodationExpenses: monthlyRepayments,
        monthlyCreditCardExpenses: monthlyCreditCardExpenses,
        monthlyLoanExpenses: otherLoanExpenses,
        bankStatementFileData: files,
        walletAddress: walletAddress
      };
    } else {
      responsibleLendingData = {
        reasonForLoan: loanPurpose,
        businessName,
        abn: ABN,
        businessType,
        businessAddress,
        typeOfProperty: propertyType,
        businessPhoneNumber,
        averageMonthlyAndOutgoingExpenses: Number(monthlyExpenses),
        filesData: files,
        walletAddress,
        durationInMonths: loanDuration,
        isForeignCompany: isForeignBusiness,
        secondDirector:
          !isForeignBusiness && secondName && secondEmail
            ? { name: secondName, email: secondEmail }
            : null,
        witness: isForeignBusiness
          ? { name: secondName, email: secondEmail }
          : null
      };
    }

    return {
      debt: parseFloat(debt),
      submit: false,
      collateralAmount: collateralAmount ? collateralAmount : "",
      collateral: selectedAssetId,
      interestRate: systemState.interestRate,
      natureOfLoan: loanType,
      responsibleLendingData
    };
  };
  const onCreateLoan = async () => {
    setRequestingLoan(true);
    const data = getLoanData();
    try {
      if (data) {
        if (props.currentLoanId) {
          await api.updateLoan(
            { loanId: props.currentLoanId },
            { ...data, submit: true }
          );
        } else if (loanId) {
          await api.updateLoan({ loanId: loanId }, { ...data, submit: true });
        }
        await revalidateLoans();
        setStep("completed");
      }
    } catch (err: any) {
      setGlobalError(err);
    }
    setRequestingLoan(false);
  };

  const onChangeLoanPurpose = (purpose: string) => {
    setLoanPurpose(purpose);
  };

  const onChangeSelectedAsset = (newAssetId: server.AssetId) => {
    setSelectedAssetId(newAssetId);
  };

  const onChangeDebt = (newValue: string) => {
    setCollateralAmount(resetCollateral(newValue, selectedAssetId));
    setDebt(newValue);
  };

  const onChangeCollateralAmount = (newValue: string) => {
    const selectedAsset = balances.assets.find(
      b => b.assetId === selectedAssetId
    )!;

    const minimumCollateralToDebtRatio = getMinCollateralToDebtRatioForAsset(
      selectedAsset,
      systemState
    );
    const maximumDebtAllowed = getMaxDebtAllowed(
      parseFloat(newValue),
      minimumCollateralToDebtRatio,
      selectedAsset.assetPriceAUD
    );
    setDebt(maximumDebtAllowed.toString());

    setCollateralAmount(newValue);
  };

  const onFileUpload = async (event: any) => {
    setFilesErrorMessage(undefined);

    const file = event.target.files[0];
    if (file.type !== "application/pdf") {
      setFilesErrorMessage("File must be a PDF.");
      return;
    }

    const existingFile = files.find(f => f.name === file.name);
    if (!!existingFile) {
      setFilesErrorMessage("File has already been uploaded.");
      return;
    }

    event.target.value = "";
    if (!file) return;
    const fd = new FormData();
    fd.append("attachmentpdf", file);
    const fileId = await api.uploadFileToGoogleCloud(fd);
    setFile([...files, { name: file.name, id: fileId }]);
  };

  const onClose = async () => {
    await revalidateLoans();
    setSelectedAssetId("BTC");
    setDebt(systemState ? systemState.minimumLoanAmount.toString() : "1000");
    setCollateralAmount(
      resetCollateral(
        systemState ? systemState.minimumLoanAmount.toString() : "1000",
        "BTC"
      )
    );
    setLoanPurpose("Car");
    setLoanPurposeDisplay("Car");
    setLoanDuration(12);
    setFile([]);
    setIncome(0);
    setEmploymentStatus("casual");
    setNumberOfChildren(0);
    setInFinancialHardship(false);
    setMonthlyRepayments(0);
    setMonthlyCreditCardExpenses(0);
    setOtherLoanExpenses(0);
    setWalletAddress(undefined);
    setTcVersionAgreedTo(undefined);
    setSourceOfCollateral("");
    setFilesErrorMessage(undefined);
    setLoanType("personal");
    setIsForeignBusiness(false);
    setBusinessName("");
    setABN("");
    setBusinessType("");
    setBusinessAddress("");
    setPropertyType("");
    setBusinessPhoneNumber("");
    setMonthlyExpenses("");
    setSecondName("");
    setSecondEmail("");
    props.onClose();
    setStep("loan");
  };

  return (
    <CreateLoanModalView
      show={props.show}
      onClose={onClose}
      loanStep={step}
      balances={balances}
      selectedAsset={balances.assets.find(b => b.assetId === selectedAssetId)!}
      onChangeSelectedAsset={onChangeSelectedAsset}
      debt={debt}
      collateralAmount={collateralAmount ? collateralAmount : ""}
      systemState={systemState}
      sourceOfCollateral={sourceOfCollateral}
      onChangeSourceOfCollateral={setSourceOfCollateral}
      onChangeCollateralAmount={onChangeCollateralAmount}
      onChangeDebt={onChangeDebt}
      creatingLoan={requestingLoan}
      onCompleteLoanDetails={onCompleteLoanDetails}
      hasAgreedToTermsAndConditions={!!tcVersionAgreedTo}
      toggleAcceptTermsAndConditions={toggleAcceptTC}
      createLoan={onCreateLoan}
      setLoanPurpose={setLoanPurpose}
      onChangeLoanPurpose={onChangeLoanPurpose}
      loanPurpose={loanPurpose}
      setLoanDuration={setLoanDuration}
      loanDuration={loanDuration}
      returnToPreviousStep={onReturnToPreviousStep}
      onCompleteBorrowerDetails={onCompleteBorrowerDetails}
      onCompleteLoanTypeDetails={onCompleteLoanTypeDetails}
      onFileUpload={onFileUpload}
      fileInput={fileInput}
      files={files}
      onCompleteFileUploads={onCompleteFileUploads}
      pretaxIncome={pretaxIncome}
      setIncome={setIncome}
      onChangeIncome={setIncome}
      setEmploymentStatus={setEmploymentStatus}
      employmentStatus={employmentStatus}
      numberOfChildren={numberOfChildren}
      onChangeNumberOfChildren={setNumberOfChildren}
      onCompleteEmploymentStatus={onCompleteEmploymentStatus}
      inFinancialHardship={inFinancialHardship}
      setInFinancialHardship={() =>
        setInFinancialHardship(!inFinancialHardship)
      }
      monthlyRepayments={monthlyRepayments}
      setMonthlyRepayments={setMonthlyRepayments}
      monthlyCreditCardExpenses={monthlyCreditCardExpenses}
      setMonthlyCreditCardExpenses={setMonthlyCreditCardExpenses}
      otherLoanExpenses={otherLoanExpenses}
      setOtherLoanExpenses={setOtherLoanExpenses}
      filesErrorMessage={filesErrorMessage}
      loanPurposeDisplay={loanPurposeDisplay}
      setLoanPurposeDisplay={setLoanPurposeDisplay}
      walletAddress={walletAddress}
      setWalletAddress={setWalletAddress}
      loanType={loanType}
      setLoanType={setLoanType}
      isForeignBusiness={isForeignBusiness}
      setIsForeignBusiness={setIsForeignBusiness}
      businessName={businessName}
      setBusinessName={setBusinessName}
      ABN={ABN}
      setABN={setABN}
      businessType={businessType}
      setBusinessType={setBusinessType}
      businessAddress={businessAddress}
      setBusinessAddress={setBusinessAddress}
      propertyType={propertyType}
      setPropertyType={setPropertyType}
      businessPhoneNumber={businessPhoneNumber}
      setBusinessPhoneNumber={setBusinessPhoneNumber}
      monthlyExpenses={monthlyExpenses}
      setMonthlyExpenses={setMonthlyExpenses}
      secondName={secondName}
      setSecondName={setSecondName}
      secondEmail={secondEmail}
      setSecondEmail={setSecondEmail}
    />
  );
};

export default RequestLoanModal;
