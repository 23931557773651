import { useState } from "react";
import NavBarView, { Link } from "./NavBar_View";
import { useHistory } from "react-router";
import useAuth from "../../hooks/useAuth";

const NavBar: React.FC = () => {
  const history = useHistory();
  const { logout } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const isAdmin = useAuth().user?.admin;

  const links: Omit<Link, "isSelected">[] = isAdmin
    ? [
        {
          title: "USERS",
          route: "/admin/users"
        },
        {
          title: "LOANS",
          route: "/admin/loans"
        }
      ]
    : [
        {
          title: "ACCOUNT",
          route: "/account"
        },
        {
          title: "PROFILE",
          route: "/profile"
        }
      ];

  return (
    <NavBarView
      links={links.map(l => ({
        ...l,
        isSelected: l.route === history.location.pathname
      }))}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      logout={logout}
    />
  );
};

export default NavBar;
