import { useRef } from "react";
import { ModalBase } from "../";
import { CrossCloseLogo, MobileCloseLogo } from "../CloseLogo/CloseLogo_View";
import useWindowSize from "../../hooks/useWindowSize";

type Props = {
  show: boolean;
  onClose: () => void;
  title: string;
  className?: string;
};
const Modal: React.FC<Props> = props => {
  const closeButtonRef = useRef(null);
  const size = useWindowSize();
  return (
    <ModalBase show={props.show} onClose={props.onClose}>
      <div
        className={`${props.className} flex justify-between py-2 px-3 border-gray-100 border-opacity-20`}
      >
        <div className="flex-1">
          <span className="title-font modal-heading text-xl font-medium text-gray-300">
            {props.title}
          </span>
        </div>
        <div>
          <button
            className="text-sm font-medium text-gray-300 hover:text-purple-electric cursor-pointer focus:outline-none"
            onClick={props.onClose}
            ref={closeButtonRef}
          >
            {size.width !== undefined && size.width > 640 ? (
              <CrossCloseLogo
                className={"absolute left-16 sm:right-5 sm:left-auto top-6"}
              />
            ) : (
              <MobileCloseLogo
                className={"absolute left-3 sm:right-5 sm:left-auto top-6"}
              />
            )}
          </button>
        </div>
      </div>
      <div className="pt-5 pb-4 px-3">{props.children}</div>
    </ModalBase>
  );
};

export default Modal;
