import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { NavButtonViewLayout } from "../NavButton/NavButton_View";
import { useHistory } from "react-router";

export type Link = {
  title: string;
  route: string;
};

type Props = {
  links: Link[];
};

const AdminDropDownMenu: React.FC<Props> = ({ links }) => {
  const history = useHistory();
  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="focus:outline-none">
          <NavButtonViewLayout isSelected={false} className="flex">
            <span className="text-white pr-2">ADMIN</span>
          </NavButtonViewLayout>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {links.map(l => {
              return (
                <Menu.Item key={l.title}>
                  <button
                    className="text-left focus:outline-none text-gray-900 w-full px-4 py-2 text-sm rounded-t hover:bg-purple-electric hover:text-white"
                    onClick={() => history.push(l.route)}
                  >
                    {l.title}
                  </button>
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default AdminDropDownMenu;
