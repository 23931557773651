import { HTMLAttributes } from "react";
import { Button } from "../";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  range?: number;
  total: number;
  onPageChange: (value: number) => void;
  current: number;
  hideLeftArrow?: boolean;
  hideRightArrow?: boolean;
}

export const usePagination = (args: Props): JSX.Element => {
  return <Paginate {...args} />;
};

export const Paginate = ({
  range = 2,
  total,
  current,
  onPageChange,
  hideLeftArrow = false,
  hideRightArrow = false
}: Props) => {
  function* paginationGen() {
    let i = 1;
    let left = Math.max(1, current - range);
    let right = Math.min(total, current + range);

    const shouldDisplay = (at: number) => {
      return at === 1 || at === total || (at >= left && at <= right);
    };

    const next = (at: number) => {
      if (at === 1 || at === total) return at + 1;
      if (at < left) return left;
      if (at > right) return total;
      return at + 1;
    };

    while (i <= total) {
      if (shouldDisplay(i)) {
        yield i;
      } else {
        yield "...";
      }
      i = next(i);
    }
  }

  return (
    <div className="flex align-items-center justify-center p-6 pb-20">
      {!hideLeftArrow && (
        <Button
          className="m-1 font-bold height-auto radius-4 min-w-2"
          title="<"
          onClick={() => onPageChange(current - 1)}
          disabled={current === 1}
        ></Button>
      )}
      {Array.from(paginationGen()).map((item, i) => {
        if (item === "...") {
          return "...";
        }
        return (
          <Button
            className="m-1 font-bold height-auto radius-4 min-w-2"
            title={item.toString()}
            key={i}
            type="button"
            disabled={item === current}
            onClick={() => onPageChange(Number(item))}
          ></Button>
        );
      })}
      {!hideRightArrow && (
        <Button
          className="m-1 font-bold height-auto radius-4 min-w-2"
          title=">"
          onClick={() => onPageChange(current + 1)}
          disabled={current >= total}
        ></Button>
      )}
    </div>
  );
};
