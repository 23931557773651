import { Modal, Loader } from "..";
import React from "react";
import { Button, AlertModal } from "../../components";
import moment from "moment";
import { getFormattedLoanStatus } from "../utils";
import {
  collateralFormatter,
  numberToFormattedAUD
} from "../../helpers/formatters";
import { displayLoanDebt, getLoansLVR } from "../../helpers/loan-helpers";
import TransferCollateralAdminView from "./TransferCollateral";
import { AddCommentsSections } from "./AddCommentsSection";
import Error from "../error/Error";
import CopyIcon from "../CopyIcon/CopyIconView";
import InputView from "../Input/Input_View";

type Props = {
  user: server.User | null;
  price: server.Asset | undefined;
  loan: server.Loan | null;
  loadingDocuments: boolean;
  documents: server.GetDocumentsResponse | null;
  financialData:
    | server.ConsumerResponsibleLendingData
    | server.BusinessResponsibleLendingData
    | null;
  loadingFinancialData: boolean;
  processing: boolean;
  contractUrl: string | null;
  reviewing: boolean;
  onClose: () => void;
  reviewLoan(status: server.ReviewLoanRequestBody["status"]): void;
  fetchDocuments(): Promise<void>;
  fetchFinancialData(): Promise<void>;
  createContract(): Promise<void>;
  fetchLoanContract(): Promise<void>;
  alertShow: boolean;
  onAlertClose: () => void;
  onAlertShow: () => void;
  error: string | null;
  reviewTransaction(status: server.ReviewLoanRequestBody["status"]): void;
  alertShowForTransaction:boolean;
  onAlertCloseForTransaction:() => void;
  onAlertShowForTransaction:() => void;
  getDepositColletral:string;
  setGetDepositColletral:React.Dispatch<React.SetStateAction<string>>;
  handleSubmitDepositColletral:()=> void;
  hashes:string[];
};

const LoanDetailsModalAdminView: React.FC<Props> = props => {
  const [showTransferModal, setShowTransferModal] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    if (props.loan) {
      setShowTransferModal(false);
    }
  }, [props.loan]);
  return (
    <Modal
      title={
        showTransferModal ? `Transfer ${props.loan?.assetId}` : "Loan Details"
      }
      show={!!props.loan}
      onClose={props.onClose}
    >
      {showTransferModal ? (
        <TransferCollateralAdminView loan={props.loan} />
      ) : (
        getContent({ showTransferModel: setShowTransferModal, ...props })
      )}
    </Modal>
  );
};

type GetContentProps = Props & {
  showTransferModel: React.Dispatch<React.SetStateAction<boolean>>;
};
const getContent = (props: GetContentProps): React.ReactNode => {
  if (!props.loan || !props.price) {
    return (
      <div className="flex h-20 justify-center items-center">
        <Loader />
      </div>
    );
  }

  const pendingLoan =
    props.loan.status === "pending-review" ||
    props.loan.status === "pending-legal" ||
    props.loan.status === "admin-signed" ||
    props.loan.status === "draft" ||
    props.loan.status === "kyc-incomplete";

    const handleOnClickToCopyAddress = (address:string) => {
      navigator.clipboard.writeText(address);
    } 

  return (
    <div className="pt-6">
      <DetailRow title="ID" value={props.loan.id} />
      <DetailRow
        title="Status"
        value={getFormattedLoanStatus(props.loan.status)}
      />
      <DetailRow title="Debt" value={displayLoanDebt(props.loan)} />
      <DetailRow
        title="Collateral"
        value={
          (pendingLoan
            ? collateralFormatter(props.loan.desiredCollateral, 6)
            : collateralFormatter(props.loan.collateral, 6)) +
          " " +
          props.loan.assetId
        }
      />

      <DetailRow
        title="LVR"
        value={getLoansLVR(props.loan, props.price).toFixed(2) + "%"}
      />
      <DetailRow
        title="Created on"
        value={moment(props.loan.createdAt).format("DD/MM/YYYY")}
      />

      <h6 className="h6 pb-3 pt-10">User</h6>
      {props.user && (
        <>
          <DetailRow title="First name" value={props.user.firstName} />
          <DetailRow title="Last name" value={props.user.lastName} />
          <DetailRow title="Email" value={props.user.email} />
          <DetailRow title="Phone number" value={props.user.phoneNumber} />
        </>
      )}

      <h6 className="h6 pb-7 pt-10">Financial Situation</h6>

      <div className="">
        {!props.financialData ? (
          <Button
            className={"w-full"}
            onClick={props.fetchFinancialData}
            loading={props.loadingFinancialData}
          >
            View financial details
          </Button>
        ) : props.loan?.natureOfLoan === "personal" ? (
          <ConsumerFinancialDetails
            data={props.financialData as server.ConsumerResponsibleLendingData}
          />
        ) : (
          <BusinessFinancialDetails
            data={props.financialData as server.BusinessResponsibleLendingData}
          />
        )}
      </div>

      <h6 className="h6 pb-7 pt-10">Documents</h6>

      <div className="">
        {!props.documents ? (
          <Button
            className={"w-full"}
            onClick={props.fetchDocuments}
            loading={props.loadingDocuments}
          >
            View documents
          </Button>
        ) : (
          <DocumentList documents={props.documents} />
        )}
      </div>

      <div className="">
        <Contract {...props} />
      </div>
      {(props.loan.status === "collateral-requested" ||
        props.loan.status === "retry-withdraw") && (
        <>
          <h6 className="h6 pb-7 pt-10">Transfer Collateral</h6>
          <Button
            className="w-full"
            title="Transfer Collateral"
            onClick={() => props.showTransferModel(true)}
          />
        </>
      )}

      <AlertModal
        modalShow={props.alertShow}
        action={() => {
          props.reviewLoan("reject");
        }}
        onClose={props.onAlertClose}
      />
      <AlertModal
        modalShow={props.alertShowForTransaction}
        action={() => {
          props.reviewTransaction("reject");
        }}
        onClose={props.onAlertCloseForTransaction}
      />
      {props.error && <Error>{props.error}</Error>}
      {props.loan.status !== "active" &&
        props.loan.status !== "closed" &&
        props.loan.status !== "rejected" &&
        props.loan.status !== "cancelled" &&
        props.loan.status !== "draft" &&
        props.loan.status !== "kyc-incomplete" &&
        props.loan.status !== "liquidated" &&
         (
          <div className="mt-5">
            {props.reviewing ? (
              <Loader />
            ) : (
              <>
              {props.loan.status !== "pending-collateral" 
               && <div className="mt-20 flex gap-6 justify-between">
                <Button
                  className="w-full"
                  color="green"
                  onClick={() => {
                    props.reviewLoan("approve");
                  }}
                  disabled={props.loan.status !== "admin-signed"}
                  loading={props.reviewing}
                  >
                  Approve
                </Button>
                <Button
                  color="red"
                  className="w-full"
                  onClick={props.onAlertShow}
                  loading={props.reviewing}
                  >
                  Reject
                </Button>
              </div>}
              {props.loan.transactionHash ? <div className="mt-6">
                <div className="flex flex-col justify-between w-full items-center">
                  {props.hashes?.map((hash, key)=>(
                  <div key={key} className="flex flex-row w-full items-center gap-x-4">
                  <DetailRow title={`Txn. Hash ${key+1}`} value={`${hash?.slice(0, 18)}...`} className="w-full" />
                  <div className="cursor-pointer" onClick={()=>handleOnClickToCopyAddress(hash)}>
                    <CopyIcon/>
                  </div>
                  </div>  
                  ))}
                </div>
                <div className="relative flex flex-col mt-2">
                  <span className="text-sm">Enter deposited collateral (in {props.loan.assetId} and expected collateral - {props.loan.desiredCollateral})</span>
                  <InputView value={props.getDepositColletral} onChange={(e)=> props.setGetDepositColletral(e.target.value)} />
                  <div className="py-2 flex w-full justify-end items-center">
                    <Button className="w-full" color="green" onClick={props.handleSubmitDepositColletral} disabled={+props.getDepositColletral >= +props.loan.desiredCollateral ? false : true}>Submit and Approve</Button>
                  </div>
                </div>
              </div> : null}
             </>
            )}
          </div>
        )}
      <AddCommentsSections loanId={props.loan.id} />
    </div>
  );
};
export default LoanDetailsModalAdminView;

type DetailRowProps = {
  title: string;
  value: string | number | undefined;
  className?: string;
};

const DetailRow = ({ title, value, className }: DetailRowProps) => {
  return (
    <div
      className={`${className} flex flex-row justify-between font-sans loan-border detail-row text-base`}
    >
      <span className="block font-normal deposit-tooltip">{title}</span>
      <span className="font-semibold row-value overflow-hidden max-w-xs break-all">
        {value}
      </span>
    </div>
  );
};

const DocumentList: React.FC<{
  documents: server.GetDocumentsResponse;
}> = ({ documents }) => {
  if (!documents.length) {
    return <span>No documents attached</span>;
  }

  return (
    <div>
      <ul>
        {documents.map(document => {
          const documentName = document.filename;

          return (
            <li>
              <a
                href={document.downloanUrl}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer hover:text-purple-600"
              >
                {documentName}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Contract: React.FC<Props> = props => {
  if (
    !props.loan ||
    props.loan.status === "draft" ||
    props.loan.status === "kyc-incomplete" ||
    (props.loan.status === "rejected" && !props.loan.contractId) ||
    (props.loan.status === "cancelled" && !props.loan.contractId)
  ) {
    return null;
  }
  if (!props.loan.contractId) {
    return (
      <>
        <h6 className="h6 pb-7 pt-10">Contract</h6>
        <Button
          className={"w-full"}
          onClick={props.createContract}
          loading={props.processing}
        >
          Create contract
        </Button>
      </>
    );
  }

  return (
    <>
      <h6 className="h6 pb-7 pt-10">Contract</h6>
      {props.loan.status === "pending-review" ? (
        <Button
          className={"w-full"}
          loading={props.processing}
          onClick={props.fetchLoanContract}
        >
          View contract
        </Button>
      ) : (
        <Button
          onClick={props.fetchLoanContract}
          loading={props.processing}
          className={"w-full"}
        >
          Download contract
        </Button>
      )}
    </>
  );
};

const ConsumerFinancialDetails: React.FC<{
  data: server.ConsumerResponsibleLendingData;
}> = ({ data }) => {
  return (
    <div>
      <DetailRow title="Reason for loan" value={data.reasonForLoan} />
      <DetailRow
        title="Loan duration"
        value={`${data.durationInMonths} months`}
      />
      <DetailRow
        title="Annual pre-tax income"
        value={numberToFormattedAUD(data.annualPreTaxIncome)}
      />
      <DetailRow
        title="Employment status"
        value={
          data.employmentStatus[0].toUpperCase() +
          data.employmentStatus.slice(1)
        }
      />
      <DetailRow title="Number of dependants" value={data.numberOfDependants} />
      <DetailRow
        title="Currently in default or other financial hardship"
        value={data.currentlyInFinancialHardshipOrInDefault ? "Yes" : "No"}
      />
      <DetailRow
        title="Monthly accommodation expenses"
        value={numberToFormattedAUD(data.monthlyAccommodationExpenses)}
      />
      <DetailRow
        title="Monthly credit card expenses"
        value={numberToFormattedAUD(data.monthlyCreditCardExpenses)}
      />
      <DetailRow
        title="Monthly loan expenses"
        value={numberToFormattedAUD(data.monthlyLoanExpenses)}
      />
      <DetailRow title="Wallet address" value={data.walletAddress} />
    </div>
  );
};

const BusinessFinancialDetails: React.FC<{
  data: server.BusinessResponsibleLendingData;
}> = ({ data }) => {
  return (
    <div>
      <DetailRow title="Reason for loan" value={data.reasonForLoan} />
      <DetailRow
        title="Loan duration"
        value={`${data.durationInMonths} months`}
      />
      <DetailRow
        title="Monthly Expenses"
        value={numberToFormattedAUD(data.averageMonthlyAndOutgoingExpenses)}
      />
      <DetailRow title="Business Name" value={data.businessName} />
      <DetailRow title="ABN Number" value={data.abn} />
      <DetailRow title="Business Type" value={data.businessType} />
      <DetailRow title="Business Address" value={data.businessAddress} />
      <DetailRow title="Type of Property" value={data.typeOfProperty} />
      <DetailRow
        title="Business Phone Number"
        value={data.businessPhoneNumber}
      />
      <DetailRow
        title="Foreign Company"
        value={data.isForeignCompany ? "Yes" : "No"}
      />
      <DetailRow title="Wallet address" value={data.walletAddress} />
      {data.witness && (
        <>
          <DetailRow title="Witness Name" value={data.witness.name} />
          <DetailRow title="Witness Email" value={data.witness.email} />
        </>
      )}
      {data.secondDirector && (
        <>
          <DetailRow
            title="Second Director Name"
            value={data.secondDirector.name}
          />
          <DetailRow
            title="Second Director Email"
            value={data.secondDirector.email}
          />
        </>
      )}
    </div>
  );
};
