import HTTPClient from "./HTTPClient";
export default class LodaApi extends HTTPClient {
  public login = async (body: server.LoginRequestBody) =>
    this.post<string>(`/auth/login`, body);

  public getBalance = async () => this.get<server.Balances>(`/wallet`);

  public getDepositAddress = async (
    body: server.GetDepositAddressRequestBody
  ) => this.put<server.CryptoAddress>(`/wallet/address`, body);

  public withdrawFunds = async (body: server.WithdrawRequestBody) =>
    this.post<server.WithdrawRequestBody>(`/wallet/withdraw`, body);

  public transferCollateral = async (
    params: server.TransferCollateralParams
  ) => {
    this.post<server.Loan>(`/admin/loan/${params.loanId}/transfer-collateral`);
  };

  public requestWithdrawFunds = async (
    params: server.RequestCollateralParams,
    body: server.RequestCollateralRequestBody
  ) =>
    this.post<server.Loan>(`/loan/${params.loanId}/request-collateral`, body);

  public getCollateralRepayDetails = async (
    params: server.GetCollateralRepaymentDetails
  ) => {
    return this.get<server.RepaymentDetails>(
      `/loan/repay-collateral/${params.loanId}/${params.repayLoanAmountInAud}`
    );
  };

  public getLiquidateLoanDetails = async (
    params: server.GetLiquidateLoanDetails
  ) => {
    return this.get<server.LiquidateLoanDetails>(
      `/admin/get-liquidate-loans/${params.loanId}/${params.liquidationPercentage}`
    );
  };

  public signup = async (body: server.SignupRequestBody) =>
    this.post<string>(`/auth/signup`, body);

  public sendVerifyEmailLink = async (
    body: server.SendVerifyEmailRequestBody
  ) => this.post<server.User>(`/auth/send-email-verification-link`, body);

  public fetchAuthUser = async () => this.get<server.User>(`/user`);

  public addBankAccount = async (body: server.BankAccount) =>
    this.post<server.User>(`/user/bank-account`, body);

  public updateBankAccount = async (body: server.BankAccount) =>
    this.put<server.User>(`/user/bank-account-update`, body);

  public userProfile = async () => this.get<server.Profile>(`/user/profile`);

  public addPhoneNumber = async (body: server.AddPhoneNumberRequestBody) =>
    this.post<server.User>(`/user/phone`, body);

  public verifyPhoneNumber = async (
    body: server.VerifyPhoneNumberRequestBody
  ) => this.post<server.User>(`/user/phone/verify`, body);

  public verifyIdentity = async (body: server.VerifyIdentityRequestBody) =>
    this.post<server.User>(`/user/identity/verify`, body);

  public refreshToken = async () => this.get<string>(`/auth/refreshToken`);

  public getAssetPrices = async () => this.get<server.Asset[]>(`/wallet/asset`);

  public getSystemState = async () => this.get<server.SystemState>(`/system`);

  public createEmptyLoan = async (data: server.RequestLoanRequestBody) =>
    this.post<server.Loan>(`/loan`, data);

  public repayWithCollateral = async (
    data: server.GetCollateralRepaymentDetails
  ) => this.post<server.Success>("/loan/initiate-collateral-repay", data);

  public updateLoan = async (
    params: server.RequestCollateralParams,
    data: server.RequestLoanRequestBody
  ) => this.patch<server.Loan>(`/loan/${params.loanId}`, data);

  public getLoanContract = async (loanId: number) =>
    this.get<string>(`/loan/${loanId}/contract`);

  public searchLoans = async (params: server.LoanSearchQuery) => {
    return this.get<server.Loan[]>("/loan/search", { params });
  };

  public liquidateLoan = async (body: server.LiquidateLoanQuery) => {
    return this.patch<server.Loan>("/admin/loan/liquidate-loan", body);
  };

  public getLoan = async (loanId: number) => {
    return this.get<server.Loan>(`/loan/${loanId}`);
  };

  public getLoanFinancialData = async (loanId: number) =>
    this.get<
      | server.ConsumerResponsibleLendingData
      | server.BusinessResponsibleLendingData
    >(`/loan/${loanId}/financial`);

  public getLoanDocuments = async (loanId: number) =>
    this.get<server.GetDocumentsResponse>(`/loan/${loanId}/documents`);

  public adminGetUsers = async (params: server.UserSearchQuery) =>
    this.get<server.SearchResults<server.UserWithLoanAndBalanceDetails>>(
      `/admin/user`,
      {
        params
      }
    );

  public adminGetLoans = async (params: server.LoanSearchQuery) =>
    this.get<server.SearchResults<server.Loan>>(`/admin/loan`, {
      params
    });

  public adminCreateLoanContract = async (loanId: number) =>
    this.post<server.Loan>(`/admin/loan/${loanId}/contract`);

  public adminGetUser = async (userId: string) =>
    this.get<server.User>(`/admin/user/${userId}`);

  public uploadFileToGoogleCloud = async (fileContents: any) => {
    const { filename, uploadUrl } = await this.get<server.GetUploadUrlResponse>(
      "user/documents/bank/upload"
    );
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", uploadUrl);
    xhr.setRequestHeader("Content-Type", "application/pdf");
    xhr.send(fileContents);
    return filename;
  };

  public reviewLoan = async (
    loanId: number,
    body: server.ReviewLoanRequestBody
  ) => this.post<server.Loan>(`/admin/loan/${loanId}/review`, body);

  public reviewTransaction = async (
    loanId: number,
    body: server.RequestDepositCollateralBody
  ) => this.post<server.Loan>(`/admin/loan/${loanId}/review-transaction`, body);

  public cancelLoan = async (loanId: number) =>
    this.post<server.Loan>(`/loan/${loanId}/cancel`);
  public forgotPassword = async (body: server.ForgotPasswordRequestBody) =>
    this.post<server.SuccessResponse>(`/auth/forgot-password`, body);
  public resetPassword = async (body: server.ResetPasswordRequestBody) =>
    this.post<server.SuccessResponse>(`/auth/reset-password`, body);
  public getAllNotes = async (params: server.GetAllNotesParams) =>
    this.get<server.Note[]>(`/admin/loan/${params.loanId}/get-all-notes`);
  public addNote = async (
    body: server.AddNoteRequestBody,
    params: server.AddNoteParams
  ) =>
    this.post<server.SuccessResponse>(
      `/admin/loan/${params.loanId}/add-note`,
      body
    );
    public updateTransactionHash = async (loanId: number, body:server.RequestTransactionHash) => this.put<server.Loan>(`/loan/${loanId}/add-transaction-hash`, body);
}
