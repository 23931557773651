import moment from "moment";
import React, { useState } from "react";
import useSWR from "swr";
import { Button } from "../../components";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import Error from "../error/Error";
interface IAddCommentsSections {
  loanId: number;
}
export const AddCommentsSections: React.FC<IAddCommentsSections> = ({
  loanId
}) => {
  const { user } = useAuth();
  const api = useApi();
  const { data: notes, revalidate } = useSWR("notes", () =>
    api.getAllNotes({ loanId })
  );
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState<string | null>(null);
  if (!user) {
    return null;
  }
  const submitHandler = async () => {
    try {
      setLoading(true);
      await api.addNote(
        {
          description: comment
        },
        { loanId }
      );
      await revalidate();
    } catch (error) {
      if (!comment) setError("Comment can not be empty");
    }
    setLoading(false);
    setComment("");
  };

  return (
    <div>
      <h6 className="h6 pb-7 pt-10">comments</h6>

      {notes?.map((comment, index) => {
        const key = index;
        return (
          <div key={key} className="flex">
            <div className="p-2 w-7 h-7 bg-purple-electric mt-1 text-white text-base uppercase mr-4 rounded-full flex items-center justify-center text-center">
              {comment.firstName.substr(0, 1)}
            </div>
            <div className="comments">
              <h6 className="capitalize">
                {comment.firstName}{" "}
                <span className="text-sm font-normal deposit-tooltip">
                  {moment(comment.createdAt).format("DD/MM/YYYY")}
                </span>
              </h6>
              <p className="mb-5 text-sm">{comment.description}</p>
            </div>
          </div>
        );
      })}
      <div className="comments-box">
        <textarea
          className={`w-full h-20 p-4 mb-5 text-sm resize-none px-4 bg-background-light border border-solid border-other-gray rounded-xl outline-none focus:border-purple-electric`}
          onChange={e => {
            setComment(e.target.value);
            setError(null);
          }}
          placeholder="Leave a comment"
          value={comment}
        />
        {error && <Error classname="mb-4">{error}</Error>}
        <Button
          loading={loading}
          className="pl-6 pr-6"
          onClick={submitHandler}
          disabled={!comment.trim().length}
        >
          Comment
        </Button>
      </div>
    </div>
  );
};
