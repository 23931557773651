import { useEffect, useState } from "react";
import { LIQUIDATE_TYPE } from "../../helpers/loans-type";
import useApi from "../../hooks/useApi";
import useGlobalError from "../../hooks/useGlobalError";
import LiquidateModalView from "./LiquidateModal_View";

type LiquidateModalProps = {
  modalTitle: LIQUIDATE_TYPE;
  setShowLiquidateModal(showLiquidateModal: boolean): void;
  showLiquidateModal: boolean;
  assetPrice: number;
  lvr: number;
  setLvr(lvr: number): void;
  selectedLoan: server.Loan;
  refreshLoan(): void;
};

const LiquidateModal: React.FC<LiquidateModalProps> = props => {
  const [percentageCrypto, setPercentageCrypto] = useState<number | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>();
  const [newCollateral, setNewCollateral] = useState(
    +props.selectedLoan.collateral
  );
  const [newLvr, setNewLvr] = useState(props.lvr);
  const [newLoanAmount, setNewLoanAmount] = useState(
    +props.selectedLoan.outstandingDebt.toFixed(2)
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const api = useApi();

  useEffect(() => {
    setIsDisabled(
      props.modalTitle === LIQUIDATE_TYPE.FULL
        ? false
        : error !== undefined || !percentageCrypto
    );
  }, [error, props.modalTitle, percentageCrypto]);

  const onClose = () => {
    props.setShowLiquidateModal(false);
  };

  useEffect(() => {
    if (props.modalTitle === LIQUIDATE_TYPE.FULL) {
      setPercentageCrypto(100);
    }
  }, [props.modalTitle]);

  const onChangeOfCryptoPercentInbox = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setError(undefined);
      const valueOfInput = Number(event.target.value);
      setPercentageCrypto(valueOfInput);
      if (valueOfInput <= 0 || valueOfInput > 100) {
        setError("Please enter a valid liquidation percentage.");
        setNewCollateral(+props.selectedLoan.collateral);
        setNewLoanAmount(props.selectedLoan.outstandingDebt);
        setNewLvr(+props.lvr);
        return;
      }
      const data = await api.getLiquidateLoanDetails({
        loanId: props.selectedLoan.id,
        liquidationPercentage: valueOfInput
      });
      setNewCollateral(data.collateralChange);
      setNewLoanAmount(data.loanAmountChange);
      setNewLvr(data.lvrChanged);
    } catch (error) {
      setNewCollateral(+props.selectedLoan.collateral);
      setNewLoanAmount(props.selectedLoan.outstandingDebt);
      setNewLvr(+props.lvr);
    }
    if (newLoanAmount < 0) {
      setError("Outstanding debt can't be less than 0.");
    }
  };

  const { setGlobalError } = useGlobalError();

  const liquidate = async () => {
    try {
      setShowLoader(true);
      await api.liquidateLoan({
        loanType: props.modalTitle,
        loanId: props.selectedLoan.id,
        liquidationPercentage: percentageCrypto!
      });
      setShowLoader(false);
      props.setShowLiquidateModal(false);
      props.refreshLoan();
    } catch (err: any) {
      setGlobalError(err.message);
    }
  };
  return (
    <LiquidateModalView
      modalTitle={props.modalTitle}
      setShowLiquidateModal={props.setShowLiquidateModal}
      showLiquidateModal={props.showLiquidateModal}
      assetPrice={props.assetPrice}
      lvr={props.lvr}
      onClick={liquidate}
      setLvr={props.setLvr}
      selectedLoan={props.selectedLoan}
      onClose={onClose}
      newCollateral={newCollateral}
      newLvr={newLvr}
      newLoanAmount={newLoanAmount}
      percentageCrypto={percentageCrypto}
      onChangeOfCryptoPercentInbox={onChangeOfCryptoPercentInbox}
      error={error}
      isDisabled={isDisabled}
      originalCollateral={+props.selectedLoan.collateral}
      showLoader={showLoader}
    />
  );
};

export default LiquidateModal;
