import useQuery from "./useQuery";

const useLoanDetailsQueryString = (): {
  loanAmount?: number;
  collateralToDebtRatio?: number;
  collateral?: number;
} => {
  const queryString = useQuery();

  const loanAmount = queryString.get("loanAmount");
  const collateralToDebtRatio = queryString.get("collateralToDebtRatio");
  const collateral = queryString.get("collateral");

  return {
    loanAmount: loanAmount ? Number(loanAmount) : undefined,
    collateralToDebtRatio: collateralToDebtRatio
      ? Number(collateralToDebtRatio)
      : undefined,
    collateral: collateral ? Number(collateral) : undefined
  };
};

export default useLoanDetailsQueryString;
