import * as React from "react";
import classnames from "classnames";
import { Loader } from "../";

type Color = "green" | "red";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  color?: Color;
};

const Button: React.FC<Props> = props => {
  const { className, title, loading, disabled, color, ...restOfProps } = props;
  return (
    <button
      className={classnames(
        className,
        `font-bold h-14 px-2 py-1 text-white rounded-xl focus:outline-none`,
        {
          "bg-green-500": color === "green",
          "bg-red-500": color === "red",
          "bg-purple-electric": !color,
          "opacity-50": disabled,
          "hover:bg-white hover:text-purple-electric": !disabled
        }
      )}
      disabled={loading || disabled}
      {...restOfProps}
    >
      {loading ? <Loader className="text-lg" /> : title || props.children}
    </button>
  );
};

export default Button;
