type Props = {
  className?: string;
};

export const CrossCloseLogo: React.FC<Props> = props => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M2.58304 0.44318C1.99214 -0.147727 1.03409 -0.147727 0.44318 0.44318C-0.147727 1.03409 -0.147727 1.99214 0.44318 2.58304L4.86015 7.00001L0.44318 11.417C-0.147726 12.0079 -0.147727 12.9659 0.44318 13.5568C1.03409 14.1477 1.99214 14.1477 2.58304 13.5568L7.00001 9.13987L11.417 13.5568C12.0079 14.1477 12.9659 14.1477 13.5568 13.5568C14.1477 12.9659 14.1477 12.0079 13.5568 11.417L9.13987 7.00001L13.5568 2.58306C14.1477 1.99215 14.1477 1.0341 13.5568 0.443198C12.9659 -0.147709 12.0079 -0.147709 11.417 0.443198L7.00001 4.86014L2.58304 0.44318Z"
        fill="white"
      />
    </svg>
  );
};

export const MobileCloseLogo: React.FC<Props> = props => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g filter="url(#filter0_d)">
        <rect x="0" y="0" width="100%" height="100%" rx="6" fill="#18171F" />
        <rect
          x="0.5"
          y="0.5"
          width="100%"
          height="100%"
          rx="5"
          stroke="#382F3E"
        />
      </g>
      <svg x="-65" y="-180" viewBox="0 0 100 100" width="300%" height="300%">
        <path d="M40 72L35 77L40 82" stroke="#7A4FFF" strokeWidth="2" />
      </svg>
      <defs>
        <filter
          id="filter0_d"
          x="-47"
          y="-8"
          width="170"
          height="170"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="35" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.117647 0 0 0 0 0.12549 0 0 0 0 0.14902 0 0 0 0.7 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
