import { QuickForm, Input } from "../";
import bankAccountValidator from "../../helpers/validators/bank-account-validator";

type Props = {
  addBankAccount: (data: server.BankAccount) => Promise<void>;
  error: string | null;
};

const AddBankAccountView: React.FC<Props> = props => {
  return (
    <div className="text-center">
      <p className="text-sm px-3 my-2"></p>
      <QuickForm<server.BankAccount>
        initialValues={{ bsbNumber: "", accountName: "", accountNumber: "" }}
        validator={bankAccountValidator}
        onSubmit={props.addBankAccount}
        error={props.error}
        buttonTitle="Save Details"
      >
        <Input
          name="accountName"
          type="text"
          placeholder="Account Name"
          maxLength={50}
        />
        <Input name="bsbNumber" type="text" placeholder="BSB" maxLength={7} />
        <Input
          name="accountNumber"
          type="text"
          placeholder="Account Number"
          maxLength={16}
          minLength={6}
        />
      </QuickForm>
    </div>
  );
};

export default AddBankAccountView;
