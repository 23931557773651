import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useGlobalError from "../../hooks/useGlobalError";
import ForgotPasswordView from "./ForgotPassword_View";
import { ForgotPasswordRequestBody } from "../../helpers/validators/Forgot-validator";
import useApi from "../../hooks/useApi";

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { setGlobalError } = useGlobalError();
  const api = useApi();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (user) {
      history.push("/account");
    }
  }, [user, history]);

  const submitHandler = async (data: ForgotPasswordRequestBody) => {
    setIsSubmit(false);
    try {
      const result = await api.forgotPassword(data);
      if (result.success) setIsSubmit(true);
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.statusCode) {
        if (error.statusCode === 404) {
          const notFoundError = error as server.NotFoundResponse;
          if (notFoundError.resource === "user") {
            return setError(error.message);
          }
        }
      }

      setGlobalError(error);
    }
  };
  return (
    <ForgotPasswordView
      isSubmit={isSubmit}
      submitHandler={submitHandler}
      error={error}
    />
  );
};

export default ForgotPassword;
