import * as React from "react";
import classnames from "classnames";
import { Loader } from "..";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  icon?: IconDefinition;
  iconClass?: string;
  customIcon?: React.ReactNode;
};

const ButtonIcon: React.FC<Props> = props => {
  const {
    className,
    title,
    loading,
    disabled,
    customIcon,
    icon,
    iconClass,
    ...restOfProps
  } = props;
  return (
    <button
      className={classnames(
        className,
        `font-bold h-14 p-2 bg-transparent border-2 border-solid border-purple-electric text-purple-electric rounded-xl focus:outline-none`,
        { "opacity-50": disabled },
        { "hover:border-white hover:text-white": !disabled }
      )}
      disabled={loading || disabled}
      {...restOfProps}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={`text-purple-electric text-base ${iconClass}`}
        />
      )}
      {!loading && customIcon}
      <span className="m-2">
        {loading ? <Loader className="text-lg" /> : title || props.children}
      </span>
    </button>
  );
};

export default ButtonIcon;
