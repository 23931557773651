import { BubbleTextColor } from "./BubbleText/BubbleText_View";

export const getFormattedLoanStatus = (
  loanStatus: server.LoanStatus
): string => {
  const loanStatusMapping: LoanStatusMapping<string> = {
    active: "Active",
    "pending-review": "Pending Review",
    "pending-outgoing-payment": "Sending Payment",
    closed: "Closed",
    rejected: "Rejected",
    "pending-legal": "Pending Legal",
    liquidated: "Liquidated",
    "pending-collateral": "Pending Collateral",
    "admin-signed": "Pending Review",
    "loan-completed": "Loan Completed",
    "collateral-requested": "Withdrawal Pending",
    cancelled: "Cancelled Application",
    "withdraw-initiated": "Withdrawal Initiated",
    "retry-withdraw": "Retry Withdraw",
    "kyc-incomplete": "KYC Incomplete",
    draft: "Draft Loan"
  };

  return loanStatusMapping[loanStatus] || loanStatus;
};

export const getLoanStatusColor = (
  loanStatus: server.LoanStatus
): BubbleTextColor => {
  const loanStatusMapping: LoanStatusMapping<BubbleTextColor> = {
    active: "green",
    "pending-review": "blue",
    "pending-outgoing-payment": "blue",
    "pending-legal": "blue",
    "pending-collateral": "yellow",
    closed: "gray",
    rejected: "red",
    liquidated: "red",
    "admin-signed": "blue",
    "loan-completed": "green",
    "collateral-requested": "yellow",
    cancelled: "red",
    "withdraw-initiated": "yellow",
    "retry-withdraw": "red",
    "kyc-incomplete": "red",
    draft: "red"
  };

  return loanStatusMapping[loanStatus];
};
