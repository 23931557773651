import { createContext, useEffect, useState, useCallback } from "react";
import { cache } from "swr";
import useApi from "../hooks/useApi";
import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "../services/identity-auth.service";

export interface User extends server.User {
  isEmailVerified?: boolean;
}
export type AuthContextType = {
  user: User | undefined;
  isLoading: boolean;
  login(): Promise<void>;
  logout(): void;
  setUser(user: User): void;
};

export const AuthContext = createContext<AuthContextType>(null as any);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const api = useApi();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);

  const fetchUser = useCallback(async () => {
    onAuthStateChanged(auth, async currentUser => {
      if (currentUser) {
        try {
          api.attachAuthHeaderInterceptor(async () => {
            const token = await currentUser.getIdToken(true);
            return token;
          });
          const fetchUser = (await api.fetchAuthUser()) as User;
          if (
            currentUser.emailVerified ||
            fetchUser.hasBankAccount ||
            fetchUser.idVerified
          ) {
            fetchUser.isEmailVerified = currentUser.emailVerified;
            setUser(fetchUser);
          } else {
            const firebaseUser: User = {
              admin: false,
              email: currentUser.email!,
              firstName: fetchUser.firstName,
              hasBankAccount: false,
              idVerified: false,
              lastName: fetchUser.lastName,
              maxIdVerificationFailuresReached: false,
              phoneNumber: "",
              phoneNumberVerified: false,
              userId: currentUser.uid,
              isEmailVerified: currentUser.emailVerified
            };
            setUser(firebaseUser);
          }
          setLoading(false);
        } catch (err) {
          api.removeAuthHeaderInterceptor();
          await auth.signOut();
          setUser(undefined);
          setLoading(false);
        }
      } else {
        api.removeAuthHeaderInterceptor();
        setUser(undefined);
        setLoading(false);
      }
    });
  }, [api]);

  const login = async () => {
    fetchUser();
  };

  const logout = async () => {
    await auth.signOut();
    api.removeAuthHeaderInterceptor();
    setUser(undefined);
    cache.clear();
  };

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  api.setCustomOnError((error: server.ErrorResponse) => {
    if (error.statusCode && error.reason) {
      const e: server.ErrorResponse = error;
      if (e.statusCode === 401) {
        logout();
      }
    }
  });

  return (
    <AuthContext.Provider value={{ user, isLoading, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
