import React from 'react'
import Modal from '../Modal/Modal_View';

type Props = {
    show: boolean;
    onClose: () => void;
    modalData:{title:string; text:string;}
}

const DepositInfoModal_View:React.FC<Props> = (props) => {
  return (
        <Modal
      title={`${props.modalData.title}`}
      show={props.show}
      onClose={props.onClose}
    >
      <div className="mt-6">
        <span>{props.modalData.text}</span>
      </div>
    </Modal>
  )
}

export default DepositInfoModal_View;
