import Input from "../../components/Input/Input_Logic";
import { AuthPageLayout, QuickForm } from "../../components";
import validator from "../../helpers/validators/Forgot-validator";
import { ForgotPasswordRequestBody } from "../../helpers/validators/Forgot-validator";
import { Link } from "react-router-dom";

type Props = {
  submitHandler: (data: ForgotPasswordRequestBody) => Promise<void>;
  error: string | null;
  isSubmit: boolean;
};

const ForgotPasswordView: React.FC<Props> = props => {
  return (
    <AuthPageLayout>
      <QuickForm<ForgotPasswordRequestBody>
        initialValues={{ email: "" }}
        validator={validator}
        onSubmit={props.submitHandler}
        error={props.error}
        buttonTitle="Send link"
      >
        <h2 className="text-3xl mb-4 font-bold">Forgot password</h2>
        <div className="mt-6">
          Enter the email address you used to sign up and we’ll send you a link
          to reset your password.{" "}
          <Link className="text-purple-electric cursor-pointer" to="login">
            Login
          </Link>
        </div>
        <div className="mt-5">
          <Input name="email" placeholder="Email" />
        </div>
        {props.isSubmit && (
          <div className="mt-5 w-full text-xs p-1 my-1 text-green-300">
            <span>
              If this account exists, the reset password email will be sent to
              it. Please check your email inbox for the same.
            </span>
          </div>
        )}
      </QuickForm>
    </AuthPageLayout>
  );
};

export default ForgotPasswordView;
