import React from "react";
import { Select, PageLayout, Paginate, BubbleText } from "../../../components";
import {
  Table,
  TableHead,
  TableRow
} from "../../../components/Table/Table_View";
import { numberToFormattedAUD } from "../../../helpers/formatters";

type Props = {
  users: server.SearchResults<server.UserWithLoanAndBalanceDetails> | undefined;
  currentPage: number;
  pageRange: number;
  loanFilter: server.loanFilter;
  assetFilter: server.balanceFilter;
  onPageChange(page: number): void;
  onChangePageRange(pageRange: any): void;
  onFilterLoans(loanFilter: server.loanFilter): void;
  onFilterAssets(loanFilter: server.balanceFilter): void;
};

const UserTableView: React.FC<Props> = props => {
  return (
    <PageLayout showHeader={true} loading={!props.users}>
      <div className="flex flex-col w-full item-center">
        <UserTable
          users={props.users!}
          currentPage={props.currentPage}
          pageRange={props.pageRange}
          loanFilter={props.loanFilter}
          balanceFilter={props.assetFilter}
          onPageChange={props.onPageChange}
          onChangePageRange={props.onChangePageRange}
          onFilterLoans={props.onFilterLoans}
          onFilterAssets={props.onFilterAssets}
        />
      </div>
    </PageLayout>
  );
};

export default UserTableView;

type UserTableProps = {
  users: server.SearchResults<server.UserWithLoanAndBalanceDetails>;
  currentPage: number;
  pageRange: number;
  loanFilter: server.loanFilter;
  balanceFilter: server.balanceFilter;
  onPageChange(page: number): void;
  onChangePageRange(pageRange: number): void;
  onFilterLoans(loanFilter: server.loanFilter): void;
  onFilterAssets(loanFilter: server.balanceFilter): void;
};

const UserTable: React.FC<UserTableProps> = props => {
  const { items } = props.users;
  const pages = props.users.totalCount / props.pageRange;

  return (
    <div>
      <div className="flex justify-end">
        <table>
          <thead>
            <tr className={"text-right flex font-semibold"}>
              <th className="text-xs uppercase px-3 flex flex-col">
                Filter: Balance
                <Select
                  value={props.balanceFilter}
                  className="mt-2 my-3 width-auto bg-gray-200 w-min h-10 pr-3"
                  onChange={e => {
                    props.onFilterAssets(e.target.value);
                  }}
                >
                  <option value="all">All</option>
                  <option value="has_balance">Has Balance</option>
                  <option value="has_no_balance">Has No Balance</option>
                </Select>
              </th>
              <th className="text-xs uppercase px-3 flex flex-col">
                Loan Status
                <Select
                  value={props.loanFilter}
                  className="mt-3 my-3 width-auto bg-gray-200 w-min h-10 pr-3"
                  onChange={e => {
                    props.onFilterLoans(e.target.value);
                  }}
                >
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="ever">No Active</option>
                  <option value="never">Never</option>
                  <option value="completed">Repaid</option>
                  <option value="liquidated">Liquidated</option>
                </Select>
              </th>
              <th className="text-xs uppercase px-3 flex flex-col">
                Per Page
                <Select
                  value={props.pageRange.toString()}
                  className="mt-3 my-3 width-auto bg-gray-200 w-min h-10 pr-3"
                  onChange={e => {
                    props.onChangePageRange(Number(e.target.value));
                  }}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div>
        <Table className="table-auto text-left mt-10 divide-y divide-gray-800">
          <TableHead>
            <th className="pl-4 py-3 text-xs font-bold text-purple-light">
              Name
            </th>
            <th className="pl-2 pr-4 text-xs font-bold text-purple-light">
              Phone Number
            </th>
            <th className="pl-2 pr-4 text-xs font-bold text-purple-light">
              Email
            </th>
            <th className="pl-2 pr-4 text-xs font-bold text-purple-light">
              Balances
            </th>
            <th className="pl-2 pr-4 text-xs font-bold text-purple-light">
              Collateral
            </th>
            <th className="pl-2 pr-4 text-xs font-bold text-purple-light">
              Loans
            </th>
            <th className="pl-2 pr-4 text-xs font-bold text-purple-light"></th>
          </TableHead>
          <tbody className="divide-border border-none divide-y divide-gray-800">
            {items.map((b, index) => {
              return (
                <TableRow key={index}>
                  <td className="py-7 pl-4 font-semibold text-base text-white ">
                    {b.firstName} {b.lastName}
                  </td>
                  <td className="py-2 pl-2 pr-4 font-semibold text-base text-white">
                    {b.phoneNumber}
                  </td>
                  <td className="py-2 pl-2 pr-4 font-semibold text-base text-white">
                    {b.email}
                  </td>
                  <td className="py-2 pl-2 pr-4">
                    {b.balances.map((b, index) => {
                      return (
                        <span
                          className="block font-semibold font-sans text-base"
                          key={index}
                        >
                          {Number(b.balance).toFixed(6)} {b.assetId}
                        </span>
                      );
                    })}
                  </td>
                  <td className="py-2 pl-2 pr-4">
                    {b.balances.map((b, index) => {
                      return (
                        <span
                          className="block font-semibold font-sans text-base"
                          key={index}
                        >
                          {Number(b.collateralBalance).toFixed(6)} {b.assetId}
                        </span>
                      );
                    })}
                  </td>
                  <td className="py-2">
                    {b.loans.map((b, index) => {
                      return (
                        <span className="block p-2" key={index}>
                          {getLoanStatus(b)}
                        </span>
                      );
                    })}
                  </td>
                  <td className="py-2 pr-4"></td>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Paginate
        current={props.currentPage}
        onPageChange={props.onPageChange}
        range={10}
        total={pages}
      />
    </div>
  );
};

const getLoanStatus = (l: server.Loan) => {
  let color;
  let status;

  if (l.status === "active") {
    color = "green";
  } else {
    color = l.status === "liquidated" ? "blue" : "red";
  }
  status =
    numberToFormattedAUD(l.outstandingDebt) +
    "  " +
    l.status.toString().toUpperCase();

  return <BubbleText color={color as any}>{status}</BubbleText>;
};
