import { useState } from "react";
import useApi from "../../hooks/useApi";
import useGlobalError from "../../hooks/useGlobalError";
import WithdrawModalView from "./WithdrawModal_View";

type Props = {
  loan?: server.Loan;
  refetchLoans: () => Promise<boolean>;
  onClose: () => void;
};

const WithdrawModal: React.FC<Props> = props => {
  const { setGlobalError } = useGlobalError();
  const api = useApi();

  const [finishedWithdrawing, setFinishedWithdrawing] = useState(false);

  const close = () => {
    setFinishedWithdrawing(false);
    props.onClose();
  };

  const onWithdraw = async (loanId: number, walletAddress: string) => {
    try {
      await api.requestWithdrawFunds(
        { loanId },
        {
          withdrawAddress: walletAddress
        }
      );

      await props.refetchLoans();
      setFinishedWithdrawing(true);
    } catch (error) {
      setGlobalError(error as server.ErrorResponse);
    }
  };

  return (
    <WithdrawModalView
      {...props}
      onClose={close}
      onWithdraw={onWithdraw}
      finishedWithdrawal={finishedWithdrawing}
    />
  );
};

export default WithdrawModal;
