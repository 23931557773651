import classNames from "classnames";

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  labelLeftAligned?: boolean; // default false
  error?: string;
  className?: string;
  layout?: "switch" | "default";
};

const Switch: React.FC<Props> = ({
  className,
  layout = "switch",
  ...props
}) => {
  return (
    <>
      <div className={`flex flex-row items-center ${className}`}>
        {layout === "switch" ? (
          <>
            <label
              htmlFor={props.name}
              className={classNames("text-sm pr-3 font-semibold text-justify", {
                "order-first mr-2": props.labelLeftAligned
              })}
            >
              {props.label}
            </label>
            <div
              className={classNames(
                "flex-none relative inline-block w-10 align-middle select-none transition duration-200 ease-in",
                { "mr-4": !props.labelLeftAligned }
              )}
            >
              <input
                type="checkbox"
                className={classNames(
                  "mt-1 mx-1 absolute block w-4 h-4 rounded-full bg-white appearance-none cursor-pointer",
                  {
                    "right-0 checkbox-checked border-purple-electric":
                      props.checked
                  }
                )}
                {...props}
                id={props.name}
              />
              <label
                htmlFor={props.name}
                className={classNames(
                  "block overflow-hidden h-6 rounded-full cursor-pointer border-2 border-solid border-purple-electric",
                  {
                    "bg-purple-electric": props.checked
                  }
                )}
              ></label>
            </div>
          </>
        ) : (
          <>
            <input
              type="checkbox"
              className={classNames(
                "appearance-none cursor-pointer border-other-gray border mr-4 align-middle default-checkbox w-6 h-6",
                { "checkbox-checked": props.checked }
              )}
              {...props}
            />{" "}
            <label htmlFor={props.name} className="text-sm font-medium">
              {props.label}
            </label>
          </>
        )}
      </div>
      {props.error && (
        <div
          className={`text-purple-light text-xs p-1 my-1 mt-2 ${
            layout === "default" && "pl-10"
          }`}
        >
          {props.error}
        </div>
      )}
    </>
  );
};

export default Switch;
