import validator from "validator";
import isMobilePhone from "validator/lib/isMobilePhone";

export type BusinessLoanPayload = {
  businessName: string;
  ABN: string;
  businessType: string;
  businessAddress: string;
  propertyType: string;
  businessPhoneNumber: string;
  monthlyExpenses: string;
  isForeignLoan: boolean;
  name: string;
  email: string;
};

const businessLoanValidator = ({
  businessName,
  ABN,
  businessType,
  businessAddress,
  propertyType,
  businessPhoneNumber,
  monthlyExpenses,
  email,
  isForeignLoan,
  name
}: BusinessLoanPayload) => {
  const errors: ValidatorResult<BusinessLoanPayload> = {};

  if (validator.isEmpty(businessName))
    errors.businessName = "Please enter a business name";
  if (
    !validator.isLength(ABN, {
      max: 11,
      min: 11
    })
  )
    errors.ABN = "Please enter a valid ABN";
  if (validator.isEmpty(businessType))
    errors.businessType = "Please select a business type";
  if (validator.isEmpty(propertyType))
    errors.propertyType = "Please select a business property type";
  if (validator.isEmpty(businessAddress))
    errors.businessAddress = "Please enter a business address";
  if (validator.isEmpty(monthlyExpenses.toString()))
    errors.monthlyExpenses = "Please enter monthly expenses";
  if (
    validator.isLength(businessPhoneNumber, { min: 1 }) &&
    !isMobilePhone(businessPhoneNumber, "en-AU")
  )
    errors.businessPhoneNumber = "Please enter a valid mobile number.";
  if (
    (validator.isLength(name, { min: 1 }) ||
      validator.isLength(email, { min: 1 })) &&
    !validator.isEmail(email)
  )
    if (!validator.isEmail(email)) errors.email = "Please enter a valid email";
  if (isForeignLoan) {
    if (!validator.isEmail(email)) errors.email = "Please enter a valid email";
    if (validator.isEmpty(name)) errors.name = "Please enter a valid name";
  }
  return errors;
};

export default businessLoanValidator;
