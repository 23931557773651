import { IdpAddPhoneNumber, Modal } from "..";
import Input from "../../components/Input/Input_Logic";
import { QuickForm } from "../../components";
import validator from "../../helpers/validators/login-validator";

type Props = {
  show: boolean;
  onClose(): void;
  updateNumber?: (data: server.AddPhoneNumberRequestBody) => Promise<void>;
  verifyNumber: (data: server.VerifyPhoneNumberRequestBody) => Promise<void>;
  login: (data: server.LoginRequestBody) => Promise<void>;
  error: string | null;
  email: string;
  step: string;
  isTwoFaEnabled: boolean;
  updateStep: (value: string) => void;
  phoneVerified: boolean;
  phoneNumber?: string;
};

const TwoFaModalView: React.FC<Props> = props => {
  return (
    <>
      {props.show === true && (
        <Modal
          title=""
          show={props.show}
          onClose={() => {
            props.updateStep("emailVerification");
            props.onClose();
          }}
        >
          {props.step === "phoneVerification" && (
            <>
              <div id="verify-button"></div>
              <IdpAddPhoneNumber
                phoneNumber={props.phoneNumber}
                updateNumber={props.updateNumber}
                verifyNumber={props.verifyNumber}
                isTwoFAEnabled={props.isTwoFaEnabled}
              />
            </>
          )}
          {props.step === "emailVerification" && (
            <div className="text-center">
              <QuickForm<server.LoginRequestBody>
                initialValues={{ email: props.email, password: "" }}
                validator={validator}
                onSubmit={props.login}
                error={props.error}
                buttonTitle="Continue"
                buttonId="login-button"
              >
                <h2 className="title-font mb-4">Verify Your Password</h2>

                <Input name="email" placeholder="Email" type="email" readOnly />
                <div className="mb-1">
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                </div>
              </QuickForm>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default TwoFaModalView;
