import React from "react";
import { Transition } from "@headlessui/react";
import { NavButton, LodaLogo, AdminDropDownMenu } from "../";
import {
  NavButtonViewLayout,
  Props as NavButtonProps
} from "../NavButton/NavButton_View";
import { Link as AdminDropMenuLink } from "../AdminDropDownMenu/AdminDropDownMenu_View";
import { Link as HomeLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

export type Link = NavButtonProps & { mobileOnly?: true };

type Props = {
  isOpen: boolean;
  toggleOpen(): void;
  links: Link[];
  adminDropMenuLinks?: AdminDropMenuLink[];
  logout(): void;
};

const NavBar: React.FC<Props> = ({
  isOpen,
  toggleOpen,
  links,
  adminDropMenuLinks,
  logout
}) => {
  const isAdmin = useAuth().user?.admin;
  const { user } = useAuth();
  const name = user?.firstName ? user?.firstName + " " + user?.lastName : "";
  const history = useHistory();

  if (isAdmin && history.location.pathname === "/account") {
    history.push("/admin/loans");
  }
  return (
    <nav>
      <div className="border-b border-solid border-color-light">
        <div className="max-w-7xl mx-auto flex items-center justify-between h-16 px-3">
          <div className="flex items-center justify-between w-full">
            <HomeLink to="/">
              <LodaLogo className="w-20 h-20" />
            </HomeLink>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4 blue-button">
                {links
                  .filter(l => !l.mobileOnly)
                  .map(l => (
                    <NavButton {...l} key={l.route} />
                  ))}
                {adminDropMenuLinks && adminDropMenuLinks.length > 0 && (
                  <AdminDropDownMenu links={adminDropMenuLinks} />
                )}
              </div>
            </div>
            <div className={"flex items-center hidden md:block"}>
              <span className={"pr-3 font-medium "}>{name}</span>
              <FontAwesomeIcon
                icon={faPowerOff}
                size="lg"
                onClick={logout}
                className="cursor-pointer logout-btn"
              />
            </div>
          </div>
          <div className="flex md:hidden">
            <HamburgerMenuIcon isOpen={isOpen} toggleOpen={toggleOpen} />
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref: any) => (
          <div className="md:hidden" id="mobile-menu">
            <div
              ref={ref}
              className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-right"
            >
              <span className={"px-4 py-5 font-medium"}>{name}</span>
              {links.map(l => (
                <NavButton {...l} key={l.title} />
              ))}

              <NavButtonViewLayout
                isSelected={false}
                onClick={logout}
                className="cursor-pointer"
              >
                LOGOUT
              </NavButtonViewLayout>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

const HamburgerMenuIcon: React.FC<{ toggleOpen(): void; isOpen: boolean }> = ({
  toggleOpen,
  isOpen
}) => {
  return (
    <button
      onClick={toggleOpen}
      type="button"
      className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-white border-solid border border-white hover:bg-gray-800 focus:outline-none"
      aria-controls="mobile-menu"
      aria-expanded="false"
    >
      <span className="sr-only">Open main menu</span>
      {!isOpen ? (
        <svg
          className="block h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      ) : (
        <svg
          className="block h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      )}
    </button>
  );
};

export default NavBar;
