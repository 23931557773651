import Input from "../Input/Input_Logic";
import QuickForm from "../QuickForm/QuickForm_View";
import phoneNumberValidator from "../../helpers/validators/phone-number-validator";
import phoneNumberVerificationValidator from "../../helpers/validators/phone-number-verification-validator";
import PinInput from "react-pin-input";
import React from "react";
import { useField, useFormikContext } from "formik";
import useAuth from "../../hooks/useAuth";

export type Step = "update" | "verify" | "unverifiedEmail";

type Props = {
  step: Step;
  phoneNumber: string;
  incorrectCode: boolean;
  existingPhoneNumber: boolean;
  invalidPhoneNumber: boolean;
  reset: () => void;
  updatePhoneNumber: (data: server.AddPhoneNumberRequestBody) => Promise<void>;
  verifyPhoneNumber: (
    data: server.VerifyPhoneNumberRequestBody
  ) => Promise<void>;
  signinFlow: boolean;
  recaptcha: boolean;
  sessionExpired: boolean;
};

const IdpAddPhoneNumberView: React.FC<Props> = ({ ...props }) => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const { user } = useAuth();
  React.useEffect(() => {
    if (props.invalidPhoneNumber) {
      setErrorMessage("Invalid phone number.");
    } else if (props.incorrectCode) {
      setErrorMessage("Code does not match. Try again.");
    } else if (props.existingPhoneNumber) {
      setErrorMessage("Phone number is already in use.");
    } else if (props.sessionExpired) {
      setErrorMessage("Session expired. Please login again to continue.");
    } else {
      setErrorMessage(null);
    }
  }, [
    props.invalidPhoneNumber,
    props.incorrectCode,
    props.existingPhoneNumber,
    props.sessionExpired
  ]);

  if (props.step === "update") {
    return (
      <div className="text-center px-3 pt-4 verify-number-w">
        <p className="text-base my-2t text-gray">
          Adding a phone number helps secure your account and allows us to keep
          you updated on the status of your loan.
          <br /> Please verify your number below.
        </p>
        <QuickForm<server.AddPhoneNumberRequestBody>
          key="update"
          initialValues={{
            phoneNumber: props.phoneNumber || "",
            isMfa: true
          }}
          validator={phoneNumberValidator}
          onSubmit={props.updatePhoneNumber}
          error={errorMessage}
          buttonTitle="Submit"
        >
          <Input
            readOnly={user?.phoneNumberVerified}
            name="phoneNumber"
            placeholder="Mobile Number (+61412 345 678)"
          />
          {!props.recaptcha && <div id="update-button"></div>}
        </QuickForm>
        {!props.recaptcha && <div id="update-button"></div>}
      </div>
    );
  }

  if (props.step === "unverifiedEmail") {
    return (
      <div className="text-center">
        <h2 className="title-font mb-4">Email Verification Required</h2>
        <p>
          Please verify your email before enabling Two-Factor Authentication
        </p>
      </div>
    );
  }

  return (
    <div className="text-center">
      <h2 className="title-font mb-4">Mobile Verification</h2>
      {!props.signinFlow && (
        <>
          <p className="text-base m-0 mb-2 text-gray">
            We have sent a 6-digit verification code to{" "}
            <span className="text-white">{props.phoneNumber}</span>.
          </p>
          <p className="mb-1 cursor-pointer text-purple-electric">
            <button onClick={props.reset}>Not the right mobile number?</button>
          </p>
        </>
      )}
      <QuickForm<server.VerifyPhoneNumberRequestBody>
        key="verify"
        initialValues={{
          code: "",
          isMfa: true
        }}
        validator={phoneNumberVerificationValidator}
        onSubmit={props.verifyPhoneNumber}
        error={errorMessage}
        buttonTitle="Verify Code"
      >
        <ReactInput />
        {/* <Input name="code" placeholder="Verification code" /> */}
      </QuickForm>
    </div>
  );
};

export default IdpAddPhoneNumberView;

const ReactInput: React.FC<any> = () => {
  const [value, setValue] = React.useState("");
  const { setFieldValue } = useFormikContext();

  const [field] = useField("code");

  const onChange = (v: string) => {
    setValue(v);
    setFieldValue("code", v);
  };
  return (
    <>
      <PinInput
        length={6}
        initialValue={value}
        onChange={onChange}
        type="numeric"
        inputMode="number"
        focus
        style={{ padding: "30px 0 10px", margin: "0 -30px" }}
        inputStyle={{
          padding: "5px",
          width: 56,
          height: 56,
          background: "#18171F",
          borderColor: "#382F3E",
          borderRadius: 12,
          margin: "0 5px"
        }}
        inputFocusStyle={{ borderColor: "#7A4FFF" }}
      />
      <Input {...field} hidden placeholder="Verification code" />
    </>
  );
};
