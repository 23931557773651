import {
  Input,
  PageLayout,
  QuickForm,
  SwitchView,
  Select,
  ToolTip
} from "../../components";
import InputView from "../../components/Input/Input_View";
import bankAccountValidator from "../../helpers/validators/bank-account-validator";
import useAuth from "../../hooks/useAuth";
import TwoFaModal from "../../components/TwoFaModal/TwoFaModal_Logic";
import identityValidator, {
  IdentityFormData
} from "../../helpers/validators/verify-identity-validator";
import { IDENTITY_ERRORS } from "../../helpers/general";
import { GENDER, getAvailableGenders } from "../../helpers/constants";

type Props = {
  updateBankAccount: (data: server.BankAccount) => Promise<void>;
  verifyIdentity: (data: server.VerifyIdentityRequestBody) => Promise<void>;
  editBankDetails: boolean;
  setEditBankDetails: () => void;
  profile: server.Profile | undefined;
  error: string | null;
  editProfile: boolean;
  setEditProfileDetails: () => void;
  verificationFailed: boolean;
  onSetState(state: string): void;
  state: string;
  setDlState(state: server.State): void;
  dlState: server.State;
  updateTwoFa: () => void;
  isTwoFaEnabled: boolean;
  showTwoFaModal: boolean;
  onTwoFaModalClose: () => void;
  updateTwoFaEnabled: (value: boolean) => void;
  verificationCountExceeded: boolean;
  gender: GENDER;
  setGender: (val: GENDER) => void;
};

export const ProfileView: React.FC<Props> = ({
  updateBankAccount,
  editBankDetails,
  editProfile,
  setEditProfileDetails,
  verifyIdentity,
  setEditBankDetails,
  profile,
  error,
  verificationFailed,
  onSetState,
  state,
  setDlState,
  dlState,
  isTwoFaEnabled,
  updateTwoFa,
  showTwoFaModal,
  onTwoFaModalClose,
  updateTwoFaEnabled,
  verificationCountExceeded,
  gender,
  setGender
}) => {
  const { user } = useAuth();
  const STATES: string[] = [
    "ACT",
    "NSW",
    "NT",
    "QLD",
    "SA",
    "TAS",
    "VIC",
    "WA"
  ];
  const availableGenders: string[] = getAvailableGenders();
  return (
    <PageLayout showHeader={true} loading={!user || !profile}>
      {user && profile && (
        <div className="md:grid grid-cols-3 mt-9">
          <div className="text-center">
            <div className="p-2 w-40 h-40 m-auto bg-purple-electric mt-1 text-white text-7xl uppercase rounded-full flex items-center justify-center text-center">
              {user.firstName.substr(0, 1)}
            </div>
            <p className="mt-5 mb-2 text-3xl text-purple-electric">
              {user.firstName} {user.lastName}
            </p>
            <p className="mb-2">{user.email}</p>
            {user.phoneNumber && (
              <p>
                {user.phoneNumber}{" "}
                {!user.phoneNumberVerified && (
                  <ToolTip
                    className="w-max"
                    children={<>Number not verified</>}
                  />
                )}
              </p>
            )}
            <SwitchView
              checked={isTwoFaEnabled}
              name="swEnableTwoFa"
              onChange={() => updateTwoFa()}
              label="Two-Factor Authentication"
              className="mt-5 mb-2 justify-center"
            />
          </div>

          <div className="col-span-2">
            <div className="bg-background-light shadow-2xl rounded-3xl p-12 mb-7">
              <h3 className="text-3xl mb-5 flex justify-between">
                Identity details
                {!user.idVerified && (
                  <span
                    className="text-purple-electric cursor-pointer text-sm"
                    onClick={() => setEditProfileDetails()}
                  >
                    {!editProfile ? "Edit" : "Cancel"}
                  </span>
                )}
              </h3>
              {!user.idVerified && editProfile ? (
                <QuickForm<IdentityFormData>
                  initialValues={{
                    dobDay: profile.Identity?.dob?.day || "",
                    dobMonth: profile.Identity?.dob?.month || "",
                    dobYear: profile.Identity?.dob?.year || "",
                    addressState: profile.Identity?.address.state || "NSW",
                    addressPostcode: profile.Identity?.address.postcode || "",
                    addressSuburb: profile.Identity?.address.suburb || "",
                    addressStreetName:
                      profile.Identity?.address.streetName || "",
                    addressStreetType:
                      profile.Identity?.address.streetType || "",
                    addressStreetNumber:
                      profile.Identity?.address.streetNumber || "",
                    addressFlatNumber:
                      profile.Identity?.address.flatNumber || "",
                    dlState: profile.Identity?.driversLicense.state || "NSW",
                    dlNumber: profile.Identity?.driversLicense.number || "",
                    dlFirstName:
                      profile.Identity?.driversLicense.firstName || "",
                    dlLastName: profile.Identity?.driversLicense.lastName || "",
                    dlMiddleName:
                      profile.Identity?.driversLicense.middleName || "",
                    gender: profile.Identity?.gender || GENDER.Male,
                    cardNumber: profile.Identity?.driversLicense.cardNumber || ""
                  }}
                  validator={identityValidator}
                  disabled={verificationCountExceeded}
                  onSubmit={data => {
                    return verifyIdentity({
                      dob: {
                        day: Number(data.dobDay),
                        month: Number(data.dobMonth),
                        year: Number(data.dobYear)
                      },
                      address: {
                        state: data.addressState,
                        postcode: data.addressPostcode,
                        suburb: data.addressSuburb,
                        streetName: data.addressStreetName,
                        streetType: data.addressStreetType,
                        streetNumber: data.addressStreetNumber,
                        flatNumber: data.addressFlatNumber
                      },
                      driversLicense: {
                        state: data.dlState,
                        number: data.dlNumber,
                        firstName: data.dlFirstName,
                        lastName: data.dlLastName,
                        middleName: data.dlMiddleName,
                        cardNumber: data.cardNumber
                      },
                      gender: gender
                    });
                  }}
                  error={
                    verificationCountExceeded
                      ? IDENTITY_ERRORS.VerificationCountExceeded
                      : verificationFailed
                      ? IDENTITY_ERRORS.UnknownError
                      : null
                  }
                  buttonTitle="Save Details"
                >
                  <div className="block">
                    <h5 className="text-base text-purple-electric mb-3">
                      Drivers license
                    </h5>
                    <div className="flex-grow">
                      <div className="flex gap-4 justify-between">
                        <Input
                          name="dlNumber"
                          type="text"
                          placeholder="Licence No"
                          maxLength={11}
                          minLength={5}
                          outerDivClassName="w-full"
                        />
                        <Select
                          name="dlState"
                          value={dlState}
                          className="bg-gray-200 w-min h-14 mt-5"
                          onChange={e => {
                            setDlState(e.target.value);
                          }}
                        >
                          {STATES.sort((a, b) => {
                            return a > b ? 1 : -1;
                          }).map(sa => (
                            <option value={sa} key={sa}>
                              {sa}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="grid grid-cols-3 gap-4 w-full">
                        <Input
                          name="dlFirstName"
                          type="text"
                          placeholder="First Name"
                          maxLength={30}
                        />
                        <Input
                          name="dlMiddleName"
                          type="text"
                          placeholder="Middle Name(s)"
                          maxLength={30}
                        />
                        <Input
                          name="dlLastName"
                          type="text"
                          placeholder="Last Name"
                          maxLength={30}
                        />
                      </div>
                      <div className="block mt-10">
                        <div className="text-left">Gender</div>
                        <div className="flex-grow">
                          <Select
                            name="gender"
                            value={gender}
                            className="bg-gray-200 h-14 mt-5"
                            onChange={e => {
                              setGender(e.target.value);
                            }}
                          >
                            {availableGenders.map((gender, index) => (
                              <option value={gender} key={index}>
                                {gender}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block mt-10">
                    <h5 className="text-base text-purple-electric mb-3">
                      Date of Birth
                    </h5>
                    <div className="flex-grow">
                      <div className="flex gap-4">
                        <Input
                          name="dobDay"
                          type="text"
                          placeholder="DD"
                          maxLength={2}
                          minLength={2}
                        />
                        <Input
                          name="dobMonth"
                          type="text"
                          placeholder="MM"
                          maxLength={2}
                          minLength={2}
                        />
                        <Input
                          name="dobYear"
                          type="text"
                          placeholder="YYYY"
                          maxLength={4}
                          minLength={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="block mt-10">
                    <h5 className="text-base text-purple-electric mb-3">
                      Address
                    </h5>
                    <div className="flex-grow w-full">
                      <div className="grid grid-cols-2 gap-4">
                        <Input
                          name="addressFlatNumber"
                          type="text"
                          placeholder="Flat No"
                          maxLength={30}
                        />
                        <Input
                          name="addressStreetNumber"
                          type="text"
                          placeholder="Street No"
                          maxLength={30}
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <Input
                          name="addressStreetName"
                          type="text"
                          placeholder="Street Name"
                          maxLength={30}
                        />
                        <Input
                          name="addressStreetType"
                          type="text"
                          placeholder="Street Type"
                          maxLength={30}
                        />
                      </div>
                      <Input
                        name="addressSuburb"
                        type="text"
                        placeholder="Suburb"
                        maxLength={30}
                      />
                      <div className="flex gap-4 justify-between">
                        <Input
                          name="addressPostcode"
                          type="text"
                          placeholder="Postcode"
                          maxLength={4}
                          outerDivClassName="w-full"
                        />
                        <Select
                          name="addressState"
                          value={state}
                          className="bg-gray-200 rounded-2xl w-min h-14 mt-5"
                          onChange={e => {
                            onSetState(e.target.value);
                          }}
                        >
                          {STATES.sort((a, b) => {
                            return a > b ? 1 : -1;
                          }).map(sa => (
                            <option value={sa} key={sa}>
                              {sa}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </QuickForm>
              ) : (
                <>
                  <h5 className="text-base text-purple-electric mb-3">
                    Drivers license
                  </h5>
                  <div className="green-id-address grid grid-cols-4 gap-2 mb-8">
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">State</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.driversLicense.state}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">
                        License number
                      </label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.driversLicense.number}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">DOB</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={`${profile.Identity?.dob.day || "--"} / ${
                          profile.Identity?.dob.month || "--"
                        } / ${profile.Identity?.dob.year || "--"}`}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">Gender</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={`${profile.Identity?.gender || "--"}`}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">
                        Card number
                      </label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.driversLicense.cardNumber}
                      />
                    </div>
                  </div>

                  <h5 className="text-base text-purple-electric mb-3">
                    Address
                  </h5>
                  <div className="green-id-address grid grid-cols-3 gap-2 mb-5">
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">Flat number</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.address.flatNumber}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">Street name</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.address.streetName}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">Street type</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.address.streetType}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">Street number</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.address.streetNumber}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">State</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.address.state}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">Suburb</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.address.suburb}
                      />
                    </div>
                    <div className="custom-input-group">
                      <label className="text-sm text-gray">Postcode</label>
                      <InputView
                        readOnly
                        placeholder="--"
                        value={profile.Identity?.address.postcode}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="bg-background-light shadow-2xl rounded-3xl p-12">
              <h3 className="text-3xl mb-5 flex justify-between">
                Bank details{" "}
                <span
                  className="text-purple-electric cursor-pointer text-sm"
                  onClick={() => setEditBankDetails()}
                >
                  {!editBankDetails ? "Edit" : "Cancel"}
                </span>
              </h3>
              <QuickForm<server.BankAccount>
                initialValues={{
                  bsbNumber: profile.bankAccount.bsbNumber,
                  accountName: profile.bankAccount.accountName,
                  accountNumber: profile.bankAccount.accountNumber
                }}
                validator={bankAccountValidator}
                onSubmit={updateBankAccount}
                error={error}
                buttonTitle="Save Details"
                hideButton={!editBankDetails}
              >
                <div className="custom-input-group mb-5">
                  <label className="text-sm text-gray">Account name</label>
                  <Input
                    name="accountName"
                    type="text"
                    autoComplete="off"
                    placeholder="--"
                    maxLength={50}
                    readOnly={!editBankDetails}
                  />
                </div>
                <div className="custom-input-group mb-5">
                  <label className="text-sm text-gray">BSB</label>
                  <Input
                    name="bsbNumber"
                    readOnly={!editBankDetails}
                    autoComplete="off"
                    type="text"
                    placeholder="--"
                    maxLength={7}
                  />
                </div>
                <div className="custom-input-group mb-5">
                  <label className="text-sm text-gray">Account number</label>
                  <Input
                    name="accountNumber"
                    type="text"
                    autoComplete="off"
                    placeholder="--"
                    maxLength={16}
                    minLength={6}
                    readOnly={!editBankDetails}
                  />
                </div>
              </QuickForm>
              <TwoFaModal
                isTwoFaEnabled={isTwoFaEnabled}
                show={showTwoFaModal}
                onClose={onTwoFaModalClose}
                updateTwoFaEnabled={updateTwoFaEnabled}
              />
            </div>
          </div>
        </div>
      )}
    </PageLayout>
  );
};
