import React from "react";

type TableProps = {
  className?: string;
};

const Table: React.FC<TableProps> = props => {
  return (
    <div className="col-span-6">
      <table className={`w-full ${props.className}`}>{props.children}</table>
    </div>
  );
};

const TableHead: React.FC = props => {
  return (
    <thead className="text-white text-l ">
      <tr>{props.children}</tr>
    </thead>
  );
};

const TableRow: React.FC<{
  className?: string;
}> = props => {
  return (
    <tr
      className={`group hover:bg-purple-electric hover:text-white hover:bg-opacity-20 ${
        props.className || ""
      }`}
    >
      {props.children}
    </tr>
  );
};

export { Table, TableHead, TableRow };
