import { useState } from "react";
import useSWR from "swr";
import useApi from "../../hooks/useApi";
import AccountView from "./Account_View";
import {
  DepositModal,
  WithdrawModal,
  LoanDetailsModal,
  CreateLoanModal,
  DepositCollateralModal,
  UserLoanDetailsModal,
  OnboardingModal
} from "../../components";
import useAuth from "../../hooks/useAuth";
import useSystemState from "../../hooks/useSystemState";
import DepositInfoModal from "../../components/DepositInfoModal/DepositInfoModal_View";

const Account: React.FC = () => {
  const api = useApi();
  const { user } = useAuth();
  const system = useSystemState();
  const [depositAsset, setDepositAsset] = useState<
    server.AssetId | undefined
  >();
  const [withdrawAsset, setWithdrawAsset] = useState<server.Loan>();
  const [showCreateLoanModal, setShowCreateLoanModal] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [
    loanCollateralModalLoan,
    setloanCollateralModalLoan
  ] = useState<server.Loan>();
  const [displayEmptyBalances, setDisplayEmptyBalances] = useState(true);
  const [displayInActiveLoans, setDisplayInActiveLoans] = useState(true);
  const [
    loanDetailsModalLoanId,
    setLoanDetailsModalLoanId
  ] = useState<number>();
  const [
    userLoanDetailsModalLoan,
    setUserLoanDetailsModalLoan
  ] = useState<server.Loan>();

  const [addressCopied, setAddressCopied] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  const { data: balances } = useSWR("balance", () => api.getBalance());
  const { data: loans, revalidate: refetchLoans } = useSWR("loans", () =>
    api.searchLoans({})
  );
  const [currentLoanId, setCurrentLoanId] = useState<number | null>(null);

  const [depositDataStatus, setDepositDataStatus] = useState<boolean>(false);

  const [modalData, setModalData] = useState({
    title:"",
    text:""
  });

  const [error, setError] = useState<boolean>(false);


  const openCreateLoanModal = async (loanId?: number) => {
    if (loanId) {
      setCurrentLoanId(loanId);
    }
    setShowCreateLoanModal(true);
  };
  const fetchLoanContract = async (loan: server.Loan) => {
    const contractUrl = await api.getLoanContract(loan.id);
    window.open(contractUrl, "_blank");
  };
  const KYC = Boolean(
    user &&
      user.isEmailVerified &&
      user.idVerified &&
      user.hasBankAccount &&
      user.phoneNumberVerified
  );
  const fetchAssetPriceOfLoan = (loanId: number) => {
    try {
      if (loans && balances) {
        const loan = loans.find(loan => loan.id === loanId);
        const balance = balances.assets.find(
          asset => asset.assetId === loan?.assetId
        );
        return balance?.assetPriceAUD;
      }
      return;
    } catch (error) {
      return;
    }
  };

  const handleOnClickToCopyAddress = (address:string) => {
    setAddressCopied(true)
    navigator.clipboard.writeText(address);
    setTimeout(()=>{
      setAddressCopied(false)
    },2000);
  }
  const getSelectedAddressBasedOnAsset = (value:server.AssetId) => {
    let address;
    switch(value){
      case "AVAX":
        address = `${process.env.REACT_APP_TRUST_WALLET_AVALANCHE_ADDRESS}`;
        break;
        case "BTC":
        address = `${process.env.REACT_APP_TRUST_WALLET_BITCOIN_ADDRESS}`;
        break;
        case "COMP":
        address = `${process.env.REACT_APP_TRUST_WALLET_COMPOUND_ADDRESS}`;
        break;
        case "DAI":
        address = `${process.env.REACT_APP_TRUST_WALLET_DAI_ADDRESS}`;
        break;
        case "ETH":
        address = `${process.env.REACT_APP_TRUST_WALLET_ETHER_ADDRESS}`;
        break;
        case "LINK":
        address = `${process.env.REACT_APP_TRUST_WALLET_CHAINLINK_ADDRESS}`;
        break;
        case "UNI":
        address = `${process.env.REACT_APP_TRUST_WALLET_UNISWAP_ADDRESS}`;
        break;
        case "USDC":
        address = `${process.env.REACT_APP_TRUST_WALLET_USD_ADDRESS}`;
        break;
        case "USDT":
        address = `${process.env.REACT_APP_TRUST_WALLET_TETHER_ADDRESS}`;
        break;
    }
    return address;
  }

  const updateLoanUsingLoanId = (
    id: number,
    data: server.RequestTransactionHash
  ) => {
    api.updateTransactionHash( id , data).then(()=>{
      setModalData({
        title:"Thank you!",
        text:"Our Team will review your transaction and will update you within 24 hours."
      })
    }).catch(() => {
      setModalData({
        title:"Sorry, Something went wrong",
        text:"You can try again later in the future."
      })
    });
  };
  const handleSubmit = async () => {
      const data = await api.getLoan(loanCollateralModalLoan?.id as number);
      if (data) {
        updateLoanUsingLoanId(data.id,{
          submit:true,
          hash:value
        });
      }
      setloanCollateralModalLoan(undefined);
      setDepositDataStatus(true); 
      setValue('');
      setError(false);
  }
  
  return (
    <AccountView
      balances={balances}
      loans={loans}
      displayInActiveLoans={displayInActiveLoans}
      displayEmptyBalances={displayEmptyBalances}
      openDepositModal={setDepositAsset}
      openWithdrawModal={setWithdrawAsset}
      openRequestLoanModal={openCreateLoanModal}
      openLoanDetailsModal={setLoanDetailsModalLoanId}
      openUserLoanDetailsModal={setUserLoanDetailsModalLoan}
      openDepositCollateralModal={setloanCollateralModalLoan}
      toggleDisplayEmptyBalances={() =>
        setDisplayEmptyBalances(!displayEmptyBalances)
      }
      toggleDisplayInActiveLoans={() =>
        setDisplayInActiveLoans(!displayInActiveLoans)
      }
      fetchLoanContract={fetchLoanContract}
      openOnboardingModal={setShowOnboardingModal}
      KYC={KYC}
      pendingCollateralTimeLimit={system?.pendingCollateralTimeLimit || null}
    >
      {showCreateLoanModal && (
        <CreateLoanModal
          show={showCreateLoanModal}
          onClose={async () => {
            setShowCreateLoanModal(false);
            setCurrentLoanId(null);
            await refetchLoans();
          }}
          currentLoanId={currentLoanId}
        />
      )}
      <OnboardingModal
        user={user!}
        show={showOnboardingModal}
        onClose={() => setShowOnboardingModal(!showOnboardingModal)}
      />
      <DepositModal
        assetId={depositAsset}
        onClose={() => setDepositAsset(undefined)}
      />
      <WithdrawModal
        loan={withdrawAsset}
        refetchLoans={refetchLoans}
        onClose={() => setWithdrawAsset(undefined)}
      />
      {loanDetailsModalLoanId && (
        <LoanDetailsModal
          loanId={loanDetailsModalLoanId}
          balanceForLoan={fetchAssetPriceOfLoan(loanDetailsModalLoanId)}
          onClose={() => setLoanDetailsModalLoanId(undefined)}
          refetchLoans={refetchLoans}
        />
      )}
      <UserLoanDetailsModal
        loan={userLoanDetailsModalLoan}
        onClose={() => setUserLoanDetailsModalLoan(undefined)}
        refetchLoans={refetchLoans}
        onLoanUpdate={setUserLoanDetailsModalLoan}
      />
      <DepositCollateralModal
        loan={loanCollateralModalLoan}
        onClose={() => setloanCollateralModalLoan(undefined)}
        setDepositDataStatus={setDepositDataStatus}
        addressCopied={addressCopied} 
        handleOnClickToCopyAddress={handleOnClickToCopyAddress}
        handleSubmit={handleSubmit}
        setValue={setValue}
        value={value}
        error={error}
        getSelectedAddressBasedOnAsset={getSelectedAddressBasedOnAsset}
      />
      <DepositInfoModal
      show={depositDataStatus}
      modalData={modalData}
      onClose={()=> setDepositDataStatus(false)}
      />
    </AccountView>
  );
};

export default Account;
