const bankAccountValidator = ({
  bsbNumber,
  accountName,
  accountNumber
}: server.BankAccount) => {
  const errors: {
    bsbNumber?: string;
    accountName?: string;
    accountNumber?: string;
  } = {};

  const regexBSB = new RegExp("^[0-9]{3}-?[0-9]{3}$");
  const regexAccountNumber = new RegExp("^[0-9]{6,16}$");

  if (!bsbNumber.match(regexBSB)) errors.bsbNumber = "Please enter a valid BSB";
  if (!accountNumber.match(regexAccountNumber))
    errors.accountNumber = "Please enter a valid account number";
  if (!accountName) errors.accountName = "Please enter an account name";
  if (accountName && accountName.length < 4)
    errors.accountName = "Account name must have at least 4 characters";
  return errors;
};

export default bankAccountValidator;
