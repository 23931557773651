export type IdentityFormData = {
  dobDay: string;
  dobMonth: string;
  dobYear: string;
  addressState: string;
  addressPostcode: string;
  addressSuburb: string;
  addressStreetName: string;
  addressStreetType: string;
  addressStreetNumber: string;
  addressFlatNumber: string | undefined;
  dlNumber: string;
  dlState: server.State;
  dlFirstName: string;
  dlLastName: string;
  dlMiddleName: string | undefined;
  gender: server.GENDER;
  cardNumber: string;
};
const identityValidator = ({
  dobDay,
  dobMonth,
  dobYear,
  addressPostcode,
  addressSuburb,
  addressStreetName,
  addressStreetType,
  addressStreetNumber,
  dlNumber,
  dlFirstName,
  dlLastName,
  cardNumber
}: IdentityFormData) => {
  const errors: Partial<{ [key in keyof IdentityFormData]: string }> = {};

  const regexPostcode = new RegExp(/^\d{4}$/);
  const regexDriversLicense = new RegExp(/^(\w{4,11})$/);
  const regexCardnumber = new RegExp(/^[A-Za-z0-9]{6,10}$/);

  if (!dobDay) errors.dobDay = "Required";
  if (!dobMonth) errors.dobMonth = "Required";
  if (!dobYear) errors.dobYear = "Required";
  if (isNaN(Number(dobYear)) || Number(dobYear) < 1900)
    errors.dobYear = "* invalid";
  if (Number(dobYear) > new Date().getFullYear() - 18)
    errors.dobYear = "* under 18";
  if (!dobYear) errors.dobYear = "Required";
  if (!dobMonth) errors.dobMonth = "Required";
  if (isNaN(Number(dobMonth)) || Number(dobMonth) > 12)
    errors.dobMonth = "* invalid";
  if (!dobDay) errors.dobDay = "Required";
  if (isNaN(Number(dobDay)) || Number(dobDay) > 31) errors.dobDay = "* invalid";

  if (!addressPostcode) errors.addressPostcode = "Required";
  if (!addressPostcode.match(regexPostcode))
    errors.addressPostcode = "Invalid postcode";
  if (!addressSuburb) errors.addressSuburb = "Please enter your suburb";
  if (!addressStreetName)
    errors.addressStreetName = "Please enter your street name";
  if (!addressStreetType)
    errors.addressStreetType = "Please enter your street type";
  if (!addressStreetNumber)
    errors.addressStreetNumber = "Please enter your street number";

  if (!dlNumber.match(regexDriversLicense))
    errors.dlNumber = "Please enter a valid driving license number";

  if (!dlFirstName) errors.dlFirstName = "Please enter your first name";
  if (!dlLastName) errors.dlLastName = "Please enter your last name";
  if(!cardNumber) errors.cardNumber = "Please enter your card number";
  if(!cardNumber.match(regexCardnumber)) errors.cardNumber = "Invalid card number"

  return errors;
};

export default identityValidator;
