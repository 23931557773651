import validator from "validator";

export type ForgotPasswordRequestBody = {
  email: string;
};
const forgotValidator = ({ email }: ForgotPasswordRequestBody) => {
  const errors: {
    email?: string;
  } = {};
  if (!validator.isEmail(email)) errors.email = "Please enter a valid email";
  return errors;
};

export default forgotValidator;
