import React from "react";

export const TopUpIcon: React.FC = props => {
  return (
    <svg
      {...props}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M17.5259 0.889601V1.77707H2.62868C2.3961 1.77707 2.17305 1.87079 2.0086 2.03763C1.84414 2.20446 1.75175 2.43073 1.75175 2.66667C1.75175 2.90261 1.84414 3.12888 2.0086 3.29571C2.17305 3.46254 2.3961 3.55627 2.62868 3.55627H16.649C16.8812 3.55627 17.1039 3.6497 17.2683 3.81607C17.4327 3.98244 17.5254 4.20817 17.5259 4.44374V14.2229C17.5259 14.4565 17.4805 14.6877 17.3924 14.9035C17.3042 15.1192 17.175 15.3152 17.0121 15.4803C16.8492 15.6453 16.6558 15.7762 16.4431 15.8653C16.2303 15.9545 16.0023 16.0003 15.7721 16H9.63988V10.1461L10.7713 11.296C10.9358 11.4629 11.159 11.5567 11.3916 11.5567C11.6243 11.5567 11.8475 11.4629 12.012 11.296C12.1765 11.1291 12.269 10.9027 12.269 10.6667C12.269 10.4306 12.1765 10.2042 12.012 10.0373L9.39384 7.37067C9.3124 7.28797 9.21569 7.22237 9.10925 7.17762C9.0028 7.13286 8.8887 7.10982 8.77347 7.10982C8.65824 7.10982 8.54415 7.13286 8.4377 7.17762C8.33125 7.22237 8.23454 7.28797 8.1531 7.37067L5.52443 10.0373C5.36735 10.2055 5.28113 10.4292 5.2841 10.6608C5.28708 10.8925 5.37901 11.1138 5.54035 11.2777C5.70169 11.4417 5.91971 11.5353 6.14805 11.5387C6.37639 11.5422 6.59703 11.4551 6.76306 11.296L7.89655 10.1461V16H1.75385C1.52363 16.0003 1.29562 15.9545 1.08284 15.8653C0.870067 15.7762 0.676707 15.6453 0.513819 15.4803C0.350931 15.3152 0.22171 15.1192 0.133545 14.9035C0.0453799 14.6877 -1.65891e-07 14.4565 0 14.2229V1.77707C-1.65891e-07 1.54352 0.0453799 1.31226 0.133545 1.09652C0.22171 0.880778 0.350931 0.684781 0.513819 0.519737C0.676707 0.354693 0.870067 0.22384 1.08284 0.13466C1.29562 0.0454793 1.52363 -0.000279088 1.75385 1.28063e-06H16.649C16.7641 1.28063e-06 16.8782 0.0230114 16.9846 0.067718C17.091 0.112425 17.1876 0.177952 17.2691 0.260559C17.3505 0.343166 17.4151 0.441235 17.4592 0.549166C17.5032 0.657097 17.5259 0.772778 17.5259 0.889601Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="17.5238" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
