import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import useGlobalError from "../../hooks/useGlobalError";
import LoanDetailsModalView from "./LoanDetailsModal_View";

type Props = {
  loanId: number | undefined;
  onClose(): void;
  balanceForLoan: number | undefined;
  refetchLoans: () => Promise<boolean>;
};

const LoanDetailsModal: React.FC<Props> = props => {
  const api = useApi();
  const { setGlobalError } = useGlobalError();

  const [loan, setLoan] = useState<server.Loan>();
  const [collateralLoanAmount, setCollateralLoanAmount] = useState<number>();
  const [remainingCollateral, setRemainingCollateral] = useState<number>();
  const [remainingLoanAmount, setRemainingLoanAmount] = useState<number>();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.loanId) {
      api.getLoan(props.loanId).then(setLoan).catch(setGlobalError);
    }
  }, [props.loanId, api, setGlobalError]);

  const getRepayDetails = async (collateralLoanAmount: number) => {
    try {
      if (loan) {
        if (
          collateralLoanAmount <= 0 ||
          collateralLoanAmount > loan.outstandingDebt
        ) {
          setError("Collateral loan amount is incorrect");
          setRemainingCollateral(undefined);
          setRemainingLoanAmount(undefined);
          return;
        }

        const details = await api.getCollateralRepayDetails({
          loanId: +loan.id,
          repayLoanAmountInAud: collateralLoanAmount
        });
        if (details.transactionInProgress)
          setError("Wait for current transaction to complete.");
        else setError(undefined);
        setRemainingCollateral(details.remainingCollateral);
        setRemainingLoanAmount(details.remainingLoanAmount);
      }
    } catch (error) {
      setRemainingCollateral(undefined);
      setRemainingLoanAmount(undefined);
    }
  };

  const repay = async () => {
    setLoading(true);
    try {
      if (loan && collateralLoanAmount && !error) {
        const { success } = await api.repayWithCollateral({
          loanId: +loan.id,
          repayLoanAmountInAud: collateralLoanAmount
        });
        setSuccess(success);
        setLoading(false);
        props.refetchLoans();
      }
    } catch (error) {
      setLoading(false);
      setSuccess(false);
      setError("Error while repaying the loan with collateral");
    }
  };

  const closeSuccessModal = () => {
    setSuccess(false);
    props.onClose();
    props.refetchLoans();
  };

  return (
    <>
      <LoanDetailsModalView
        show={!!props.loanId}
        onClose={props.onClose}
        loan={loan}
        balanceInAud={props.balanceForLoan}
        getRepayDetails={getRepayDetails}
        repay={repay}
        setCollateralLoanAmount={setCollateralLoanAmount}
        collateralLoanAmount={collateralLoanAmount}
        setError={setError}
        error={error}
        remainingCollateral={remainingCollateral}
        remainingLoanAmount={remainingLoanAmount}
        success={success}
        closeSuccessModal={closeSuccessModal}
        loading={loading}
      />
    </>
  );
};

export default LoanDetailsModal;
