type Event<T> = {
  target: {
    value: T;
  };
};

type Props<Value> = Omit<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >,
  "value" | "onChange"
> & {
  value: Value;
  onChange(event: Event<Value>): void;
};

const Select = <Value extends string>(props: Props<Value>) => {
  const { value, className, onChange, ...restOfProps } = props;
  return (
    <select
      className={`w-full mt-3 p-1 px-3 text-sm bg-background-light border-2 border-solid border-other-gray rounded-xl outline-none focus:border-purple-electric ${className}`}
      value={value as Value}
      onChange={e => onChange((e as unknown) as Event<Value>)}
      {...restOfProps}
    />
  );
};

export default Select;
