import { DebounceInput } from "react-debounce-input";
import { Button, Modal, ToolTip } from "..";
import { LIQUIDATE_TYPE } from "../../helpers/loans-type";

type LiquidateModalProps = {
  modalTitle: LIQUIDATE_TYPE;
  setShowLiquidateModal(showLiquidateModal: boolean): void;
  showLiquidateModal: boolean;
  assetPrice: number;
  lvr: number;
  onClick(): void;
  setLvr(lvr: number | null): void;
  selectedLoan: server.Loan;
  onClose(): void;
  newCollateral: number;
  newLvr: number;
  newLoanAmount: number;
  percentageCrypto: number | undefined;
  onChangeOfCryptoPercentInbox(
    event: React.ChangeEvent<HTMLInputElement>
  ): void;
  error: string | undefined;
  isDisabled: boolean;
  originalCollateral: number;
  showLoader: boolean;
};

const LiquidateModalView: React.FC<LiquidateModalProps> = props => {
  return (
    <Modal
      title={props.modalTitle}
      show={props.showLiquidateModal}
      onClose={props.onClose}
    >
      <div className="w-100 grid grid-cols-2 rounded-xl mt-2 gap-1.5">
        <div className="flex justify-start font-normal text-base">
          Outstanding Debt (AUD)
        </div>
        <div>
          <div className="flex justify-end font-medium text-base">
            {props.newLoanAmount.toFixed(2)}
          </div>
        </div>

        <div className="flex justify-start font-normal text-base">
          Asset Price (AUD)
        </div>
        <div>
          <div className="flex justify-end font-medium text-base">
            {props.assetPrice}
          </div>
        </div>

        <div className="flex justify-start font-normal text-base">
          Collateral ({props.selectedLoan.assetId})
        </div>
        <div>
          <div className="flex justify-end font-medium text-base">
            {props.originalCollateral}
          </div>
        </div>

        <div className="flex justify-start font-normal text-base">
          Remaining Collateral
        </div>
        <div>
          <div className="flex justify-end font-medium text-base">
            {props.newCollateral.toFixed(6)}
          </div>
        </div>

        <div className="flex justify-start font-normal text-base">LVR</div>
        <div>
          <div className="flex justify-end font-medium text-base">
            {props.newLvr.toFixed(2)} %
          </div>
        </div>

        {props.modalTitle === LIQUIDATE_TYPE.PARTIAL && (
          <div className="grid grid-cols-1 gap-2 mt-2">
            <span className="text-base text-gray flex items-start">
              Liquidation (%) &nbsp;
              <ToolTip
                value={`Please enter the valid percentage of crypto you want to liquidate`}
              />
            </span>
            <div className="w-full">
              <DebounceInput
                value={props.percentageCrypto}
                type="number"
                onChange={event => {
                  props.onChangeOfCryptoPercentInbox(event);
                }}
                debounceTimeout={500}
                className="pp-3 margin0 text-sm h-14 w-full  mt-5 p-1 px-4 bg-background-light border border-solid border-other-gray rounded-xl outline-none focus:border-purple-electric"
              />
              {props.error && (
                <div className="w-full bg-purple-electric text-xs p-1 my-1 rounded-xl text-center mt-2 text-white">
                  {props.error}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={"py-2 pr-4 mt-5 flex justify-between items-center w-1/3"}>
        <Button
          title="Liquidate"
          className="px-7 min-width-btn"
          type="submit"
          loading={props.showLoader}
          disabled={props.isDisabled}
          onClick={props.onClick}
        />
      </div>
    </Modal>
  );
};

export default LiquidateModalView;
