import useLoanDetailsQueryString from "../../hooks/useLoanDetailsQueryString";
import useCollateralCalculatorState from "../../hooks/useCollateralCalculatorState";
import DepositModalView from "./DepositModal_View";

type Props = {
  assetId: server.AssetId | undefined;
  onClose: () => void;
};

const DepositAddressModal: React.FC<Props> = props => {
  const loanDeailsQueryString = useLoanDetailsQueryString();

  const collateralCalculatorState = useCollateralCalculatorState(
    loanDeailsQueryString.loanAmount,
    loanDeailsQueryString.collateralToDebtRatio
  );

  const asset = collateralCalculatorState.assetPrices?.find(
    ap => ap.assetId === props.assetId
  );

  return (
    <DepositModalView
      asset={asset}
      onClose={props.onClose}
      {...collateralCalculatorState}
    />
  );
};

export default DepositAddressModal;
