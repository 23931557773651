import useAuth from "../../hooks/useAuth";
import useApi from "../../hooks/useApi";
import { useState } from "react";
import useGlobalError from "../../hooks/useGlobalError";
import VerifyIdentityView from "./VerifyIdentity_View";
import { GENDER } from "../../helpers/constants";
import { BAD_REQUEST_CODE } from "../../types/errors";

type Props = {};

const VerifyIdentity: React.FC<Props> = () => {
  const api = useApi();
  const { user, setUser } = useAuth();
  const { setGlobalError } = useGlobalError();
  const [state, onSetState] = useState<string>("NSW");
  const [dlState, setDlState] = useState<server.State>("NSW");
  const [gender, setGender] = useState<GENDER>(GENDER.Male);
  const [failedIdentification, setFailedIdentification] = useState(false);
  const [verificationCountExceeded, setVerificationCountExceeded] = useState(
    false
  );
  const addIdentity = async (body: server.VerifyIdentityRequestBody) => {
    try {
      const updatedUser = await api.verifyIdentity(body);
      setUser({ ...user, ...updatedUser });
      if (!updatedUser.idVerified) {
        setFailedIdentification(true);
      }
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.code === BAD_REQUEST_CODE.VerificationCountExceed) {
        return setVerificationCountExceeded(true);
      } else if (error.statusCode === 400) {
        return setFailedIdentification(true);
      }

      setGlobalError(error);
    }
  };

  return (
    <VerifyIdentityView
      verifyIdentity={addIdentity}
      onSetState={onSetState}
      state={state}
      setDlState={setDlState}
      dlState={dlState}
      gender={gender}
      setGender={setGender}
      failedIdentification={failedIdentification}
      verificationCountExceeded={verificationCountExceeded}
    />
  );
};

export default VerifyIdentity;
