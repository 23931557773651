import Input from "../../components/Input/Input_Logic";
import { AuthPageLayout, Button, QuickForm } from "../../components";
import validator from "../../helpers/validators/resetPassword-validator";
import { ResetValidatorPayload } from "../../helpers/validators/resetPassword-validator";
import { useHistory } from "react-router";

type Props = {
  submitHandler: (data: ResetValidatorPayload) => Promise<void>;
  error: string | null;
  isSubmit: boolean;
};

const ResetPasswordView: React.FC<Props> = props => {
  const history = useHistory();
  return (
    <AuthPageLayout>
      {props.isSubmit ? (
        <div className="mt-5 w-full text-lg p-1 my-1 rounded-xl text-white text-center">
          <span>Password reset successful.</span>
          <br />
          <Button
            title={"Go to Login"}
            onClick={() => history.push("/login")}
            className="mt-8 px-7 min-width-btn"
          />
        </div>
      ) : (
        <QuickForm<ResetValidatorPayload>
          initialValues={{ password: "", confirmPassword: "" }}
          validator={validator}
          onSubmit={props.submitHandler}
          error={props.error}
          buttonTitle="Reset"
        >
          <h2 className="text-3xl mb-4 font-bold">Reset password</h2>
          <div className="mt-5">
            <Input name="password" placeholder="New password" type="password" />
          </div>
          <div className="mb-1">
            <Input
              name="confirmPassword"
              placeholder="Confirm password"
              type="password"
            />
          </div>
        </QuickForm>
      )}
    </AuthPageLayout>
  );
};

export default ResetPasswordView;
