import * as React from "react";
import { useHistory } from "react-router";
import { SignupValidatorPayload } from "../../helpers/validators/signup-validator";
import useApi from "../../hooks/useApi";
import useGlobalError from "../../hooks/useGlobalError";
import useLoanDetailsQueryString from "../../hooks/useLoanDetailsQueryString";
import SignupView from "./Signup_View";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../../services/identity-auth.service";

const Signup: React.FC = () => {
  const api = useApi();
  const history = useHistory();
  const loanDetailsQueryString = useLoanDetailsQueryString();
  const { setGlobalError } = useGlobalError();
  const [error, setError] = React.useState<string | null>(null);

  const signup = async (data: SignupValidatorPayload) => {
    try {
      await api.signup({
        email: data.email.toLowerCase(),
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName
      });
      await signInWithEmailAndPassword(auth, data.email, data.password);
      // @ts-ignore
      window.gtag("event", "sign_up", {
        method: "Website"
      });
      history.push(
        `/account${
          loanDetailsQueryString.collateralToDebtRatio &&
          loanDetailsQueryString.loanAmount &&
          loanDetailsQueryString.collateral
            ? `?loanAmount=${loanDetailsQueryString.loanAmount}&collateralToDebtRatio=${loanDetailsQueryString.collateralToDebtRatio}&collateral=${loanDetailsQueryString.collateral}`
            : ""
        }`
      );
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.statusCode) {
        if (error.statusCode === 400) {
          return setError(error.message);
        }
      }

      setGlobalError(error);
    }
  };

  const navigateToLogin = () => {
    history.push("/login");
  };

  return (
    <SignupView
      signup={signup}
      navigateToLogin={navigateToLogin}
      error={error}
    />
  );
};

export default Signup;
