import { useState } from "react";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import AddBankAccountView from "./AddBankAccount_View";

type Props = {};

const AddBankAccount: React.FC<Props> = () => {
  const api = useApi();
  const { user, setUser } = useAuth();
  const [error, setError] = useState<string | null>(null);

  const addBankAccount = async (values: server.BankAccount) => {
    try {
      setError(null);

      const updatedUser = await api.addBankAccount({
        ...values,
        bsbNumber: values.bsbNumber.replace("-", "")
      });
      setUser({ ...user, ...updatedUser });
    } catch (err) {
      setError((err as server.ErrorResponse).message);
    }
  };

  return <AddBankAccountView error={error} addBankAccount={addBankAccount} />;
};

export default AddBankAccount;
