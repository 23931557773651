import BN from "bignumber.js";
import { numberToFormattedAUD } from "./formatters";
import { roundUpTwoDecimals } from "./general";

export const getAssetValueInAud = (
  assetAmount: string,
  assetPriceInAud: number
): number => {
  const amount = new BN(assetPriceInAud);

  return amount.eq(0)
    ? 0
    : Number(amount.times(new BN(assetAmount)).toFixed(2));
};

export const getLVR = (
  assetAmount: string,
  assetPriceInAud: number,
  debt: number,
) => {  
  const assetValue = getAssetValueInAud(assetAmount, assetPriceInAud);
  if(assetValue === 0 || debt ===  0 ) return 0;
  return (debt / assetValue) * 100;
};

export const getMaximumLVR = (collateraltoDebtRatio: number) =>
  (100 / collateraltoDebtRatio) * 100;

export const getLoansLVR = (loan: server.Loan, asset: server.Asset) => {
  const collateral =
    loan.status === "pending-review" ||
    loan.status === "admin-signed" ||
    loan.status === "pending-legal" ||
    loan.status === "pending-collateral" ||
    loan.status === "rejected" ||
    loan.status === "cancelled" ||
    loan.status === "kyc-incomplete" ||
    loan.status === "draft" ||
    loan.status === "closed" 
      ? loan.desiredCollateral
      : loan.collateral;

  const debt =
    loan.status === "pending-review" ||
    loan.status === "admin-signed" ||
    loan.status === "pending-legal" ||
    loan.status === "pending-collateral" ||
    loan.status === "pending-outgoing-payment" ||
    loan.status === "rejected" ||
    loan.status === "kyc-incomplete" ||
    loan.status === "draft" ||
    loan.status === "cancelled" 
      ? loan.openingDebt
      : loan.outstandingDebt;

  return getLVR(collateral, asset.assetPriceAUD, debt);
};

export const getDebtToCollateralRatio = (
  assetAmount: string,
  assetPriceInAud: number,
  debt: number
) => {
  const assetValue = getAssetValueInAud(assetAmount, assetPriceInAud);

  return assetValue === 0
    ? 0
    : parseFloat(((assetValue / debt) * 100).toFixed(2));
};

export const getCollateralRequired = (
  loanAmount: number,
  collateraltoDebtRatio: number,
  assetPrice: number
) => (loanAmount * (collateraltoDebtRatio / 100)) / assetPrice;

export const getMaxDebtAllowed = (
  collateralAmount: number,
  collateraltoDebtRatio: number,
  assetPrice: number
) => {
  const amount = collateralAmount * assetPrice * (100 / collateraltoDebtRatio);

  // Remove decimals as we only want full dollar amount.
  // We round down to ensure LVR stays at minimum required ratio or better.
  return Math.floor(amount);
};

export const meetsMinimumCR = (
  collateralAmount: string,
  assetPriceAUD: number,
  debt: number,
  minimumCollateralToDebtRatio: number
) => {
  const cr = roundUpTwoDecimals(
    getDebtToCollateralRatio(collateralAmount, assetPriceAUD, debt)
  );

  return cr >= minimumCollateralToDebtRatio;
};

export const displayLoanDebt = (loan: server.Loan): string => {
  if (
    loan.status === "pending-collateral" ||
    loan.status === "pending-outgoing-payment" ||
    loan.status === "pending-legal" ||
    loan.status === "rejected" ||
    loan.status === "pending-review" ||
    loan.status === "admin-signed" ||
    loan.status === "kyc-incomplete" ||
    loan.status === "draft"
  ) {
    return numberToFormattedAUD(loan.openingDebt);
  }

  return numberToFormattedAUD(loan.outstandingDebt);
};

export const getMinCollateralToDebtRatioForAsset = (
  asset: server.Balance,
  systemState: server.SystemState
) =>
  asset.isStablecoin
    ? systemState.minimumStablecoinCollateralToDebtRatio
    : systemState.minimumCryptoCollateralToDebtRatio;
