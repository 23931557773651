import * as React from "react";

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  outerDivClassName?: string;
};

const InputView: React.FC<Props> = props => {
  const { className, error, outerDivClassName, ...restOfProps } = props;
  const quantityInputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    const ignoreScroll = (e: { preventDefault: () => void }) => {
      e.preventDefault();
    };
    if (props.type === "number" && quantityInputRef.current) {
      quantityInputRef.current.addEventListener("wheel", ignoreScroll);
      //disable mousewheel
      quantityInputRef.current.addEventListener(
        "mousewheel",
        ignoreScroll,
        false
      );

      quantityInputRef.current.addEventListener(
        "DOMMouseScroll",
        ignoreScroll,
        false
      );
    }
  }, [props.type, quantityInputRef]);

  const content = (
    <>
      <input
        className={`${className} w-full h-14 mt-5 p-1 text-sm px-4 bg-background-light border border-solid border-other-gray rounded-xl outline-none focus:border-purple-electric`}
        ref={quantityInputRef}
        {...restOfProps}
      />
      {props.error && (
        <div className="w-full bg-purple-electric text-xs p-1 my-1 rounded-xl text-center mt-2 text-white">
          {error}
        </div>
      )}
    </>
  );

  if (props.outerDivClassName) {
    return <div className={`${props.outerDivClassName}`}>{content}</div>;
  } else {
    return <div>{content}</div>;
  }
};

export default InputView;
