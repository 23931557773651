import moment from "moment";
import {
  PageLayout,
  Select,
  Paginate,
  ButtonIcon,
  ToolTip
} from "../../../components";
import {
  Table,
  TableHead,
  TableRow
} from "../../../components/Table/Table_View";
import { getLoansLVR } from "../../../helpers/loan-helpers";
import { getFormattedLoanStatus } from "../../../components/utils";
import {
  collateralFormatter,
  numberToFormattedAUD
} from "../../../helpers/formatters";
import { useEffect, useState } from "react";
import { LIQUIDATE_TYPE } from "../../../helpers/loans-type";

type Props = {
  users?: server.SearchResults<server.UserWithLoanAndBalanceDetails>;
  loans?: server.SearchResults<server.Loan>;
  assetPrices?: server.Asset[];
  page: number;
  pageSize: number;
  loanStatus?: server.LoanStatus;
  onLoanStatusChange(status?: server.LoanStatus): void;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  openLoanDetailsModal(loan?: server.Loan): void;
  setOrderBy(orderBy: server.LoanOrderBy): void;
  setSortBy(sortBy: server.SortType): void;
  sortBy: server.SortType;
  orderBy: server.LoanOrderBy;
  lvrOrderBy: boolean;
  lvrSortBy: server.SortType;
  setLvrOrderBy(orderBy: boolean): void;
  setLvrSortBy(sortBy: server.SortType): void;
  sendRequiredDataForModal(
    selectedLoan: server.LoanData,
    liquidateType: LIQUIDATE_TYPE
  ): void;
};

const LoanTableView: React.FC<Props> = props => {
  return (
    <PageLayout
      showHeader={true}
      loading={!props.loans || !props.assetPrices || !props.users}
    >
      <div className="flex flex-col w-full item-center">
        <LoanTable
          users={props.users!}
          loans={props.loans!}
          assetPrices={props.assetPrices!}
          page={props.page}
          pageSize={props.pageSize}
          loanStatus={props.loanStatus}
          onPageChange={props.onPageChange}
          onPageSizeChange={props.onPageSizeChange}
          onLoanStatusChange={props.onLoanStatusChange}
          openLoanDetailsModal={props.openLoanDetailsModal}
          setOrderBy={props.setOrderBy}
          setSortBy={props.setSortBy}
          sortBy={props.sortBy}
          orderBy={props.orderBy}
          lvrSortBy={props.lvrSortBy}
          lvrOrderBy={props.lvrOrderBy}
          setLvrOrderBy={props.setLvrOrderBy}
          setLvrSortBy={props.setLvrSortBy}
          sendRequiredDataForModal={props.sendRequiredDataForModal}
        />
      </div>
    </PageLayout>
  );
};

type LoanTableProps = {
  users: server.SearchResults<server.UserWithLoanAndBalanceDetails>;
  loans: server.SearchResults<server.Loan>;
  assetPrices: server.Asset[];
  page: number;
  pageSize: number;
  loanStatus?: server.LoanStatus;
  onLoanStatusChange(status?: server.LoanStatus): void;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: number): void;
  openLoanDetailsModal(loan?: server.Loan): void;
  setOrderBy(orderBy: server.LoanOrderBy): void;
  setSortBy(sortBy: server.SortType): void;
  sortBy: server.SortType;
  orderBy: server.LoanOrderBy;
  lvrOrderBy: boolean;
  lvrSortBy: server.SortType;
  setLvrOrderBy(orderBy: boolean): void;
  setLvrSortBy(sortBy: server.SortType): void;
  sendRequiredDataForModal(
    selectedLoan: server.LoanData,
    liquidateType: LIQUIDATE_TYPE
  ): void;
};

const LoanTable: React.FC<LoanTableProps> = props => {
  const pages = Math.ceil(props.loans.totalCount / props.pageSize);
  const {
    setOrderBy,
    setSortBy,
    setLvrOrderBy,
    setLvrSortBy,
    lvrOrderBy,
    lvrSortBy
  } = props;
  const [loansData, setLoansData] = useState<server.LoanData[]>([
    ...props.loans.items
  ]);

  const loanStatus = (lvr: number, isStableCoin: boolean, status: string) => {
    if (status.toLowerCase() === "liquidated") {
      return "text-red";
    }
    if (isStableCoin) {
      if (lvr >= 80 && lvr < 85) {
        return "text-yellow";
      } else if (lvr >= 85 && lvr < 90) {
        return "text-yellow-reddish";
      } else if (lvr >= 90) {
        return "text-red";
      } else {
        return "text-green-300";
      }
    } else {
      if (lvr >= 60 && lvr < 80) {
        return "text-yellow";
      } else if (lvr >= 80 && lvr < 90) {
        return "text-yellow-reddish";
      } else if (lvr >= 90) {
        return "text-red";
      } else {
        return "text-green-300";
      }
    }
  };

  const filterAction = (
    orderBy: server.LoanOrderBy,
    _sortBy: server.SortType
  ) => {
    setLvrOrderBy(false);
    setLvrSortBy("ASC");
    setOrderBy(orderBy);
    const sortBy: server.SortType =
      orderBy === props.orderBy ? (_sortBy === "ASC" ? "DESC" : "ASC") : "DESC";
    setSortBy(sortBy);
  };
  useEffect(() => {
    const loans: server.LoanData[] = props.loans.items.map(
      (requiredLoanData: server.LoanData) => {
        const price = props.assetPrices.find(
          assetPrice => assetPrice.assetId === requiredLoanData.assetId
        ) as server.Asset;
        const username = props.users.items.find(
          user => user.userId === requiredLoanData.userId
        ) as server.UserWithLoanAndBalanceDetails;

        return {
          ...requiredLoanData,
          userName: `${username.firstName} ${username.lastName}`,
          lvr: Number(getLoansLVR(requiredLoanData, price).toFixed(2)) | 0,
          outstandingDebt: requiredLoanData.outstandingDebt
        };
      }
    );
    if (lvrOrderBy) {
      loans.sort((a: server.LoanData, b: server.LoanData) =>
        lvrSortBy === "ASC" ? a.lvr! - b.lvr! : b.lvr! - a.lvr!
      );
    }
    setLoansData([...loans]);
    if (!loansData.length) {
      props.onPageChange(pages);
    }
  }, [lvrOrderBy, lvrSortBy, props, loansData.length, pages]);

  const sortByLvr = (_sortBy: server.SortType) => {
    setLvrOrderBy(true);
    setLvrSortBy(_sortBy === "ASC" ? "DESC" : "ASC");
    setOrderBy("loanId" as server.LoanOrderBy);
    setSortBy("ASC");
  };

  return (
    <div>
      <div className="flex justify-end">
        <table>
          <thead>
            <tr className={"text-right flex font-semibold"}>
              <th className="flex flex-col text-xs uppercase px-3">
                Loan Status
                <Select
                  value={props.loanStatus || "all"}
                  className="mt-3 my-3 width-auto bg-gray-200 w-min h-10 pr-3"
                  onChange={e => {
                    props.onLoanStatusChange(
                      e.target.value === "all" ? undefined : e.target.value
                    );
                  }}
                >
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="closed">Completed</option>
                  <option value="liquidated">Liquidated</option>
                  <option value="pending-review">Pending Review</option>
                  <option value="admin-signed">Admin Signed</option>
                  <option value="pending-legal">Pending Legal</option>
                  <option value="pending-collateral">Pending Collateral</option>
                  <option value="pending-outgoing-payment">
                    Sending Payment
                  </option>
                  <option value="collateral-requested">Withdraw pending</option>
                  <option value="rejected">Rejected</option>
                  <option value="cancelled">Cancelled Application</option>
                  <option value="withdraw-initiated">Withdraw Initiated</option>
                  <option value="retry-withdraw">Retry Withdraw</option>
                  <option value="kyc-incomplete">KYC Incomplete</option>
                  <option value="draft">Draft Loan</option>
                </Select>
              </th>
              <th className={"text-xs uppercase flex  px-3 flex-col"}>
                Per Page
                <Select
                  value={props.pageSize.toString()}
                  className="mt-3 my-3 width-auto bg-gray-200 w-min h-10"
                  onChange={e => {
                    props.onPageSizeChange(Number(e.target.value));
                  }}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <Table className="table-auto text-left mt-10 divide-y divide-gray-800">
        <TableHead>
          <th
            className={`pl-4 py-3 text-left text-xs font-bold text-purple-light sort-filter ${
              props.orderBy === ("loanId" as server.LoanOrderBy) && !lvrOrderBy
                ? `active sort-${props.sortBy}`
                : ""
            }`}
            onClick={() =>
              filterAction("loanId" as server.LoanOrderBy, props.sortBy)
            }
          >
            ID
          </th>
          <th
            className={`pl-2 pr-4 text-left text-xs font-bold text-purple-light`}
          >
            User Name
          </th>
          <th
            className={`pl-2 pr-4 text-left text-xs font-bold text-purple-light sort-filter ${
              props.orderBy === "status" && !lvrOrderBy
                ? `active sort-${props.sortBy}`
                : ""
            }`}
            onClick={() => filterAction("status", props.sortBy)}
          >
            Status
          </th>
          <th
            className={`pl-2 pr-4 text-left text-xs font-bold text-purple-light sort-filter ${
              props.orderBy === "openingDebt" && !lvrOrderBy
                ? `active sort-${props.sortBy}`
                : ""
            }`}
            onClick={() => filterAction("openingDebt", props.sortBy)}
          >
            Borrowed
          </th>
          <th
            className={`pl-2 pr-4 text-left text-xs font-bold text-purple-light sort-filter ${
              props.orderBy === "outstandingDebt" && !lvrOrderBy
                ? `active sort-${props.sortBy}`
                : ""
            }`}
            onClick={() => filterAction("outstandingDebt", props.sortBy)}
          >
            Outstanding
          </th>
          <th
            className={`pl-2 pr-4 text-left text-xs font-bold text-purple-light sort-filter ${
              props.orderBy === "collateral" && !lvrOrderBy
                ? `active sort-${props.sortBy}`
                : ""
            }`}
            onClick={() => filterAction("collateral", props.sortBy)}
          >
            Collateral
          </th>
          <th
            className={`pl-2 pr-4 text-left text-xs font-bold text-purple-light sort-filter ${
              lvrOrderBy ? `active sort-${lvrSortBy}` : ""
            }`}
            onClick={() => sortByLvr(lvrSortBy)}
          >
            LVR &nbsp;
            <ToolTip className="lvr-content">
              <span className="box box-green"></span> Normal
              <span className="box box-yellow"></span> Low risk
              <span className="box box-yellow-reddish"></span> Risk
              <span className="box box-red"></span> High risk
            </ToolTip>
          </th>
          <th
            className={`pl-2 pr-4 text-left text-xs font-bold text-purple-light`}
          >
            Liquidation Price
          </th>
          <th
            className={`pl-2 pr-4 text-left text-xs font-bold text-purple-light sort-filter ${
              props.orderBy === "startedAt" && !lvrOrderBy
                ? `active sort-${props.sortBy}`
                : ""
            }`}
            onClick={() => filterAction("startedAt", props.sortBy)}
          >
            Started At
          </th>
          <th></th>
        </TableHead>
        <tbody className="text-gray-400 divide-y divide-gray-800 border-none">
          {loansData.map(singleLoan => {
            return (
              <TableRow key={singleLoan.id}>
                <td className="py-7 pl-4 text-white font-semibold ">
                  <span>{singleLoan.id}</span>
                </td>
                <td className="py-2 pl-2 pr-4 text-white font-semibold text-base">
                  <span>{singleLoan.userName}</span>
                </td>
                <td className="py-2 pl-2 pr-4 text-white font-semibold text-base">
                  <span>{getFormattedLoanStatus(singleLoan.status)}</span>
                </td>

                <td className="py-2 pl-2 pr-4 text-white font-semibold text-base">
                  <span>{numberToFormattedAUD(singleLoan.openingDebt)}</span>
                </td>
                <td className="py-2 pl-2 pr-4 text-white font-semibold text-base">
                  <span>
                    {numberToFormattedAUD(singleLoan.outstandingDebt)}
                  </span>
                </td>
                <td className="py-2 pl-2 pr-4 text-white font-semibold text-base">
                  <span>
                    {collateralFormatter(singleLoan.collateral, 6)}{" "}
                    {singleLoan.assetId}
                  </span>
                </td>
                <td className="py-2 pl-2 pr-4 text-white font-semibold text-base">
                  <span
                    className={loanStatus(
                      Number(singleLoan.lvr),
                      singleLoan.isStablecoin,
                      singleLoan.status
                    )}
                  >
                    {Number(singleLoan.lvr)}%
                  </span>
                </td>
                <td className="py-2 pl-2 pr-4 text-white font-semibold text-base">
                  <span>
                    {numberToFormattedAUD(singleLoan.liquidationPrice)}
                  </span>
                </td>
                <td className="py-2 pl-2 pr-4 text-xs font-semibold text-white">
                  <span>
                    {singleLoan.startedAt
                      ? moment(singleLoan.startedAt).format("DD/MM/YYYY")
                      : "-"}
                  </span>
                </td>
                <td>
                  <div
                    className={
                      "py-2 pl-2 pr-4 flex justify-between items-center"
                    }
                  >
                    <ButtonIcon
                      className={"px-4 w-full"}
                      title="View"
                      onClick={() => props.openLoanDetailsModal(singleLoan)}
                    />
                  </div>
                  {singleLoan.isFullyLiquidated && (
                    <div
                      className={
                        "py-2 pl-2 pr-4 flex justify-between items-center"
                      }
                    >
                      <ButtonIcon
                        className={
                          "border-yellow-reddish text-yellow-reddish w-full"
                        }
                        title={LIQUIDATE_TYPE.FULL}
                        onClick={() =>
                          props.sendRequiredDataForModal(
                            singleLoan,
                            LIQUIDATE_TYPE.FULL
                          )
                        }
                      />
                    </div>
                  )}
                  {singleLoan.isPartiallyLiquidated &&
                    !singleLoan.isFullyLiquidated && (
                      <div
                        className={
                          "py-2 pl-2 pr-4 flex justify-between items-center"
                        }
                      >
                        <ButtonIcon
                          className={
                            "border-yellow-reddish text-yellow-reddish w-full"
                          }
                          title={LIQUIDATE_TYPE.PARTIAL}
                          onClick={() =>
                            props.sendRequiredDataForModal(
                              singleLoan,
                              LIQUIDATE_TYPE.PARTIAL
                            )
                          }
                        />
                      </div>
                    )}
                </td>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <Paginate
        current={props.page}
        onPageChange={props.onPageChange}
        range={10}
        total={pages}
      />
    </div>
  );
};

export default LoanTableView;
