const validate = ({
  walletAddress,
  withdrawAmount,
  withdrawAvailableAmount
}: {
  walletAddress: string;
  withdrawAmount: string;
  withdrawAvailableAmount: string;
}) => {
  const errors: {
    walletAddress?: string;
    withdrawAmount?: string;
  } = {};
  if (!walletAddress) errors.walletAddress = "Please provide a wallet address";

  if (!withdrawAmount)
    errors.withdrawAmount = "Please enter your withdraw amount.";

  if (!Number(withdrawAmount) || Number(withdrawAmount) <= 0)
    errors.withdrawAmount = "Please enter a valid withdraw amount";

  if (Number(withdrawAmount) > Number(withdrawAvailableAmount))
    errors.withdrawAmount = "Insufficient balance";
  return errors;
};

export default validate;
