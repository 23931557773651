import Input from "../../components/Input/Input_Logic";
import {
  AuthPageLayout,
  IdpAddPhoneNumber,
  PageLayout,
  QuickForm
} from "../../components";
import validator from "../../helpers/validators/login-validator";
import { useHistory } from "react-router";

type Props = {
  verifyNumber: (data: server.VerifyPhoneNumberRequestBody) => Promise<void>;
  login: (data: server.LoginRequestBody) => Promise<void>;
  navigateToSignup: () => void;
  error: string | null;
  setError: (error: string | null) => void;
};

const LoginView: React.FC<Props> = props => {
  const history = useHistory();
  if (props.error && props.error === "auth/multi-factor-auth-required") {
    return (
      <PageLayout showHeader={true}>
        <div className="flex pt-28 w-full text-center justify-center h-full">
          <IdpAddPhoneNumber verifyNumber={props.verifyNumber} />
        </div>
        <div className="flex justify-center w-full mt-6">
          <span
            className="text-purple-electric cursor-pointer"
            onClick={() => props.setError(null)}
          >
            Back to login
          </span>
        </div>
      </PageLayout>
    );
  }

  return (
    <AuthPageLayout
      navigateComponent={
        <>
          <div className="mt-6">
            <span
              className="text-purple-electric cursor-pointer"
              onClick={() => history.push("/forgot-password")}
            >
              Forgot password?
            </span>
          </div>
          <div className="mt-6">
            Don't have an account?{" "}
            <span
              className="text-purple-electric cursor-pointer"
              onClick={props.navigateToSignup}
            >
              Signup
            </span>
          </div>
        </>
      }
    >
      <QuickForm<server.LoginRequestBody>
        initialValues={{ email: "", password: "" }}
        validator={validator}
        onSubmit={props.login}
        error={props.error}
        buttonTitle="Login"
      >
        <div className="mt-5">
          <Input name="email" placeholder="Email" />
        </div>
        <div className="mb-1">
          <Input name="password" placeholder="Password" type="password" />
        </div>
        <div id="login-button"></div>
      </QuickForm>
    </AuthPageLayout>
  );
};

export default LoginView;
