import { useCallback, useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import useGlobalError from "../../hooks/useGlobalError";
import { ProfileView } from "./Profile_View";
import { getAuth } from "firebase/auth";
import { multiFactor } from "@firebase/auth";
import { GENDER } from "../../helpers/constants";
import { BAD_REQUEST_CODE } from "../../types/errors";

const Profile: React.FC = () => {
  const api = useApi();
  const { user, setUser } = useAuth();
  const { setGlobalError } = useGlobalError();
  const [editBankDetail, setEditBankDetail] = useState(false);
  const [profileData, setProfileData] = useState<server.Profile>();
  const [error, setError] = useState<string | null>(null);
  const [editProfile, setEditProfile] = useState(false);
  const [state, onSetState] = useState<string>("NSW");
  const [dlState, setDlState] = useState<server.State>("NSW");
  const [gender, setGender] = useState<GENDER>(GENDER.Male);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [verificationCountExceeded, setVerificationCountExceeded] = useState(
    false
  );
  const [showTwoFaModal, setshowTwoFaModal] = useState(false);
  const auth = getAuth();
  const [isTwoFaEnabled, setTwoFaEnabled] = useState(false);
  const updateTwoFaEnabled = (value: boolean) => {
    setTwoFaEnabled(value);
  };
  const fetchProfile = useCallback(async () => {
    try {
      api.attachAuthHeaderInterceptor(
        (): Promise<string> => {
          return auth.currentUser!.getIdToken();
        }
      );

      const profile = await api.userProfile();
      setProfileData({ ...profile });
    } catch (err) {
      setGlobalError(err as server.ErrorResponse);
    }
  }, [api, auth.currentUser, setGlobalError]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    setTwoFaEnabled(multiFactor(auth.currentUser!).enrolledFactors.length > 0);
  }, [auth.currentUser]);

  const onTwoFaModalClose = () => setshowTwoFaModal(false);
  const updateBankAccount = async (values: server.BankAccount) => {
    try {
      setError(null);
      if (!user?.hasBankAccount) {
        await api.addBankAccount({
          ...values,
          bsbNumber: values.bsbNumber.replace("-", "")
        });
        const updatedUser = await api.fetchAuthUser();
        setUser({ ...user, ...updatedUser });
      } else {
        await api.updateBankAccount({
          ...values,
          bsbNumber: values.bsbNumber.replace("-", "")
        });
      }
      await fetchProfile();
      setEditBankDetail(false);
    } catch (err) {
      setError((err as server.ErrorResponse).message);
    }
  };

  const verifyIdentity = async (body: server.VerifyIdentityRequestBody) => {
    try {
      const updatedUser = await api.verifyIdentity(body);
      setUser({ ...user, ...updatedUser });
      if (!updatedUser.idVerified) {
        return setVerificationFailed(true);
      }
      setVerificationFailed(false);
      fetchProfile();
    } catch (err) {
      const error = err as server.ErrorResponse;
      if (error.code === BAD_REQUEST_CODE.VerificationCountExceed) {
        return setVerificationCountExceeded(true);
      }
      setGlobalError(error);
    }
  };
  const updateTwoFa = async () => {
    setshowTwoFaModal(true);
  };

  return (
    <ProfileView
      profile={profileData}
      editBankDetails={editBankDetail}
      verifyIdentity={verifyIdentity}
      setEditBankDetails={() => {
        setEditBankDetail(!editBankDetail);
        setError(null);
      }}
      updateTwoFa={updateTwoFa}
      updateBankAccount={updateBankAccount}
      isTwoFaEnabled={isTwoFaEnabled}
      error={error}
      verificationFailed={verificationFailed}
      onSetState={onSetState}
      state={state}
      setDlState={setDlState}
      dlState={dlState}
      editProfile={editProfile}
      setEditProfileDetails={() => setEditProfile(!editProfile)}
      showTwoFaModal={showTwoFaModal}
      onTwoFaModalClose={onTwoFaModalClose}
      updateTwoFaEnabled={updateTwoFaEnabled}
      verificationCountExceeded={verificationCountExceeded}
      gender={gender}
      setGender={setGender}
    />
  );
};

export default Profile;
