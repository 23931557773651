import { useHistory } from "react-router";
import { PageLayout, LodaLogo } from "../";

type Props = {
  navigateButtonTitle?: string;
  onNavigate?: () => void;
  navigateComponent?: React.ReactNode;
};

const AuthPageLayout: React.FC<Props> = props => {
  const { location } = useHistory();

  return (
    <PageLayout showHeader={false}>
      <div className="flex flex-col items-center px-4 sm:pt-40 pt-24">
        <div
          className={`auth-container ${
            location.pathname === "/signup" ? "auth-container-sm" : ""
          }`}
        >
          {location.pathname === "/login" ? (
            <div>
              <LodaLogo className="w-32  pb-5" />
            </div>
          ) : null}
          {props.children}
          {props.onNavigate && props.navigateButtonTitle && (
            <button className="mt-4 hover:underline" onClick={props.onNavigate}>
              {props.navigateButtonTitle}
            </button>
          )}
          {props.navigateComponent}
        </div>
      </div>
    </PageLayout>
  );
};

export default AuthPageLayout;
