import { useState } from "react";
import useSWR from "swr";
import useApi from "./useApi";
import useSystemState from "./useSystemState";

export type CollateralCalculatorState = {
  systemState: server.SystemState | undefined;
  loanAmount: number;
  collateralToDebt: number;
  setLoanAmount: (la: number) => void;
  setCollateralToDebt: (ctd: number) => void;
  assetPrices: server.Asset[] | undefined;
};

const useCollateralCalculatorState = (
  loanValue?: number,
  ctd?: number
): CollateralCalculatorState => {
  const api = useApi();
  const systemState = useSystemState();
  const [loanAmount, setLoanAmount] = useState(loanValue || 1000);
  const [collateralToDebt, setCollateralToDebt] = useState(ctd || 200);

  const { data: assetPrices } = useSWR("asset-prices", () =>
    api.getAssetPrices()
  );

  return {
    assetPrices,
    systemState,
    loanAmount,
    setLoanAmount,
    collateralToDebt,
    setCollateralToDebt
  };
};

export default useCollateralCalculatorState;
