export const COLLATERAL_RATIO_BUTTON_DATA: {
  title: string;
  value: number;
}[] = [
  {
    title: "50",
    value: 200
  },
  {
    title: "33",
    value: 300
  },
  {
    title: "25",
    value: 400
  }
];

export const LVR_EXPLANATION = `The Loan-to-Value Ratio (LVR) is an indicator representing the size of the loan compared to the value of the assets securing the loan.`;

export enum GENDER {
  Male = "Male",
  Female = "Female",
  Other = "Other"
}

export const getAvailableGenders = () => {
  return Object.keys(GENDER);
};
