import { useEffect, useState } from "react";
import useSWR from "swr";
import useApi from "../../../hooks/useApi";
import LoanTableView from "./LoanTable_View";
import { AdminLoanDetailModal, LiquidateModal } from "../../../components";
import useGlobalError from "../../../hooks/useGlobalError";
import { LIQUIDATE_TYPE } from "../../../helpers/loans-type";

const LoanTableLogic: React.FC = () => {
  const api = useApi();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [loanStatus, setLoanStatus] = useState<server.LoanStatus | undefined>();
  const onPageChange = (page: number) => {
    setPage(page);
  };
  const [orderBy, setOrderBy] = useState<server.LoanOrderBy>(
    "loanId" as server.LoanOrderBy
  );
  const [sortBy, setSortBy] = useState<server.SortType>("ASC");
  const [lvrOrdrby, setLvrOrderBy] = useState(false);
  const [lvrSortBY, setLvrSortBy] = useState<server.SortType>("ASC");
  const { setGlobalError } = useGlobalError();

  const [loanDetail, setLoanDetail] = useState<server.Loan | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [showLiquidateModal, setShowLiquidateModal] = useState(false);
  const [assetPrice, setAssetPrice] = useState<number>(0);
  const [lvr, setLvr] = useState<number>(0);
  const [liquidateModalTitle, setLiquidateModalTitle] = useState(
    LIQUIDATE_TYPE.PARTIAL
  );
  const [selectedLoan, setSelectedLoan] = useState<server.Loan | null>(null);

  const onChangeSelectedRange = (pageRange: number) => {
    setPage(1);
    setPageSize(pageRange);
  };

  const sendRequiredDataForModal = (
    selectedLoan: server.LoanData,
    liquidateType: LIQUIDATE_TYPE
  ) => {
    setLvr(selectedLoan.lvr!);
    setShowLiquidateModal(true);
    setLiquidateModalTitle(liquidateType);
    setSelectedLoan(selectedLoan);
  };

  const getLoans = () => {
    try {
      return api.adminGetLoans({
        page,
        pageSize: pageSize,
        status: loanStatus,
        orderBy: orderBy,
        sortBy: sortBy
      });
    } catch (error: any) {
      setGlobalError(error);
      return undefined;
    }
  };

  const getUsers = () => {
    try {
      return api.adminGetUsers({
        page: -1, // to get all users send page as negative
        loanFilter: "all",
        balanceFilter: "all"
      });
    } catch (error: any) {
      setGlobalError(error);
      return undefined;
    }
  };
  const {
    data: loans,
    revalidate: revalidateLoans
  } = useSWR(
    `adminLoans-page-${page}-range-${pageSize}-loanFilter${loanStatus}-filter${orderBy}-sortBy-${sortBy}`,
    () => getLoans()
  );

  const {
    data: users,
    revalidate: revalidateUsers
  } = useSWR(
    `adminUsers-page-${page}-range-${pageSize}-loanFilter${
      loanStatus || "all"
    }-balanceFilterall`,
    () => getUsers()
  );

  const revalidateUsersAndLoans = () => {
    revalidateLoans();
    revalidateUsers();
  };
  const { data: assetPrices } = useSWR("asset-prices", () =>
    api.getAssetPrices()
  );

  const openLoanDetail = (loan: server.Loan) => {
    if (loan) {
      setLoanDetail(loan);
    }
  };

  useEffect(() => {
    if (loanDetail) {
      const loan = loans?.items.find(loan => loan.id === loanDetail.id);
      if (loan) setLoanDetail({ ...loan });
    }
    // eslint-disable-next-line
  }, [loans?.items]);
  useEffect(() => {
    setError(null);
  }, [loanDetail]);

  useEffect(() => {
    if (selectedLoan) {
      const priceOfSelectedAsset = assetPrices!.find(
        assetPrice => assetPrice.assetId === selectedLoan!.assetId
      ) as server.Asset;
      setAssetPrice(priceOfSelectedAsset.assetPriceAUD);
    }
  }, [selectedLoan, assetPrices]);

  return (
    <>
      <LoanTableView
        users={users}
        loans={loans}
        assetPrices={assetPrices}
        page={page}
        setOrderBy={setOrderBy}
        setSortBy={setSortBy}
        pageSize={pageSize}
        loanStatus={loanStatus}
        onPageChange={onPageChange}
        onPageSizeChange={onChangeSelectedRange}
        onLoanStatusChange={setLoanStatus}
        openLoanDetailsModal={openLoanDetail}
        sortBy={sortBy}
        orderBy={orderBy}
        lvrSortBy={lvrSortBY}
        lvrOrderBy={lvrOrdrby}
        setLvrOrderBy={setLvrOrderBy}
        setLvrSortBy={setLvrSortBy}
        sendRequiredDataForModal={sendRequiredDataForModal}
      />
      <AdminLoanDetailModal
        loan={loanDetail}
        onClose={() => setLoanDetail(null)}
        onReviewLoan={revalidateUsersAndLoans}
        onLoanUpdate={setLoanDetail}
        error={error}
        setError={setError}
      />

      {showLiquidateModal && (
        <LiquidateModal
          setShowLiquidateModal={setShowLiquidateModal}
          showLiquidateModal
          assetPrice={assetPrice!}
          lvr={lvr!}
          selectedLoan={selectedLoan!}
          modalTitle={liquidateModalTitle}
          setLvr={setLvr}
          refreshLoan={revalidateUsersAndLoans}
        />
      )}
    </>
  );
};

export default LoanTableLogic;
