import React, { useCallback, useEffect, useState } from "react";
import { Modal, Loader, Button, BubbleText } from "..";
import {
  formatWithFixedDown,
  formatWithUndefined,
  numberToFormattedAUD
} from "../../helpers/formatters";
import Error from "../error/Error";
import { RadioGroup } from "../RadioGroup/RadioGroup_View";
import { DebounceInput } from "react-debounce-input";

type Props = {
  show: boolean;
  loan: server.Loan | undefined;
  onClose(): void;
  balanceInAud: number | undefined;
  getRepayDetails: (val: number) => Promise<void>;
  repay: () => Promise<void>;
  setCollateralLoanAmount: (val: number) => void;
  collateralLoanAmount: number | undefined;
  setError: (val: string | undefined) => void;
  error: string | undefined;
  remainingCollateral: number | undefined;
  remainingLoanAmount: number | undefined;
  success: boolean;
  closeSuccessModal: () => void;
  loading: boolean;
};

enum RepaymentType {
  DEPOSITAMOUNT = "Deposit Amount",
  SELLCOLLATERAL = "Sell collateral"
}

type IContentProps = {
  props: Props;
  accountNumber: string;
  bsb: string;
  accountName: string;
  repaymentType: RepaymentType;
  setCollateralLoanAmount: (val: number) => void;
  collateralLoanAmount: number | undefined;
  setError: (val: string | undefined) => void;
  error: string | undefined;
  getRepayDetails: (val: number) => Promise<void>;
  remainingCollateral: number | undefined;
  remainingLoanAmount: number | undefined;
  repay: () => Promise<void>;
  loading: boolean;
  quantityInputRef: React.RefObject<HTMLInputElement>;
};

const LoanDetailsModal: React.FC<Props> = props => {
  const {
    loan,
    getRepayDetails,
    repay,
    setCollateralLoanAmount,
    collateralLoanAmount,
    setError,
    error,
    remainingCollateral,
    remainingLoanAmount,
    success,
    closeSuccessModal,
    loading
  } = props;
  const [accountNumber, setAccountNumber] = useState("");
  const [bsb, setBsb] = useState("");
  const [accountName, setAccountName] = useState("");

  const [repaymentType, setRepaymentType] = useState<RepaymentType>(
    RepaymentType.DEPOSITAMOUNT
  );

  const getAccountInfo = useCallback(async () => {
    if (loan) {
      setAccountNumber(loan.repaymentDetails.bankAccountName);
      setBsb(loan.repaymentDetails.bankAccountBsb);
      setAccountName(loan.repaymentDetails.bankAccountNumber);
    }
  }, [loan]);

  useEffect(() => {
    getAccountInfo();
  }, [getAccountInfo]);

  const quantityInputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    const ignoreScroll = (e: { preventDefault: () => void }) => {
      e.preventDefault();
    };
    if (
      quantityInputRef.current &&
      !Boolean(quantityInputRef.current.getAttribute("listener"))
    ) {
      quantityInputRef.current.addEventListener("wheel", ignoreScroll);
      //disable mousewheel
      quantityInputRef.current.addEventListener("mousewheel", ignoreScroll);

      quantityInputRef.current.addEventListener("DOMMouseScroll", ignoreScroll);
      quantityInputRef.current.setAttribute("listener", "true");
    }
  }, [quantityInputRef, collateralLoanAmount]);

  const contentProps: IContentProps = {
    props,
    accountNumber,
    bsb,
    accountName,
    repaymentType,
    setCollateralLoanAmount,
    collateralLoanAmount,
    setError,
    error,
    getRepayDetails,
    remainingCollateral,
    remainingLoanAmount,
    repay,
    loading,
    quantityInputRef
  };

  return (
    <>
      {success ? (
        <Modal
          title="Repayment Initiated"
          show={success}
          onClose={closeSuccessModal}
        >
          <div className="px-4 m-4 text-center">
            <p className="mb-2">Thank you for the repayment</p>
            <p className="mb-2">The loan will be updated after 24 hours</p>
          </div>
        </Modal>
      ) : (
        <Modal title="Loan Details" show={props.show} onClose={props.onClose}>
          <div>
            <span className="block font-bold text-s font-ABC-Favorite-Extended tracking-wider">
              Repayment Type
            </span>
            <div>
              <RadioGroup
                name="loan-type"
                onChangeHandler={e =>
                  setRepaymentType(e.target.value as RepaymentType)
                }
                options={Object.values(RepaymentType)}
                value={repaymentType}
              />
            </div>
          </div>
          {getContent(contentProps)}
        </Modal>
      )}
    </>
  );
};

type ICollateralContent = {
  loan: server.Loan;
  setCollateralLoanAmount: (val: number) => void;
  collateralLoanAmount: number | undefined;
  setError: (val: string | undefined) => void;
  error: string | undefined;
  balanceInAud: number | undefined;
  getRepayDetails: (val: number) => Promise<void>;
  remainingCollateral: number | undefined;
  remainingLoanAmount: number | undefined;
  repay: () => Promise<void>;
  loading: boolean;
  quantityInputRef: React.RefObject<HTMLInputElement>;
};

const getContent = (content: IContentProps): React.ReactNode => {
  const { loan } = content.props;
  if (!loan) {
    return (
      <div className="flex h-20 justify-center items-center">
        <Loader />
      </div>
    );
  }
  const collateralContent: ICollateralContent = {
    loan,
    setCollateralLoanAmount: content.setCollateralLoanAmount,
    collateralLoanAmount: content.collateralLoanAmount,
    setError: content.setError,
    error: content.error,
    balanceInAud: content.props.balanceInAud,
    getRepayDetails: content.getRepayDetails,
    remainingCollateral: content.remainingCollateral,
    remainingLoanAmount: content.remainingLoanAmount,
    repay: content.repay,
    loading: content.loading,
    quantityInputRef: content.quantityInputRef
  };
  return (
    <div className="pt-10">
      <span className="block font-bold text-s font-ABC-Favorite-Extended tracking-wider">
        Repayment Details
      </span>
      <div className="mt-8">
        {content.repaymentType === RepaymentType.DEPOSITAMOUNT
          ? getLoanTypeContent(
              loan,
              content.accountNumber,
              content.bsb,
              content.accountName
            )
          : getCollateralTypeContent(collateralContent)}
        <p className="text-base mt-9 text-left deposit-tooltip">
          Loda is constantly monitoring for incoming transfers. Once your funds
          are received, we will notify you and your outstanding balance will be
          updated. Please ensure you include the reference code mentioned above
          so that we can match the payment to this loan.
        </p>
      </div>
    </div>
  );
};
const getLoanTypeContent = (
  loan: server.Loan,
  accountNumber: string,
  bsb: string,
  accountName: string
): React.ReactNode => {
  return (
    <>
      <DetailRow title="Account name" value={accountName} />
      <DetailRow title="Account BSB" value={bsb} />
      <DetailRow title="Account number" value={accountNumber} />
      <DetailRow
        title="Reference"
        className="highlighted-row-value"
        value={loan.repaymentDetails.reference}
      />
      <div className="w-100 grid grid-cols-2 bg-loan-status-modal p-7 rounded-xl mt-12 gap-1.5">
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Status
        </div>
        <div className="flex justify-end font-medium text-base">
          {loan.status[0].toUpperCase() + loan.status.slice(1)}
        </div>
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Collateral
        </div>
        <div className="flex justify-end font-medium text-base">
          {loan.collateral} {loan.assetId}
        </div>
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Outstanding Amount:
        </div>
        <div>
          <div className="flex justify-end font-medium text-base">
            $ {loan.outstandingDebt.toFixed(2)}
          </div>
        </div>
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Liquidation Price:
        </div>
        <div className="flex justify-end font-medium text-base">
          $ {loan.liquidationPrice.toFixed(2)}
        </div>
      </div>
    </>
  );
};
const getCollateralTypeContent = (
  content: ICollateralContent
): React.ReactNode => {
  const updateLoanAmount = (val: number) => {
    try {
      content.setError(undefined);
      const number = val;
      if (number > content.loan.outstandingDebt) {
        content.setError("Value must not be greater than outstanding debt");
      } else if (number <= 0) {
        content.setError("Value can not be zero or negative");
      }
      content.setCollateralLoanAmount(number);
    } catch (error) {
      content.setError("Error in setting collateral amount");
    }
  };
  const onInputLoanAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateLoanAmount(+e.target.value);
    content.getRepayDetails(+e.target.value);
  };

  const remainingOutstandingAmount = (
    newLoanAmount: number | undefined
  ): string => {
    if (!newLoanAmount || newLoanAmount <= 1)
      return formatWithUndefined("0.00", 5);
    return formatWithUndefined(newLoanAmount, 5);
  };

  const updateMaxLoanAmount = () => {
    const maxAmount = formatWithFixedDown(content.loan.outstandingDebt, 6);
    updateLoanAmount(parseFloat(maxAmount));
    content.getRepayDetails(parseFloat(maxAmount));
  };

  const isDisabled =
    content.error !== undefined ||
    content.remainingLoanAmount === undefined ||
    content.remainingCollateral === undefined;
  return (
    <>
      <div className="flex justify-between custom-input-group items-baseline">
        <div className="flex flex-col">
          <span>Enter debt amount to repay from collateral</span>
          <span
            className="fa-sm text-purple-electric cursor-pointer width-fit-content"
            onClick={updateMaxLoanAmount}
          >
            Enter full amount
          </span>
        </div>
        <div className="w-1/3">
          <DebounceInput
            type="number"
            debounceTimeout={500}
            min={0}
            value={content.collateralLoanAmount}
            placeholder="Loan Amount"
            className="pl-4 p-3 px-6 margin0 text-sm text-right height-auto w-full h-14 mt-5 bg-background-light border border-solid border-other-gray rounded-xl outline-none focus:border-purple-electric"
            onChange={e => onInputLoanAmount(e)}
            inputRef={content.quantityInputRef}
          />
        </div>
      </div>
      {content.error && <Error>{content.error}</Error>}
      <div className="w-100 grid grid-cols-2 bg-loan-status-modal p-7 rounded-xl mt-12 gap-1.5">
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Status
        </div>
        <div className="flex justify-end font-medium text-base">
          {content.loan.status[0].toUpperCase() + content.loan.status.slice(1)}
        </div>
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Asset Price:
        </div>
        <div className="flex justify-end font-medium text-base">
          {content.balanceInAud && numberToFormattedAUD(content.balanceInAud)}
        </div>
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Collateral
        </div>
        <div className="flex justify-end font-medium text-base">
          {formatWithUndefined(content.loan.collateral, 5)}{" "}
          {content.loan.assetId}
        </div>
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Outstanding Amount:
        </div>
        <div>
          <div className="flex justify-end font-medium text-base">
            $ {formatWithUndefined(content.loan.outstandingDebt, 5)}
          </div>
        </div>
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Remaining Outstanding Amount:
        </div>
        <div>
          <div className="flex justify-end font-medium text-base">
            $ {remainingOutstandingAmount(content.remainingLoanAmount)}
          </div>
        </div>
        <div className="flex justify-start font-normal text-base deposit-tooltip">
          Remaining Collateral:
        </div>
        <div>
          <div className="flex justify-end font-medium text-base">
            {formatWithUndefined(content.remainingCollateral, 5)}{" "}
            {content.loan.assetId}
          </div>
        </div>
      </div>
      <Button
        title="Repay"
        className="w-full mt-4"
        disabled={isDisabled}
        loading={content.loading}
        onClick={() => content.repay()}
      />
      <div className="flex flex-col mt-2">
        <BubbleText color={"yellow"} className={"text-lg"}>
          {" "}
          *The above calculated values may vary from the actual values which
          will be updated after 24 hours.
        </BubbleText>
      </div>
    </>
  );
};

export default LoanDetailsModal;

type DetailRowProps = {
  title: string;
  value: string;
  className?: string;
};

const DetailRow = ({ title, value, className }: DetailRowProps) => {
  return (
    <div
      className={`${className} flex flex-row justify-between loan-border pb-5 mb-6 text-sm`}
    >
      <span className="block font-normal font-sans text-base deposit-tooltip">
        {title}
      </span>
      <span className={"font-semibold text-base row-value"}>{value}</span>
    </div>
  );
};
