import React from "react";
import { Button } from "../../components";

type Props = {
  openRequestLoanModal(): void;
};
export const EmptyPageView: React.FC<Props> = ({ openRequestLoanModal }) => {
  return (
    <div className="animation-page-wrap">
      <svg
        className="circle-animation"
        width="198"
        height="313"
        viewBox="0 0 198 313"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="99" cy="17" r="17" fill="white" />
        <circle cx="99" cy="58" r="42.25" stroke="#BEAAFF" strokeWidth="1.5" />
        <circle cx="99" cy="214" r="98.25" stroke="#BEAAFF" strokeWidth="1.5" />
        <circle cx="99" cy="138" r="79.25" stroke="white" strokeWidth="1.5" />
      </svg>

      <div className="animation-page-content text-center">
        <h2 className="font-ABC-Favorite-Extended text-3xl font-bold mb-3 text-white">
          Borrow with Loda
        </h2>
        <p>
          You currently don’t have any loans with Loda. Secure a loan today at
          10% by using your cryptocurrency.
        </p>
        <Button
          title="Borrow"
          className="mt-7 w-36"
          onClick={openRequestLoanModal}
        />
      </div>
    </div>
  );
};
