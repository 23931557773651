import { useState } from "react";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import useGlobalError from "../../hooks/useGlobalError";
import VerifyEmailView from "./VerifyEmail_View";

const VerifyEmail: React.FC = () => {
  const { user } = useAuth();
  const api = useApi();
  const { setGlobalError } = useGlobalError();
  const [isOtpSent, setIsOtpSent] = useState(false);

  const sendVerifyEmail = async () => {
    try {
      setIsOtpSent(true);
      if (user) {
        await api.sendVerifyEmailLink({ userId: user.userId });
      }
      setIsOtpSent(false);
    } catch (error) {
      setIsOtpSent(false);
      setGlobalError(error as server.ErrorResponse);
    }
  };

  return (
    <VerifyEmailView isOtpSent={isOtpSent} sendVerifyEmail={sendVerifyEmail} />
  );
};

export default VerifyEmail;
