import { QuickForm, Modal } from "../";
import Input from "../Input/Input_Logic";
import validate from "../../helpers/validators/withdraw-validator";

type Props = {
  loan?: server.Loan;
  onClose: () => void;
  onWithdraw(loanId: number, walletAddress: string): void;
  finishedWithdrawal: boolean;
};

const WithdrawModal: React.FC<Props> = props => {
  if (!props.loan) {
    return null;
  }
  if (!props.finishedWithdrawal) {
    return (
      <Modal
        title={`Withdraw ${props.loan.assetId}`}
        show={!!props.loan}
        onClose={props.onClose}
      >
        <label className={"label-font"}>
          Amount to be withdrawn:{" "}
          <span className={"text-purple-electric"}>
            {Number(props.loan.collateral).toFixed(5)}
          </span>
        </label>
        <QuickForm
          initialValues={{
            walletAddress: "",
            withdrawAmount: Number(props.loan.collateral).toFixed(5).toString(),
            withdrawAvailableAmount: Number(props.loan.collateral)
              .toFixed(5)
              .toString()
          }}
          validator={validate}
          error={null}
          buttonTitle="Withdraw Funds"
          onSubmit={({ walletAddress }): void =>
            props.loan && props.onWithdraw(props.loan.id, walletAddress)
          }
        >
          <Input name="walletAddress" placeholder="Wallet Address" />
          <Input
            name="withdrawAmount"
            readOnly
            disabled
            placeholder="Withdraw Amount"
          />
        </QuickForm>
      </Modal>
    );
  }

  return (
    <Modal
      title={`Withdrawal Successful`}
      show={!!props.loan}
      onClose={props.onClose}
      className="text-center success-modal"
    >
      <div className="text-center p-4">
        Thank you for completing your Loan with Loda. You will receive your
        assets back within 24 hours.
      </div>
    </Modal>
  );
};

export default WithdrawModal;
