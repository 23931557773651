import { PageLayout } from "../../components";

type Props = {};

const NotFoundView: React.FC<Props> = () => {
  return (
    <PageLayout showHeader={true}>
      <div className="flex justify-center">
        <h1>Page not found!</h1>
      </div>
    </PageLayout>
  );
};

export default NotFoundView;
