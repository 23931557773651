import { useField } from "formik";
type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

const SelectFormik: React.FC<Props> = props => {
  const [field, meta] = useField(props as any);
  const error = meta.error && meta.touched ? meta.error : undefined;

  const { className } = props;
  return (
    <div>
      <select
        {...props}
        {...field}
        className={`w-full mt-3 p-1 px-3 text-sm bg-background-light border-2 border-solid border-other-gray rounded-xl outline-none focus:border-purple-electric ${className}`}
      />
      {error && (
        <div className="w-full bg-purple-electric text-xs p-1 my-1 rounded-xl text-center mt-2 text-white">
          {error}
        </div>
      )}
    </div>
  );
};

export default SelectFormik;
