import React from "react";
import { useState } from "react";
import { User } from "../../providers/Auth";
import OnboardingModalView, { Tab } from "./OnboardingModal_View";

type Props = {
  show: boolean;
  user: User;
  onClose: () => void;
};

const OnboardingModal: React.FC<Props> = props => {
  const [selectedTab, setSelectedTab] = useState<Tab>("email");

  React.useEffect(() => {
    if (!props.user.isEmailVerified) {
      setSelectedTab("email");
    } else if (!props.user.phoneNumberVerified) {
      setSelectedTab("phone");
    } else if (!props.user.hasBankAccount) {
      setSelectedTab("bank");
    } else if (props.user.hasBankAccount) {
      setSelectedTab("kyc");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <OnboardingModalView
      {...props}
      tab={selectedTab}
      changeTab={setSelectedTab}
    />
  );
};

export default OnboardingModal;
