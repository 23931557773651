import { useCallback, useEffect, useState } from "react";
import LoanDetailsModalAdminView from "./LoanDetailsModal_Admin_View";
import useApi from "../../hooks/useApi";
import useGlobalError from "../../hooks/useGlobalError";
import useSWR from "swr";

type Props = {
  loan: server.Loan | null;
  onClose: () => void;
  onReviewLoan(): void;
  onLoanUpdate(loan: server.Loan): void;
  error: string | null;
  setError: (err: string | null) => void;
};

const LoanDetailsModalAdminLogic: React.FC<Props> = props => {
  const [user, setUser] = useState<server.User | null>(null);
  const [
    documents,
    setDocuments
  ] = useState<server.GetDocumentsResponse | null>(null);
  const [financialData, setFinancialData] = useState<
    | server.ConsumerResponsibleLendingData
    | server.BusinessResponsibleLendingData
    | null
  >(null);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [loadingFinancialData, setLoadingFinancialData] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [contractUrl, setContractUrl] = useState<string | null>(null);
  const [reviewingLoan, setReviewingLoan] = useState<boolean>(false);
  const [alertShow, setAlertShow] = useState<boolean>(false);
  const [alertShowForTransaction, setAlertShowForTransaction] = useState<boolean>(false);
  const [getDepositColletral, setGetDepositColletral] = useState<string>("");
  const [hashes, setHashes] = useState<string[]>([]);

  const api = useApi();
  const { setGlobalError } = useGlobalError();

  const fetchUser = useCallback(() => {
    if (props.loan) {
      api.adminGetUser(props.loan.userId).then(setUser).catch(setGlobalError);
    }
  }, [props.loan, api, setGlobalError]);

  const { data: assetPrices } = useSWR("asset-prices", () =>
    api.getAssetPrices()
  );

  useEffect(()=>{
    if (props.loan) {
      const hashString = props.loan.transactionHash;
      if (hashString) {
        const hashArray = JSON.parse(hashString);
        setHashes(hashArray);
      }
    }
  },[props.loan]);

  useEffect(() => {
    setDocuments(null);
    setLoadingDocuments(false);
    setFinancialData(null);
    setLoadingFinancialData(false);
    setUser(null);
    setContractUrl(null);
    fetchUser();
  }, [props.loan?.id, fetchUser]);

  const reviewLoan = async (status: server.ReviewLoanRequestBody["status"]) => {
    if (!props.loan) {
      return;
    }
    if (status === "reject") {
      setAlertShow(false);
    }
    setReviewingLoan(true);

    try {
      const updatedLoan = await api.reviewLoan(props.loan.id, { status });
      props.onReviewLoan();
      props.onLoanUpdate(updatedLoan);
    } catch (error) {
      const err = error as server.ErrorResponse;
      setGlobalError(err);
    }

    setReviewingLoan(false);
  };

  const reviewTransaction = async (status:server.ReviewLoanRequestBody["status"]) => {
    if (!props.loan) {
      return;
    }
    if (status === "reject") {
      setAlertShow(false);
    }
    setReviewingLoan(true);

    try {
       await api.reviewTransaction(props.loan.id, { status, depositCollateral:getDepositColletral });
    } catch (error) {
      const err = error as server.ErrorResponse;
      setGlobalError(err);
    }

    setReviewingLoan(false);
  }

  const fetchDocuments = async () => {
    if (!props.loan) {
      return;
    }

    setLoadingDocuments(true);

    try {
      const documentResults = await api.getLoanDocuments(props.loan.id);

      setDocuments(documentResults);
    } catch (error) {
      setGlobalError(error as server.ErrorResponse);
    }

    setLoadingDocuments(false);
  };

  const fetchFinancialData = async () => {
    if (!props.loan) {
      return;
    }

    setLoadingFinancialData(true);

    try {
      const data = await api.getLoanFinancialData(props.loan.id);

      setFinancialData(data);
    } catch (error) {
      setGlobalError(error as server.ErrorResponse);
    }

    setLoadingFinancialData(false);
  };

  const fetchLoanContract = async () => {
    setProcessing(true);
    if (!props.loan) {
      return;
    }

    const contractUrl = await api.getLoanContract(props.loan.id);

    setContractUrl(contractUrl);
    setProcessing(false);
    window.open(contractUrl, "_blank");
  };

  const createContract = async () => {
    setProcessing(true);
    if (!props.loan) {
      return;
    }

    try {
      const updatedLoan = await api.adminCreateLoanContract(props.loan.id);
      props.onLoanUpdate(updatedLoan);
      fetchLoanContract();
    } catch (error) {
      setGlobalError(error as server.ErrorResponse);
    }

    setProcessing(false);
  };

  const handleSubmitDepositColletral = async () => {
    if (!props.loan) {
      return;
    }
    reviewTransaction('approve').then(()=>{
      setGetDepositColletral('');
    });
  }

  return (
    <LoanDetailsModalAdminView
      user={user}
      price={assetPrices?.find(a => a.assetId === props.loan?.assetId)}
      loan={props.loan}
      onClose={props.onClose}
      loadingDocuments={loadingDocuments}
      documents={documents}
      loadingFinancialData={loadingFinancialData}
      financialData={financialData}
      processing={processing}
      contractUrl={contractUrl}
      fetchDocuments={fetchDocuments}
      fetchFinancialData={fetchFinancialData}
      reviewLoan={reviewLoan}
      createContract={createContract}
      fetchLoanContract={fetchLoanContract}
      reviewing={reviewingLoan}
      alertShow={alertShow}
      onAlertClose={() => setAlertShow(false)}
      onAlertShow={() => setAlertShow(true)}
      alertShowForTransaction={alertShowForTransaction}
      onAlertCloseForTransaction={() => setAlertShowForTransaction(false)}
      onAlertShowForTransaction={() => setAlertShowForTransaction(true)}
      error={props.error}
      reviewTransaction={reviewTransaction}
      getDepositColletral={getDepositColletral}
      setGetDepositColletral={setGetDepositColletral}
      handleSubmitDepositColletral={handleSubmitDepositColletral}
      hashes={hashes}
    />
  );
};

export default LoanDetailsModalAdminLogic;
