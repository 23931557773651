const LoanTermsAndConditions: React.FC = () => {
  return (
    <div className="term-condition overflow-y-scroll  h-64 my-6">
      <p>
        <strong className="text-white">GENERAL TERMS AND CONDITIONS</strong>
      </p>

      <p>&nbsp;</p>

      <p>
        This [Product name] Agreement is made up of two documents: the Details
        and these General Terms. These should be read together.
      </p>

      <p>&nbsp;</p>

      <p>
        These General Terms do not contain all the contract terms or all the
        information we are required by law to give you before the agreement is
        formed. Further terms and information are in the Details. Together,
        these General Terms and the Details make up the [Product name]
        Agreement.
      </p>

      <p>&nbsp;</p>

      <p>
        The meaning of capitalised words and some other key words is explained
        at the end of these General Terms. There is also a description of your
        major rights and obligations under the National Credit Code at the end
        of these General Terms.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Scope of this contract</strong>
      </p>

      <p>
        <strong>1.</strong>
        <strong>Parties</strong>
      </p>

      <p>
        This [Product name] Agreement is between Loda Finance Pty Ltd ACN 648
        427 721 of Unit 506, 113 Commercial Road, Teneriffe, QLD, 4005 (
        <strong>Loda</strong>/<strong>we/us/our</strong>) and the Borrower named
        in the Details (<strong>Borrower</strong>/<strong>you/your</strong>).
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>2.</strong>
        <strong>About this [Product name] Agreement</strong>
      </p>

      <p>
        2.1 Loda offers a credit product where it will advance the Loan Amount
        and take custody of the Security. By submitting an application for
        credit, you agree to be bound by these terms and conditions.
      </p>

      <p>
        2.2 The [Product name] Agreement documents only become binding on us if
        you have signed the offer in the Details and we have accepted your
        offer.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>3.</strong>
        <strong>Conditions precedent to advance of Loan Amount</strong>
      </p>

      <p>
        3.1 We are not required to advance any funds to you until you have
        transferred the Security into the Security Wallet.
      </p>

      <p>
        3.2 We are not required to advance any funds to you until you have
        provided all information reasonably requested by us.
      </p>

      <p>
        3.3 We are not required to advance any funds to you if, at the time of
        the proposed advance, the LVR would exceed 50% (even if the LVR would
        not have exceeded 50% at the time that you transferred the Security into
        the Security Wallet).
      </p>

      <p>
        3.4 If the LVR exceeds 50% at the time we propose to advance funds to
        you, we may in our absolute discretion:
      </p>

      <p>
        (a) rescindthis [Product name] Agreement immediately and return the
        Security by transferring it to the Customer Wallet; or
      </p>

      <p>
        (b)reduce the Loan Amount to the nearest multiple of $1,000 that would
        result in the LVR being less than or equal to 50%.
      </p>

      <p>
        3.5 If you do not remedy any of the circumstances in
        clause3.1or3.2within 7 days of the Commencement Date, we may rescind
        this [Product name] Agreement without any further liability to us.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>4.</strong>
        <strong>Loan Amount and Loan Term</strong>
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Loan Amount</strong>
      </p>

      <p>
        4.1 Subject to clause3, you offer to borrow money from us and, by
        executing this [Product name] Agreement, we accept your offer and agree
        to lend you the Loan Amount specified in the Details.It is your
        responsibility to ensure the completeness and accuracy of the payment
        directions in the Details.
      </p>

      <p>
        4.2 If the LVR falls belowXX%, whether as a result of changes in the
        market value of the Security or you transferring additional Security to
        us, you may request further funds from us, up to the amount that would
        cause the LVR to be50%.Our decision to increase the Loan Amount
        following a request to do so is in our absolute discretion and is
        subject to our responsible lending obligations under the National Credit
        Code.
      </p>

      <p>
        4.3 Further credit under clause4.2will be deemed to be an increase in
        the Loan Amount and becomes part of the Loan Amount Total, repayable to
        us in accordance with clause6.
      </p>

      <p>
        4.4 We may carry out a review of your Loan Account at least annually.
        You must give us any information we reasonably ask for concerning your
        financial position and your performance under this [Product name]
        Agreement.
      </p>

      <p>
        4.5 If your Loan Account at any time has a credit balance, we will
        refund to you that credit balance.
      </p>

      <p>
        4.6 We may decline any request for an increase to your Loan Amount if:
      </p>

      <p>
        (a) you have not given us all documents and information we reasonably
        require (including a direct debit authorisation), in a form satisfactory
        to us;
      </p>

      <p>
        (b) you have given us any false or misleading financial or other
        information;
      </p>

      <p>
        (c) you have not complied with any other conditions that we have
        reasonably requested that you satisfy;
      </p>

      <p>
        (d) there is a suspicion of unlawful use of the loan account, possible
        fraud or a security risk;
      </p>

      <p>
        (e) you have breached this [Product name] Agreement or any other
        agreement between you and us;
      </p>

      <p>
        (f) there is an increased risk that you will improperly perform your
        monetary obligations towards us;
      </p>

      <p>
        (g) payment under the [Product name] Agreement is overdue for at least
        one day and remains overdue; or
      </p>

      <p>
        (h) we receive information on your death or intention to initiate
        bankruptcy proceedings.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>5.</strong>
        <strong>Security</strong>
      </p>

      <p>
        5.1 You must transfer the Security to us to secure your obligations
        under this [Product name] Agreement. You must transfer the Security in
        the relevant cryptocurrency to the Security Wallet.
      </p>

      <p>
        5.2 We will hold the Security in a cold storage wallet which we own and
        control.
      </p>

      <p>5.3 You agree that, for the purposes of the PPSA:</p>

      <p>(a) thisAgreementis a security agreement; and</p>

      <p>
        (b) by transferring the Security to us in accordance with clause 5.1,
        you grant us a security interest in the collateral that is the
        cryptocurrency so transferred.
      </p>

      <p>
        5.4 If you repay the Loan Amount Total at the end of the Loan Term and
        you do not owe any other amount to us, we will transfer the Security to
        the Customer Wallet within 24 hours or as soon as reasonably practicable
        after the end of the Loan Term.
      </p>

      <p>
        5.5 You are solely responsible for ensuring that the Customer Wallet
        information provided to us is correct. We are not responsible for any
        loss that you suffer as a result of the Customer Wallet details being
        incorrect if, pursuant to clause5.4we transfer the Security to the
        Customer Wallet specified in the Details.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>6.</strong>
        <strong>What you must pay</strong>
      </p>

      <p>
        You must repay to us all amounts you borrow from us, and you must pay us
        interest charges, all fees and charges (if any) and other amounts as
        specified under this [Product name] Agreement.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>7.</strong>
        <strong>Repayments</strong>
      </p>

      <p>
        7.1 Your statement of account for a Statement Period will set out the
        amount you must pay for that Statement Period, how you may pay it and
        when it must be paid.
      </p>

      <p>7.2 That amount will consist of:</p>

      <p>
        (a) your Repayment Amount &ndash; this amount is payable by the due date
        shown on the applicable statement;
      </p>

      <p>
        (b) any Overdue Amount &ndash; this amount is payable immediately if not
        paid already;
      </p>

      <p>
        (c) any other amount due under this [Product name] Agreement which is
        payable on demand, such as enforcement expenses.
      </p>

      <p>
        <strong>[Remove 7.3 - 7.7 if balloon loan]</strong>
      </p>

      <p>
        7.3At any time that the Balance Owing on your Loan Account is greater
        than zero, you must pay the Repayment Amount set out in each statement
        by the due date (specified in that statement). 7.4You must pay the
        Repayment Amount by the due date and you may pay more or all of the
        Balance Owing on your Loan Account if you wish. Unless we agree
        otherwise, your obligation to continue to make repayments on the dates
        and in the amounts due under this [Product name] Agreement is not
        changed or affected by any prepayment. 7.5Your obligation to pay the
        Repayment Amount does not affect your obligation to pay the whole or
        part of the Loan Amount Total on our demand or as payable immediately as
        set out in this [Product name] Agreement. 7.6We may apply any amount we
        receive from you to any part of the Balance Owing on your Loan Account
        in any order we choose. 7.7If you do not make the Repayment Amount by
        the due date, we may suspend the operation of your Loan Account until we
        receive payment.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Repayments due</strong>
      </p>

      <p>
        7.8 Your repayment frequency (weekly, fortnightly, monthly or single
        balloon repayment) is set out in the Details. 7.9If you are making
        regular repayments, after your first repayment, repayments are due:
      </p>

      <p>
        (a) for monthly repayments, on the same day as the first repayment in
        each following month. If for any payment there is no corresponding day
        to the day the previous monthly payment was made, the repayment is due
        on the last day of that month;
      </p>

      <p>
        (b) for fortnightly repayments, every 14 days after the first repayment;
        or
      </p>

      <p>(c) for weekly repayments, every 7 days after the first repayment.</p>

      <p>
        7.10 f you are making a single balloon repayment, the Total Amount Owing
        must be paid immediately at the end of the Loan Term.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Repayments due on a non-Business Day</strong>
      </p>

      <p>
        7.11 If a repayment is due on a day which is not a Business Day, you may
        make the repayment on the next Business Day. However, if that means that
        the repayment would be made in the next calendar month, then the
        repayment is payable on the last Business Day of the calendar month in
        which the repayment is due.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Changes to repayment amounts</strong>
      </p>

      <p>
        7.12 Your actual repayment may vary if the amount or frequency of any
        fee or charge (if any) changes or a new fee or charge is introduced.
        7.13We may change the amount of repayments as necessary to ensure that
        the Loan Amount and all other amounts you must pay are repaid within the
        Loan Term (for example, to make up for any repayments you have made
        late). 7.14If a change increases your Repayment Amount, we will notify
        the increase to you in writing (in accordance with clause17) before the
        increase takes effect.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Early repayment</strong>
      </p>

      <p>
        7.15 You are entitled to repay the Loan Amount Total or any payment due
        under this [Product name] Agreement at any time before the Loan Amount
        due date. 7.16No deduction in any fees or charges (if any) due under
        this [Product name] Agreement will be made as a result of the early
        payment made by you.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Payment methods</strong>
      </p>

      <p>
        7.17 Unless we agree otherwise,you must pay your repayments by
        permitting us to debit amounts directly from an account you have with a
        financial institution.You must not cancel any direct debit authorisation
        you give us or close the account referred to in any direct debit
        authorisation unless you first give us another direct debit
        authorisation acceptable to us. You must also ensure there is enough
        money in the account to meet each debit. 7.18We are unable to accept
        payments by:
      </p>

      <p>(a)international drafts, cheques or currency;</p>

      <p>(b)traveller&rsquo;s cheque;</p>

      <p>(c)credit card; or</p>

      <p>(d)cash.</p>

      <p>
        7.19 Repayments are not treated as made to the Loan Account until they
        are cleared. 7.20You must pay any amount due under this [Product name]
        Agreement without deduction or set-off (and irrespective of any
        counterclaim) whatsoever. If you believe that we owe you an amount, you
        may raise that claim with us separately. You agree that we may set-off
        any amount owed by us under this [Product name] Agreement against any
        amount owed to us under this [Product name] Agreement or any other
        contract between you and us, and that our liability to make payment will
        be reduced by the extent of any such set-off.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Repayment on demand</strong>
      </p>

      <p>
        7.21 We may demand that you repay the whole or part of the Loan Amount
        Total at any time by giving you 30 days&rsquo; notice in writing. You do
        not need to be in Default under this [Product name] Agreement for us to
        do so. You must repay in full the amount we demand at the time we
        specify in the demand. 7.22You acknowledge that there is no agreement,
        arrangement or understanding between you and us that we may demand
        repayment only when a particular event occurs or does not occur.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>8.</strong>
        <strong>Other Amounts</strong> 8.1You must pay us:
      </p>

      <p>
        (a)all fees and charges in the circumstances indicated in the Details
        and any changed or new fee or charge notified to you;
      </p>

      <p>
        (b)an amount equal to any government charges and duties on receipts or
        withdrawals under this [Product name] Agreement, calculated in
        accordance with the relevant legislation; and
      </p>

      <p>
        (c)when we ask, any reasonable expenses we reasonably incur in enforcing
        this [Product name] Agreement after you are in Default (including the
        use of our staff and facilities).
      </p>

      <p>
        8.2You authorise us to debit these amounts to your Loan Account. We may
        do so on or after the date we pay them or the date they become due or
        payable by you or us (whichever is earlier).
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>9.</strong>
        <strong>Interest charges</strong> 9.1You must pay us interest charges
        for each day on the Balance Owing on your Loan Account for the end of
        each day. Interest charges are calculated daily at the Annual Percentage
        Rate applying to the Balance Owing on your Loan Account for that day on
        the basis of a 365 day year (including in a leap year). 9.2The interest
        charges accrue daily on the Balance Owing on your Loan Account. They are
        debited:
      </p>

      <p>
        (a)at the end of the Loan Term, if you elect a single balloon repayment;
      </p>

      <p>(b)monthly, if you elect monthly repayments;</p>

      <p>(c)fortnightly, if you elect fortnightly repayments; or</p>

      <p>(d)weekly, if you elect weekly repayments,</p>

      <p>
        to your Loan Account as at the last day of each repayment period, and on
        the last day (if any) that you have your Loan Account.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Annual Percentage Rate</strong>
      </p>

      <p>
        9.3The Annual Percentage Rate that applies to your loan will be the rate
        specified in the Details.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>10.</strong>
        <strong>Default</strong> 10.1You are in Default if:
      </p>

      <p>
        (a)you do not pay on time any amount due under this [Product name]
        Agreement (this includes if our direct debit authorisation for your
        account is subsequently reversed by the paying institution);
      </p>

      <p>(b)the LVR exceeds 60%;</p>

      <p>
        (c)you do something you agree not to do or you fail to do something you
        agree to do under this [Product name] Agreement;
      </p>

      <p>
        (d)you give, or another person gives, us incorrect or misleading
        information in connection with this [Product name] Agreement (including
        in your Loan Amount Application to us);
      </p>

      <p>
        (e)we reasonably believe you or another person has acted fraudulently in
        connection with this [Product name] Agreement;
      </p>

      <p>
        (f)you cancel the direct debit authorisation you have given us and, at
        or before that cancellation, you do not provide a replacement direct
        debit authorisation to us; or
      </p>

      <p>
        (g)you become insolvent or steps are taken to have you declared
        bankrupt.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>What can happen then?</strong>
      </p>

      <p>
        10.2If you are in Default, we may give you a notice stating that you are
        in Default. We will not charge you Default Interest on the Loan Amount
        Total. 10.3Enforcement expenses may become payable under this [Product
        name] Agreement if you are in Default. 10.4The Default in
        clause10.1(b)may only be remedied by you either:
      </p>

      <p>
        (a)paying money to us to reduce the Balance Owing on your Loan Account;
        or
      </p>

      <p>(b)transferring cryptocurrency to us as additional Security,</p>

      <p>
        in an amount that results in the LVR being less than 60%. If you pay
        money to us to reduce the Balance Owing on your Loan Account, that
        amount will be taken to reduce the Loan Amount for the purpose of
        calculating the LVR for the remainder of the term of this [Product name]
        Agreement.
      </p>

      <p>
        10.5If you do not, or cannot, correct the Default within any grace
        period given in the notice or required by law (or if you are in Default
        again for a similar reason at the end of that period), then, at the end
        of that period and without further notice to you, the Loan Amount Total
        becomes immediately due for payment (to the extent it is not already due
        for payment). We will then sell such part of the Security as is
        necessary to cover the costs of sale and the Balance Owing on your Loan
        Account, or all of the Security if the Security is insufficient.
        10.6Notwithstanding clauses10.2,10.4and10.5, if the LVR exceeds 85% at
        any time, we will sell the Security to satisfy the Balance Owing on your
        Loan Account, without giving prior notice to you. 10.7You agree that our
        exercise of rights under clause10.6constitutes seizure of the Security
        for the purposes of the PPSA and that we are not required to give notice
        of seizure under the PPSA. 10.8To the maximum extent permitted by law,
        you waive any right under the PPSA to receive any notice that we would
        otherwise be required to give you under the PPSA. 10.9We are not
        responsible for any loss that you may suffer as a result of the exercise
        or non-exercise of our rights under clause10.6.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>11.</strong>
        <strong>Loan account and transactions</strong> 11.1You authorise us to
        open a Loan Account in your name and to debit to it each part of the
        amount you borrow on the date we lend it and any amount which you must
        pay under this [Product name] Agreement on or after the date it becomes
        due, without first notifying you. 11.2We may assign any date we consider
        appropriate to a debit or credit to your Loan Account. However, for a
        debit that date will not be earlier than the date on which a debit
        occurs. We will credit payments to your Loan Account as soon as
        practicable after we receive them. This is not necessarily on the same
        day that you pay. 11.3We may subsequently adjust debits and credits to
        the Loan Account and the Balance Owing on your Loan Account so as to
        accurately reflect the legal obligations of you and us (for example,
        because of an error or because a direct debit authority is dishonoured).
        If we do this, we may make consequential changes (including interest
        charges).
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>General Terms and Conditions</strong>
      </p>

      <p>
        <strong>12.</strong>
        <strong>Verification of identity</strong>
      </p>

      <p>
        By submitting this application, you acknowledge that you are personally
        liable for performing the obligations set out in this [Product name]
        Agreement and for paying all monies due under this [Product name]
        Agreement. You agree to provide us with all information (including your
        personal information, such as your name, address and date of birth) or
        documentation reasonably required by us to enable us to verify your
        identity in accordance with the
        <em>Anti-Money Laundering and Counter-Terrorism Financing Act 2006</em>
        (Cth). You consent to us using and disclosing such information for this
        purpose. You warrant that all such information provided to us is true
        and correct and acknowledge that if we are unable to verify your
        identity as required, we will not be able to provide the services under
        this [Product name] Agreement to you.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>13.</strong>
        <strong>Statements</strong>
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Frequency</strong>
      </p>

      <p>
        13.1We will give you a statement of your Loan Account for each Statement
        Period. The Statement Period will correspond with your repayment
        frequency. Subject to13.2below, if you elect:
      </p>

      <p>(a)monthly repayments, you will receive a statement each month;</p>

      <p>
        (b)fortnightly repayments, you will receive a statement each fortnight;
      </p>

      <p>(c)weekly repayments, you will receive a statement each week; and</p>

      <p>
        (d)a single balloon repayment, you will receive a statement at the end
        of the Loan Term.
      </p>

      <p>13.2We need not give you a statement if:</p>

      <p>
        (a)the Annual Percentage Rate is fixed for the life of the contract and
        no rate variation is permitted;
      </p>

      <p>
        (b)no amount has been debited or credited to your Loan Account during
        the Statement Period and the Balance Owing on your Loan Account is less
        than $10;
      </p>

      <p>
        (c)no amount was debited or credited to your Loan Account and we wrote
        off your debt during the Statement Period; or
      </p>

      <p>
        (d)you have been in Default under the [Product name] Agreement for at
        least 120 days and we did not provide further credit to you during the
        Statement Period.
      </p>

      <p>
        13.3You must check the entries on your statement carefully. 13.4You must
        tell us as soon as possible if you notice any error or unauthorised
        transaction on your statement.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Electronic Statements</strong>
      </p>

      <p>
        13.5We may give statements, notices and other information (
        <strong>Information</strong>):
      </p>

      <p>(a)by email; or</p>

      <p>(b)by making them available on the [Loda Portal], provided we:</p>

      <ol>
        <li>
          tell you by email or SMS message that this Information is available;
          and
        </li>
        <li>allow you to readily access and store this Information.</li>
      </ol>

      <p>
        13.6If you enrol to receive Information by email or by accessing the
        [Loda Portal], you will not generally receive paper copies unless you
        specifically ask for them. We may, however (but are not obliged to),
        send you paper copies from time to time at our discretion. 13.7You may
        change your mind and ask to get Information by mail at any time by
        contacting our support team. You can do this by sending an email to
        [Loda to insert contact email]. 13.8If you enrol to receive Information
        by email, we will send all the Information to the most recent email
        address that you gave us. You must tell us if your email address changes
        as soon as possible. You may do this by contacting our support team.
        13.9If you enrol to receive Information through the [Loda Portal], we
        will send you an SMS message whenever new Information is available for
        you. We will send this information to the most recent mobile phone
        number that you gave us. You must tell us as soon as possible if your
        mobile phone number has changed. You may do this by contacting our
        support team. 13.10If you enrol to receive Information by email or in
        some other electronic form, you must check electronic communications
        regularly for such information.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Repayment Reminders</strong>
      </p>

      <p>
        13.11 Before each due date for a repayment, we will send you a SMS
        message to the mobile phone number that you provided to us, confirming
        the amount that needs to be repaid, the due date and providing a link to
        the [Loda Portal] with further information. This SMS message is not a
        statement of account, and serves simply as a reminder.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>14.</strong>
        <strong>Our obligations</strong>
      </p>

      <p>
        We will comply with all applicable laws (including the National Credit
        Code, if applicable) and administrative requirements for the performance
        of our obligations under this [Product name] Agreement and to carry out
        the transactions contemplated by this [Product name] Agreement.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>15.</strong>
        <strong>Your obligations and liabilities</strong>
      </p>

      <p>
        15.1You indemnify us against any loss or claim we may suffer due to your
        material breach of this [Product name] Agreement, a breach of your
        warranties as set out in clause16, your negligence in relation to this
        [Product name] Agreement, or otherwise due to your acts or omissions,
        with our recourse to such indemnity to be limited to the amount which we
        would otherwise be able to recover at general law for your breach of
        contract or negligence in respect of this [Product name] Agreement, plus
        legal costs on a full indemnity basis.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>16.</strong>
        <strong>Warranties</strong>
      </p>

      <p>14.1 You represent and warrant to us that:</p>

      <p>
        (a)you have provided us with sufficient information about your income
        and expenses which is able to be verified by us;
      </p>

      <p>
        (b)all information and supporting documentation provided to us is, to
        the best of your knowledge, true and correct;
      </p>

      <p>
        (c)you will advise us of any change in circumstances which affects, or
        is likely to affect, the accuracy of any of the above warranties, or
        your ability to pay the Loan Amount Total by the Loan Amount Due Date;
      </p>

      <p>
        (d)you own any cryptocurrency that you transfer to us as Security or are
        acting with the authority of the owner of the cryptocurrency in
        transferring it to us as Security; and
      </p>

      <p>
        (e)the owner of the Customer Wallet is the same person who owned the
        cryptocurrency immediately prior to it being provided as Security.
      </p>

      <p>
        14.2 Unless required by law, we do not guarantee continuous,
        uninterrupted or secure access to credit, and we make no representations
        or warranties regarding the amount of time needed to complete processing
        of credit applications.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>17.</strong>
        <strong>Communications and billing</strong> 17.1Notices and statements
        under this [Product name] Agreement must be sent in writing, including
        in electronic form via email or SMS, unless otherwise stated.
        17.2Communications from us to you may be:
      </p>

      <p>(a)sent electronically by email or SMS;</p>

      <p>(b)given personally;</p>

      <p>(c)left at your residential or business address last known to us;</p>

      <p>
        (d)sent by post to your postal or residential or business address last
        known to us; or
      </p>

      <p>(e)given in any other way permitted by law.</p>

      <p>17.3Communications from you to us must be:</p>

      <p>
        (a)sent to the relevant email address as notified to you from time to
        time;
      </p>

      <p>
        (b)given personally to one of our employees at the address stated in the
        Details or as otherwise notified to you from time to time; or
      </p>

      <p>
        (c)sent by prepaid post to the address stated in the Details or as
        otherwise notified to you from time to time; or
      </p>

      <p>(d)given in any other way permitted by law.</p>

      <p>
        17.4Unless the sender receives evidence to the contrary, a notice shall
        be taken to be delivered:
      </p>

      <p>(a)in the case of delivery by hand, when delivered;</p>

      <p>
        (b)in the case of delivery by post, on the third (seventh, if sent to an
        address outside Australia) day after the date of posting; or
      </p>

      <p>(c)in the case of delivery by email or SMS, at the time of sending.</p>

      <p>17.5By submitting this application, you agree that we can:</p>

      <p>
        (a)contact you via any phone number or address that you provide to us;
        and
      </p>

      <p>(b)provide any notices by electronic communication.</p>

      <p>
        17.6T he electronic communication methods we will use are email and SMS.
        You must provide us with a valid email address so that we can
        communicate with you, and you must inform us immediately if your email
        address changes by emailing[Loda to insert contact email]. 17.7We may
        serve any document in a court action (including a writ of summons, other
        originating process or third or other party notice) on you by delivering
        it to your residential address in the Details (or any changed
        residential address notified to us) or by leaving it there. This does
        not prevent any other method of service.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>18.</strong>
        <strong>Changes</strong>
      </p>

      <p>
        18.1Subject to these General Terms and the Details, we may change any
        provision (other than the Annual Percentage Rate) of this [Product name]
        Agreement without your consent, including changing:
      </p>

      <p>(a)the way we calculate or apply interest;</p>

      <p>(b)the way we calculate repayments or when they are due; and</p>

      <p>(c)when we give you a statement for your Loan Account,</p>

      <p>for one or more of the following reasons:</p>

      <p>
        (a)to comply with any change or anticipated change in any relevant law,
        code of practice or guidance;
      </p>

      <p>(b)to reflect any decision of a court, ombudsman or regulator;</p>

      <p>
        (c)to reflect a change in our systems or procedures including for
        security reasons;
      </p>

      <p>
        (d)as a result of changed circumstances (including by adding benefits or
        new features);
      </p>

      <p>
        (e)to respond proportionately to changes in the cost of providing the
        Loan Amount; or
      </p>

      <p>(f)to make the provisions of this [Product name] Agreement clearer.</p>

      <p>
        18.2We will not change any fee or charge, or introduce a new fee or
        charge, unless the change is required to comply with any change or
        anticipated change in any relevant law (in which case, we may make the
        relevant change to this [Product name] Agreement without your consent).
        18.3We will not change any provision of this [Product name] Agreement if
        any applicable law (such as the National Credit Code) prohibits the
        change. 18.4We will give you at least 20 days&rsquo; notice in writing
        of any change. 18.5The fees and charges shown in the Details are current
        at the Disclosure Date.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>19.</strong>
        <strong>National Credit Code</strong> 19.1This clause applies to the
        extent that the National Credit Code applies to this [Product name]
        Agreement. 19.2If:
      </p>

      <p>
        (a)the National Credit Code would otherwise make a provision of this
        [Product name] Agreement illegal, void or unenforceable; or
      </p>

      <p>
        (b)a provision of this [Product name] Agreement would otherwise
        contravene a requirement of the National Credit Code or impose an
        obligation or liability which is prohibited by the National Credit Code,
      </p>

      <p>
        this [Product name] Agreement is to be read as if that provision were
        varied to the extent necessary to comply with the National Credit Code
        or, if necessary, omitted.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>20.</strong>
        <strong>How we may exercise our rights</strong> 20.1We may exercise a
        right or remedy or give or refuse our consent in any way we reasonably
        consider appropriate including by imposing conditions. 20.2If we do not
        exercise a right or remedy fully at a given time, we can still exercise
        it later. 20.3Our rights and remedies under this [Product name]
        Agreement are in addition to other rights and remedies provided by law
        independently of it. 20.4We are not liable for loss caused by the
        exercise or attempted exercise of, failure to exercise, or relay in
        exercising, a right or remedy.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>21.</strong>
        <strong>Problems, errors and complaints</strong> 21.1You agree that we
        may fill in any blanks in any document related to this [Product name]
        Agreement (such as an acknowledgment). 21.2If you have a problem or
        complaint about the Loan Account or believe an error has been made, you
        should speak to our support team. You can do this by sending an email
        to[Loda to insert contact email]. 21.3To assist us in resolving your
        problem or complaint, you should:
      </p>

      <p>(a)report it promptly;</p>

      <p>
        (b)state clearly the nature of the problem or your particular grievance;
        and
      </p>

      <p>(c)have available all relevant documents.</p>

      <p>21.4We aim to:</p>

      <p>
        (a) acknowledge receipt of all complaints within 5 Business Days; and
      </p>

      <p>(b) resolve all complaints within 21 days.</p>

      <p>
        21.5We may request additional documentation from you to assist us in
        resolving your complaint, and you must provide all reasonable assistance
        to us to facilitate us in resolving your complaint. 21.6Where we cannot
        resolve a complaint within21days, we will notify you of the reason for
        the delay as well as an indication of when we expect to resolve the
        complaint or dispute.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>22.</strong>
        <strong>Applicable law</strong>
      </p>

      <p>
        This [Product name] Agreement is governed by the law in force in the
        place where you reside as at the date of our acceptance of your offer.
        You agree to submit to the non-exclusive jurisdiction of the courts that
        have jurisdiction under the law of that place, and the law of the
        Commonwealth of Australia, if applicable.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>23.</strong>
        <strong>GST</strong> 23.1Words or expressions used in this clause that
        are defined in
        <em>A New Tax System (Goods and Services Tax) Act 1999</em>(Cth) (
        <strong>GST Act</strong>) have the same meaning given to them in that
        Act. 23.2If we are liable to pay goods and services tax (
        <strong>GST</strong>) or any similar tax on a supply made in connection
        with this [Product name] Agreement, you must pay us an additional amount
        equal to the consideration payable for the supply multiplied by the
        prevailing GST rate.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>24.</strong>
        <strong>Privacy Policy</strong>
      </p>

      <p>
        Your privacy is very important to us, which is why we have created a
        separate Privacy Policy in order to explain in detail how we collect,
        manage, process, secure, and store your personal information. You can
        access our Privacy Policy at thislink.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>25.</strong>
        <strong>Entire Agreement</strong>
      </p>

      <p>
        Unless otherwise stated, and to the maximum extent permitted by law,
        these General Terms and the Details constitute the entire agreement of
        the parties about their subject matter and supersede all other
        representations, arrangements or agreements. Other than as expressly set
        out in these General Terms and the Details, you agree that you have not
        relied on any representation made by or on our behalf.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>26.</strong>
        <strong>Termination</strong>
      </p>

      <p>
        Any party may terminate this [Product name] Agreement at any time upon
        providing reasonable written notice to the other parties. You are
        required to pay all amounts owing to us under this [Product name]
        Agreement within two Business Days of termination of the [Product name]
        Agreement.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>27.</strong>
        <strong>Assignment and other dealings</strong> 27.1We may assign,
        novate, or otherwise deal with our rights under this [Product name]
        Agreement in any way we consider appropriate without your consent. We
        will notify you in advance of any assignment. You must execute all
        documents which in our opinion are necessary for these purposes. 27.2You
        must not assign, novate, transfer, or deal with your rights or
        obligations under this [Product name] Agreement without our written
        consent.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>28.</strong>
        <strong>Difficulties in paying</strong>
      </p>

      <p>
        If you have difficulties paying any amount you owe us under this
        [Product name] Agreement, you should contact us as soon as possible. Any
        variation to these terms shall be subject to written agreement between
        the parties.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>29.</strong>
        <strong>Consumer Law</strong>
      </p>

      <p>
        Nothing in this [Product name] Agreement is intended to exclude,
        restrict or modify any non-excludable provision of the Consumer Laws,
        including by limiting our liability or imposing liability on you in a
        manner which would be considered unfair under the Consumer Laws.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>30.</strong>
        <strong>Definitions and Interpretation</strong>
      </p>

      <p>
        <strong>Annual Percentage Rate</strong>means the rate specified in the
        Details.
      </p>

      <p>
        <strong>Balance Owing on your Loan Account</strong>means, for a Loan
        Account, at any time, the difference between all amounts credited and
        all amounts debited to the Loan Account at that time. When this amount
        is to be calculated for the end of a day, it includes all debits and
        credits assigned to that day.
      </p>

      <p>
        <strong>Business Day</strong>means a day other than a Saturday, Sunday
        or public holiday in [Queensland], Australia.
      </p>

      <p>
        <strong>Commencement Date</strong>means the date we transfer the Loan
        Amount to you.
      </p>

      <p>
        <strong>Consumer Laws</strong>means the
        <em>Australian Securities and Investments Commission Act 2001</em>
        (Cth) and the<em>Competition and Consumer Act 2010</em>(Cth).
      </p>

      <p>
        <strong>Customer Wallet</strong>means the cryptocurrency wallet
        identified in the Details.
      </p>

      <p>
        <strong>[Product name] Agreement</strong>means these General Terms and
        the Details.
      </p>

      <p>
        <strong>Default</strong>means the circumstances described in clause10.1.
      </p>

      <p>
        <strong>Details</strong>means the document titled &ldquo;[Product name]
        Agreement Details&rdquo; we provide to you when we offer you the Loan
        (and which forms part of the [Product name] Agreement).
      </p>

      <p>
        <strong>Disclosure Date</strong>means the date stated in the Details.
      </p>

      <p>
        <strong>[Loda Portal]</strong>means the webpage through which you lodge
        your[Product name] Application.
      </p>

      <p>
        <strong>General Terms</strong>means these [Product Name] General Terms
        and Conditions.
      </p>

      <p>
        <strong>Loan</strong>means a personal loan (as set out in the Details)
        which we provide to you under this [Product name] Agreement.
      </p>

      <p>
        <strong>Loan Account</strong>means, for a Loan, the account we establish
        in your name for recording all transactions in connection with the Loan.
      </p>

      <p>
        <strong>Loan Amount</strong>means the maximum total amount of credit
        that we will provide under this [Product name] Agreement, as specified
        in the Details.
      </p>

      <p>
        <strong>Loan Amount Total</strong>means, at any time, the Balancing
        Owing on your Loan Account at the time, plus all accrued interest
        charges and other amounts which you must pay under this [Product name]
        Agreement but which have not been debited to your Loan Account at that
        time.
      </p>

      <p>
        <strong>Loan Term</strong>means the term of the loan as set out in the
        Details.
      </p>

      <p>
        <strong>LVR</strong>means, at any time, the Loan Amount divided by the
        market value of the Security at that time (multiplied by 100% to be
        expressed as a percentage).
      </p>

      <p>
        <strong>Market Value</strong>of the Security means, at any time, the
        &ldquo;last price&rdquo; quoted for the Security against AUD by [Insert
        exchange name and web address].
      </p>

      <p>
        <strong>National Credit Code</strong>means Schedule 1 to the
        <em>National Consumer Credit Protection Act 2009</em>(Cth).
      </p>

      <p>
        <strong>Overdue Amount</strong>means any part of a Repayment Amount that
        was not paid by the due date set out in the relevant statement for a
        Statement Period.
      </p>

      <p>
        <strong>PPSA</strong>means the
        <em>Personal Property Securities Act 2010</em>(Cth) and any regulations
        made under that Act
      </p>

      <p>
        <strong>Repayment Amount</strong>means the amount owing as set out in
        the statement of account.
      </p>

      <p>
        <strong>Statement Period</strong>has the meaning provided in13.1and
        <strong>Statement Date</strong>means the last day of a Statement Period.
      </p>

      <p>
        <strong>Security</strong>means, at any time, the amount, in the relevant
        cryptocurrency, specified in the Details, plus any additional
        cryptocurrency that you transfer to us under this [Product name]
        Agreement, less any cryptocurrency that we sell in exercise of our
        rights under this [Product name] Agreement;
      </p>

      <p>
        <strong>Security Wallet</strong>means the cryptocurrency wallet
        identified in the Details;
      </p>

      <p>&nbsp;</p>

      <p>A reference to:</p>

      <p>(a)any thing includes the whole and each part of it;</p>

      <p>(b)a document includes any variation or replacement of it;</p>

      <p>
        (c)law means common law, principles of equity, and laws made by
        parliament (and laws made by parliament include regulations and other
        instruments under them, and consolidations, amendments, re-enactments or
        replacement of them);
      </p>

      <p>
        (d)the words &ldquo;including&rdquo;, &ldquo;for example&rdquo; or
        &ldquo;such as&rdquo; when introducing an example, do not limit the
        meaning of the words to which the example relates to that example or
        examples of a similar kind;
      </p>

      <p>
        (e)the word person includes an individual, a firm, a body corporate, an
        unincorporated association or an authority;
      </p>

      <p>(f)the singular includes the plural and vice versa; and</p>

      <p>
        (g)headings are for convenience only and do not affect the
        interpretation of this [Product name] Agreement.
      </p>

      <p>&nbsp;</p>

      <p>&nbsp;</p>

      <p>
        <strong>
          Things you should know about your proposed creditcontract
        </strong>
      </p>

      <p>
        This statement tells you about some of the rights and obligations of
        yourself and your credit provider. It does not state the terms and
        conditions of your contract.
      </p>

      <p>
        If you have any concerns about your contract, contact the credit
        provider and, if you still have concerns,the AFCA scheme, or get legal
        advice.
      </p>

      <p>
        <strong>The contract</strong>
      </p>

      <p>
        <strong>1 How can I get Details of my proposed credit contract?</strong>
      </p>

      <p>
        Your credit provider must give you a precontractual statement containing
        certain information about your contract. The precontractual statement,
        and this document, must be given to you before&mdash;
      </p>

      <p>&frac34;your contract is entered into; or</p>

      <p>&frac34;you make an offer to enter into the contract;</p>

      <p>whichever happens first.</p>

      <p>
        <strong>2 How can I get a copy of the final contract?</strong>
      </p>

      <p>
        If the contract document is to be signed by you and returned to your
        credit provider, you must be given a copy to keep. Also, the credit
        provider must give you a copy of the final contract within 14 days after
        it is made. This rule does not, however, apply if the credit provider
        has previously given you a copy of the contract document to keep.
      </p>

      <p>
        If you want another copy of your contract, write to your credit provider
        and ask for one. Your credit provider may charge you a fee. Your credit
        provider has to give you a copy&mdash;
      </p>

      <p>
        &frac34;within 14 days of your written request if the original contract
        came into existence 1 year or less before your request; or
      </p>

      <p>&frac34;otherwise within 30 days of your written request.</p>

      <p>
        <strong>3 Can I terminate the contract?</strong>
      </p>

      <p>
        Yes. You can terminate the contract by writing to the credit provider so
        long as&mdash;
      </p>

      <p>&frac34;you have not obtained any credit under the contract; or</p>

      <p>
        &frac34;a card or other means of obtaining credit given to you by your
        credit provider has not been used to acquire goods or services for which
        credit is to be provided under the contract.
      </p>

      <p>
        However, you will still have to pay any fees or charges incurred before
        you terminated the contract.
      </p>

      <p>
        <strong>4 Can I pay my credit contract out early?</strong>
      </p>

      <p>
        Yes. Pay your credit provider the amount required to pay out your credit
        contract on the day you wish to end your contract.
      </p>

      <p>
        <strong>5 How can I find out the pay out figure?</strong>
      </p>

      <p>
        You can write to your credit provider at any time and ask for a
        statement of the pay out figure as at any date you specify. You can also
        ask for Details of how the amount is made up.
      </p>

      <p>
        Your credit provider must give you the statement within 7 days after you
        give your request to the credit provider. You may be charged a fee for
        the statement.
      </p>

      <p>
        <strong>
          6 Will I pay less interest if I pay out my contract early?
        </strong>
      </p>

      <p>
        Yes. The interest you can be charged depends on the actual time money is
        owing. However, you may have to pay an early termination charge (if your
        contract permits your credit provider to charge one) and other fees.
      </p>

      <p>
        <strong>7 Can my contract be changed by my credit provider?</strong>
      </p>

      <p>Yes, but only if your contract says so.</p>

      <p>
        <strong>
          8 Will I be told in advance if my credit provider is going to make a
          change in the contract?
        </strong>
      </p>

      <p>That depends on the type of change. For example&mdash;</p>

      <p>
        &frac34;you get at least same day notice for a change to an annual
        percentage rate. That notice may be a written notice to you or a notice
        published in a newspaper.
      </p>

      <p>&frac34;you get 20 days advance written notice for&mdash;</p>

      <p>a change in the way in which interest is calculated; or</p>

      <p>a change in credit fees and charges; or</p>

      <p>any other changes by your credit provider;</p>

      <p>
        except where the change reduces what you have to pay or the change
        happens automatically under the contract.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>
          9 Is there anything I can do if I think that my contract is unjust?
        </strong>
      </p>

      <p>
        Yes. You shouldfirsttalk to your credit provider. Discuss the matter and
        see if you can come to some arrangement.
      </p>

      <p>
        If that is not successful, you may contactthe AFCA scheme.The AFCA
        scheme is a free serviceestablishedto provide you with an independent
        mechanism to resolve specific complaints.The AFCA schemecan be contacted
        at 1800 931 678, by writing to GPO Box 3 Melbourne, VIC 3001, emailing
        <a href="mailto:info@afca.org.au">info@afca.org.au</a>or by lodging a
        complaint online at www.afca.org.au
      </p>

      <p>
        Alternatively, you can go to court. You may wish to get legal advice,
        forexamplefrom your community legal centre or Legal Aid.
      </p>

      <p>
        You can also contact ASIC, the regulator, for information on 1300300630
        or through ASIC&rsquo;s website athttp://www.asic.gov.au.
      </p>

      <p>
        <strong>Insurance</strong>
      </p>

      <p>
        <strong>10 Do I have to take out insurance?</strong>
      </p>

      <p>
        Your credit provider can insist you take out or pay the cost of types of
        insurance specifically allowed by law. These are compulsory third party
        personal injury insurance, mortgage indemnity insurance or insurance
        over property covered by any mortgage. Otherwise, you can decide if you
        want to take out insurance or not. If you take out insurance, the credit
        provider can not insist that you use any particular insurance company.
      </p>

      <p>
        <strong>11 Will I get Details of my insurance cover?</strong>
      </p>

      <p>
        Yes, if you have taken out insurance over mortgaged property or consumer
        credit insurance and the premium is financed by your credit provider. In
        that case the insurer must give you a copy of the policy within 14 days
        after the insurer has accepted the insurance proposal.
      </p>

      <p>
        Also, if you acquire an interest in any such insurance policy which is
        taken out by your credit provider then, within 14 days of that
        happening, your credit provider must ensure you have a written notice of
        the particulars of that insurance.
      </p>

      <p>
        You can always ask the insurer for Details of your insurance contract.
        If you ask in writing, your insurer must give you a statement containing
        all the provisions of the contract.
      </p>

      <p>
        <strong>
          12 If the insurer does not accept my proposal, will I be told?
        </strong>
      </p>

      <p>
        Yes, if the insurance was to be financed by the credit contract. The
        insurer will inform you if the proposal is rejected.
      </p>

      <p>
        <strong>13 In that case, what happens to the premiums?</strong>
      </p>

      <p>
        Your credit provider must give you a refund or credit unless the
        insurance is to be arranged with another insurer.
      </p>

      <p>
        <strong>
          14 What happens if my credit contract ends before any insurance
          contract over mortgaged property?
        </strong>
      </p>

      <p>
        You can end the insurance contract and get a proportionate rebate of any
        premium from the insurer.
      </p>

      <p>
        <strong>Mortgages</strong>
      </p>

      <p>
        <strong>
          15 If my contract says I have to give a mortgage, what does this mean?
        </strong>
      </p>

      <p>
        A mortgage means that you give your credit provider certain rights over
        any property you mortgage. If you default under your contract, you can
        lose that property and you might still owe money to the credit provider.
      </p>

      <p>
        <strong>16 Should I get a copy of my mortgage?</strong>
      </p>

      <p>
        Yes. It can be part of your credit contract or, if it is a separate
        document, you will be given a copy of the mortgage within 14 days after
        your mortgage is entered into.
      </p>

      <p>
        However, you need not be given a copy if the credit provider has
        previously given you a copy of the mortgage document to keep.
      </p>

      <p>
        <strong>
          17 Is there anything that I am not allowed to do with the property I
          have mortgaged?
        </strong>
      </p>

      <p>
        The law says you can not assign or dispose of the property unless you
        have your credit provider&rsquo;s, or the court&rsquo;s, permission. You
        must also look after the property. Read the mortgage document as well.
        It will usually have other terms and conditions about what you can or
        can not do with the property.
      </p>

      <p>
        <strong>
          18 What can I do if I find that I can not afford my repayments and
          there is a mortgage over property?
        </strong>
      </p>

      <p>See the answers to questions 22 and 23.</p>

      <p>Otherwise you may&mdash;</p>

      <p>
        &frac34;if the mortgaged property is goods &mdash; give the property
        back to your credit provider, together with a letter saying you want the
        credit provider to sell the property for you;
      </p>

      <p>
        &frac34;sell the property, but only if your credit provider gives
        permission first;
      </p>

      <p>OR</p>

      <p>
        &frac34;give the property to someone who may then take over the
        repayments, but only if your credit provider gives permission first.
      </p>

      <p>
        If your credit provider won&rsquo;t give permission, you can contactthe
        AFCA scheme for help.
      </p>

      <p>
        If you have a guarantor, talk to the guarantor who may be able to help
        you.
      </p>

      <p>
        You should understand that you may owe money to your credit provider
        even after the mortgaged property is sold.
      </p>

      <p>
        <strong>
          19 Can my credit provider take or sell the mortgaged property?
        </strong>
      </p>

      <p>
        Yes, if you have not carried out all of your obligations under your
        contract.
      </p>

      <p>
        <strong>
          20 If my credit provider writes asking me where the mortgaged goods
          are, do I have to say where they are?
        </strong>
      </p>

      <p>
        Yes. You have 7 days after receiving your credit provider&rsquo;s
        request to tell your credit provider. If you do not have the goods you
        must give your credit provider all the information you have so they can
        be traced.
      </p>

      <p>
        <strong>
          21 When can my credit provider or its agent come into a residence to
          take possession of mortgaged goods?
        </strong>
      </p>

      <p>
        Your credit provider can only do so if it has the court&rsquo;s approval
        or the written consent of the occupier which is given after the occupier
        is informed in writing of the relevant section in the National Credit
        Code.
      </p>

      <p>
        <strong>General</strong>
      </p>

      <p>
        <strong>22 What do I do if I can not make a repayment?</strong>
      </p>

      <p>
        Get in touch with your credit provider immediately. Discuss the matter
        and see if you can come to some arrangement. You can ask your credit
        provider to change your contract in a number of ways&mdash;
      </p>

      <p>&frac34;to extend the term of your contract and reduce payments; or</p>

      <p>
        &frac34;to extend the term of your contract and delay payments for a set
        time; or
      </p>

      <p>&frac34;to delay payments for a set time.</p>

      <p>
        <strong>
          23 What if my credit provider and I can not agree on a suitable
          arrangement?
        </strong>
      </p>

      <p>
        If the credit provider refuses your request to change the repayments,
        you can ask the credit provider to review this decision if you think it
        is wrong.
      </p>

      <p>
        If the credit provider still refuses your request you can complain to
        theAFCA scheme. Further Details about this scheme are set out below in
        question 25.
      </p>

      <p>
        <strong>24 Can my credit provider take action against me?</strong>
      </p>

      <p>
        Yes, if you are in default under your contract. But the law says that
        you can not be unduly harassed or threatened for repayments. If you
        think you are being unduly harassed or threatened, contactthe AFCA
        schemeor ASIC, or get legal advice.
      </p>

      <p>
        <strong>25 Do I have any other rights and obligations?</strong>
      </p>

      <p>
        Yes. The law will give you other rights and obligations. You should also
        READ YOUR CONTRACT carefully.
      </p>

      <p>
        <strong>
          IF YOU HAVE ANY COMPLAINTS ABOUT YOUR CREDIT CONTRACT, OR WANT MORE
          INFORMATION, CONTACT YOUR CREDIT PROVIDER. YOU MUST ATTEMPT TO RESOLVE
          YOUR COMPLAINT WITH YOUR CREDIT PROVIDER BEFORE CONTACTING THE AFCA
          SCHEME. IF YOU HAVE A COMPLAINT WHICH REMAINS UNRESOLVED AFTER
          SPEAKING TO YOUR CREDIT PROVIDER YOU CAN CONTACT THE AFCA SCHEME OR
          GET LEGAL ADVICE.
        </strong>
      </p>

      <p>
        <strong>
          THE AFCA SCHEME IS A FREE SERVICE ESTABLISHED TO PROVIDE YOU WITH AN
          INDEPENDENT MECHANISM TO RESOLVE SPECIFIC COMPLAINTS.THE AFCA
          SCHEMECAN BE CONTACTED AT 1800 931 678, BY WRITING TO GPO BOX 3
          MELBOURNE, VIC 3001, EMAILING INFO@AFCA.ORG.AU OR LODGING A COMPLAINT
          ONLINE AT WWW.AFCA.ORG.AU.
        </strong>
      </p>

      <p>
        <strong>
          PLEASE KEEP THIS INFORMATION STATEMENT. YOU MAY WANT SOME INFORMATION
          FROM IT AT A LATER DATE.
        </strong>
      </p>
    </div>
  );
};

export default LoanTermsAndConditions;
