import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useGlobalError from "../../hooks/useGlobalError";
import IdpAddPhoneNumberView, { Step } from "./IdpAddPhoneNumber_View";

type Props = {
  phoneNumber?: string;
  updateNumber?: (data: server.AddPhoneNumberRequestBody) => Promise<void>;
  verifyNumber: (data: server.VerifyPhoneNumberRequestBody) => Promise<void>;
  isTwoFAEnabled?: boolean;
};

const IdpAddPhoneNumber: React.FC<Props> = ({
  updateNumber,
  verifyNumber,
  isTwoFAEnabled,
  ...props
}) => {
  const { setGlobalError } = useGlobalError();

  const [step, setStep] = useState<Step>("update");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    props.phoneNumber || ""
  );
  const [incorrectCode, setIncorrectCode] = useState(false);
  const [existingPhoneNumber, setExistingPhoneNumber] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [signinFlow, setSigninFlow] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!updateNumber || isTwoFAEnabled) {
      setPhoneNumber("");
      setSigninFlow(true);
      setStep("verify");
    }
  }, [updateNumber, isTwoFAEnabled]);

  const updatePhoneNumber = async (data: server.AddPhoneNumberRequestBody) => {
    setInvalidPhoneNumber(false);
    setExistingPhoneNumber(false);
    setSessionExpired(false);
    try {
      const phoneNumber = data.phoneNumber;
      setRecaptcha(false);
      await updateNumber!({ phoneNumber, isMfa: true });
      setPhoneNumber(phoneNumber);
      setStep("verify");
    } catch (err) {
      setRecaptcha(true);

      const error: server.ErrorResponse = err as server.ErrorResponse;
      if (error.statusCode && error.statusCode === 400) {
        return setExistingPhoneNumber(true);
      }
      if (error.code) {
        if (error.code === "auth/invalid-phone-number") {
          return setInvalidPhoneNumber(true);
        } else if (error.code === "auth/requires-recent-login") {
          return setSessionExpired(true);
        } else if (error.code === "auth/unverified-email") {
          setStep("unverifiedEmail");
          return;
        }
      }

      setGlobalError(err as server.ErrorResponse);
    }
  };

  const verifyPhoneNumber = async (
    data: server.VerifyPhoneNumberRequestBody
  ) => {
    try {
      await verifyNumber(data);
    } catch (err) {
      const error: server.ErrorResponse = err as server.ErrorResponse;
      if (error.code === "auth/invalid-verification-code") {
        return setIncorrectCode(true);
      } else if (error.code === "auth/unverified-email") {
        return setStep("unverifiedEmail");
      }
      setGlobalError(err as server.ErrorResponse);
    }
  };

  const reset = () => {
    if (signinFlow) {
      history.push("/login");
    } else {
      setPhoneNumber("");
      setStep("update");
    }
  };

  return (
    <IdpAddPhoneNumberView
      step={step}
      phoneNumber={phoneNumber}
      incorrectCode={incorrectCode}
      existingPhoneNumber={existingPhoneNumber}
      invalidPhoneNumber={invalidPhoneNumber}
      reset={reset}
      updatePhoneNumber={updatePhoneNumber}
      verifyPhoneNumber={verifyPhoneNumber}
      signinFlow={signinFlow}
      recaptcha={recaptcha}
      sessionExpired={sessionExpired}
    />
  );
};

export default IdpAddPhoneNumber;
