import { Modal, Loader } from "..";
import React from "react";
import { Button, AlertModal } from "../../components";
import moment from "moment";
import { getFormattedLoanStatus } from "../utils";
import {
  collateralFormatter,
  numberToFormattedAUD
} from "../../helpers/formatters";
import { displayLoanDebt, getLoansLVR } from "../../helpers/loan-helpers";

type Props = {
  user: server.User | undefined;
  price: server.Asset | undefined;
  loan: server.Loan | undefined;
  loadingDocuments: boolean;
  documents: server.GetDocumentsResponse | undefined;
  financialData:
    | server.ConsumerResponsibleLendingData
    | server.BusinessResponsibleLendingData
    | undefined;
  loadingFinancialData: boolean;
  contractUrl: string | undefined;
  onClose: () => void;
  fetchDocuments(): Promise<void>;
  fetchFinancialData(): Promise<void>;
  fetchLoanContract(): Promise<void>;
  cancelLoan(): void;
  cancellingLoan: boolean;
  alertShow: boolean;
  onAlertClose: () => void;
  onAlertShow: () => void;
};
const LoanDetailsModalUserView: React.FC<Props> = props => {
  return (
    <Modal title="Loan Details" show={!!props.loan} onClose={props.onClose}>
      {getContent(props)}
    </Modal>
  );
};

const getContent = (props: Props): React.ReactNode => {
  const { loan, price } = props;
  if (!loan || !price) {
    return (
      <div className="flex h-20 justify-center items-center">
        <Loader />
      </div>
    );
  }

  const pendingLoan =
    loan.status === "pending-review" ||
    loan.status === "pending-legal" ||
    loan.status === "admin-signed" ||
    loan.status === "draft" ||
    loan.status === "kyc-incomplete";

  return (
    <div className="pt-6">
      <DetailRow title="ID" value={loan.id} />
      <DetailRow title="Status" value={getFormattedLoanStatus(loan.status)} />
      <DetailRow title="Debt" value={displayLoanDebt(loan)} />
      <DetailRow
        title="Collateral"
        value={
          (pendingLoan
            ? collateralFormatter(loan.desiredCollateral, 6)
            : collateralFormatter(loan.collateral, 6)) +
          " " +
          loan.assetId
        }
      />
      <DetailRow
        title="LVR"
        value={getLoansLVR(loan, price).toFixed(2) + "%"}
      />
      <DetailRow
        title="Created on"
        value={moment(loan.createdAt).format("DD/MM/YYYY")}
      />

      <h6 className="h6 pb-7 pt-10">Financial Situation</h6>

      <div className="">
        {!props.financialData ? (
          <Button
            className={"w-full"}
            onClick={props.fetchFinancialData}
            loading={props.loadingFinancialData}
          >
            View financial details
          </Button>
        ) : props.loan?.natureOfLoan === "personal" ? (
          <ConsumerFinancialDetails
            data={props.financialData as server.ConsumerResponsibleLendingData}
          />
        ) : (
          <BusinessFinancialDetails
            data={props.financialData as server.BusinessResponsibleLendingData}
          />
        )}
      </div>

      <h6 className="h6 pb-7 pt-10">Documents</h6>

      <div className="">
        {!props.documents ? (
          <Button
            className={"w-full"}
            onClick={props.fetchDocuments}
            loading={props.loadingDocuments}
          >
            View documents
          </Button>
        ) : (
          <DocumentList documents={props.documents} />
        )}
      </div>

      {loan.status !== "pending-review" &&
        loan.status !== "pending-legal" &&
        loan.status !== "rejected" &&
        loan.status !== "cancelled" &&
        loan.status !== "draft" &&
        loan.status !== "kyc-incomplete" &&
        loan.status !== "admin-signed" && (
          <>
            <h6 className="h6 pb-7 pt-10">Contract</h6>
            <div className="">
              <Contract {...props} />
            </div>
          </>
        )}

      <AlertModal
        modalShow={props.alertShow}
        action={() => {
          props.cancelLoan();
        }}
        onClose={props.onAlertClose}
      />

      {loan.status !== "active" &&
        loan.status !== "closed" &&
        loan.status !== "rejected" &&
        loan.status !== "cancelled" &&
        loan.status !== "liquidated" &&
        +loan.collateral === 0 && (
          <div className="mt-5">
            {props.cancellingLoan ? (
              <Loader />
            ) : (
              <div className="mt-20 flex gap-6 justify-between">
                <Button
                  className="w-full"
                  color="green"
                  onClick={props.onAlertShow}
                  loading={props.cancellingLoan}
                >
                  Cancel Application
                </Button>
              </div>
            )}
          </div>
        )}
    </div>
  );
};
export default LoanDetailsModalUserView;

type DetailRowProps = {
  title: string;
  value: string | number | undefined;
  className?: string;
};

const DetailRow = ({ title, value, className }: DetailRowProps) => {
  return (
    <div
      className={`${className} flex flex-row justify-between font-sans loan-border detail-row text-base`}
    >
      <span className="block font-normal deposit-tooltip">{title}</span>
      <span className="font-semibold row-value overflow-hidden max-w-xs break-all">
        {value}
      </span>
    </div>
  );
};

const DocumentList: React.FC<{
  documents: server.GetDocumentsResponse;
}> = ({ documents }) => {
  if (!documents.length) {
    return <span>No documents attached</span>;
  }

  return (
    <div>
      <ul>
        {documents.map(document => {
          const documentName = document.filename;

          return (
            <li>
              <a
                href={document.downloanUrl}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer hover:text-purple-600"
              >
                {documentName}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Contract: React.FC<Props> = props => {
  if (!props.loan) {
    return null;
  }

  return (
    <Button className={"w-full"} onClick={props.fetchLoanContract}>
      Download contract
    </Button>
  );
};

const ConsumerFinancialDetails: React.FC<{
  data: server.ConsumerResponsibleLendingData;
}> = ({ data }) => {
  return (
    <div>
      <DetailRow title="Reason for loan" value={data.reasonForLoan} />
      <DetailRow
        title="Loan duration"
        value={`${data.durationInMonths} months`}
      />
      <DetailRow
        title="Annual pre-tax income"
        value={numberToFormattedAUD(data.annualPreTaxIncome)}
      />
      <DetailRow
        title="Employment status"
        value={
          data.employmentStatus[0].toUpperCase() +
          data.employmentStatus.slice(1)
        }
      />
      <DetailRow title="Number of dependants" value={data.numberOfDependants} />
      <DetailRow
        title="Currently in default or other financial hardship"
        value={data.currentlyInFinancialHardshipOrInDefault ? "Yes" : "No"}
      />
      <DetailRow
        title="Monthly accommodation expenses"
        value={numberToFormattedAUD(data.monthlyAccommodationExpenses)}
      />
      <DetailRow
        title="Monthly credit card expenses"
        value={numberToFormattedAUD(data.monthlyCreditCardExpenses)}
      />
      <DetailRow
        title="Monthly loan expenses"
        value={numberToFormattedAUD(data.monthlyLoanExpenses)}
      />
      <DetailRow title="Wallet address" value={data.walletAddress} />
    </div>
  );
};

const BusinessFinancialDetails: React.FC<{
  data: server.BusinessResponsibleLendingData;
}> = ({ data }) => {
  return (
    <div>
      <DetailRow title="Reason for loan" value={data.reasonForLoan} />
      <DetailRow
        title="Loan duration"
        value={`${data.durationInMonths} months`}
      />
      <DetailRow
        title="Monthly Expenses"
        value={numberToFormattedAUD(data.averageMonthlyAndOutgoingExpenses)}
      />
      <DetailRow title="Business Name" value={data.businessName} />
      <DetailRow title="Business Type" value={data.businessType} />
      <DetailRow title="ABN Number" value={data.abn} />
      <DetailRow title="Business Address" value={data.businessAddress} />
      <DetailRow title="Type of Property" value={data.typeOfProperty} />
      <DetailRow
        title="Business Phone Number"
        value={data.businessPhoneNumber}
      />
      <DetailRow
        title="Foreign Company"
        value={data.isForeignCompany ? "Yes" : "No"}
      />
      <DetailRow title="Wallet address" value={data.walletAddress} />
      {data.witness && (
        <>
          <DetailRow title="Witness Name" value={data.witness.name} />
          <DetailRow title="Witness Email" value={data.witness.email} />
        </>
      )}
      {data.secondDirector && (
        <>
          <DetailRow
            title="Second Director Name"
            value={data.secondDirector.name}
          />
          <DetailRow
            title="Second Director Email"
            value={data.secondDirector.email}
          />
        </>
      )}
    </div>
  );
};
