type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  value?: string;
  tooltipClassName?: string;
};

const ToolTip: React.FC<Props> = props => {
  const {
    className,
    value,
    children,
    tooltipClassName,
    ...restOfProps
  } = props;
  return (
    <div
      className={`group cursor-pointer relative inline-block border-gray-400 text-center ${tooltipClassName}`}
    >
      ⓘ
      <div
        className={`opacity-0 shadow-lg bg-background-lighter text-white text-center text-xs rounded-lg py-2 absolute z-10  group-hover:opacity-100 bottom-full ml-2 w-80 px-3 pointer-events-none left-modal ${className}`}
        {...restOfProps}
      >
        {value ? value : children}
      </div>
    </div>
  );
};

export default ToolTip;
