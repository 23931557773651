import { Modal, Button } from "..";

interface AlertPopupViewType {
  modalShow: boolean;
  onClose: () => void;
  action: () => void;
}

const AlertPopupView: React.FC<AlertPopupViewType> = ({
  modalShow,
  onClose,
  action
}) => {
  return (
    <Modal
      title="Are you sure?"
      show={modalShow}
      onClose={onClose}
      //   className="text-center"
    >
      <p>You will not be able to revert this action!</p>
      <Button
        title="Confirm"
        className="mt-8 px-7 min-width-btn"
        onClick={action}
      />
    </Modal>
  );
};

export default AlertPopupView;
